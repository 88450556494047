import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartPixelIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
        d="M72.981 16.675h7.68v26.27h-7.55v9.62h-8.87v7.9h-8.68v9.65h-8.63v7.67h-10.68v-7.67h-8.7v-9.63h-8.75v-7.87h-8.74v-9.56h-7.65v-26.33h7.52v-8.61h27.2v8.52h8.62v-8.52h27.19l.04 8.56zm-62 1v18.16h10.22v-18.15l-10.22-.01z"
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
