import React from 'react';
import Sound from '../Sound';

export class SoundWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromLaunch: props.fromLaunch,
    };
  }

  findRange = (ranges, heartRate) => {
    for (let index = 0; index < ranges.length; index++) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return index;
      }
    }
    return null;
  };

  handleFinish = (fromRender) => {
    let index = this.findRange(
      this.props.configuration.ranges,
      this.props.pulse
    );
    if (index != null) {
      this.setState({index});
    } else {
      if (!fromRender) {
        this.setState({index: undefined});
      }
    }
  };

  render() {
    if (!this.state.fromLaunch) {
      if (this.state.index === undefined) {
        this.handleFinish(true);
      }

      return (
        <>
          {this.props.configuration.ranges &&
            this.props.configuration.ranges.map((range, index) => {
              let play = Sound.status.STOPPED;
              if (this.state.index === index) {
                play = Sound.status.PLAYING;
              }

              return (
                <Sound
                  key={index}
                  url={range.soundUrl}
                  playStatus={play}
                  playbackRate={range.speed}
                  volume={range.volume}
                  onFinishedPlaying={() => this.handleFinish(false)}
                />
              );
            })}
        </>
      );
    } else {
      return (
        <button
          style={{width: '200px', height: '50px'}}
          onClick={() => this.setState({fromLaunch: false})}
        >
          Click here to start test you sound widget. This button is visible only
          in test mode.
        </button>
      );
    }
  }
}
