import React from 'react';
import pulsoid from '../window-widget-background.png';
import heart from './pixel_heart.png';

export class WindowsNT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 1000,
      deadlineTime: new Date().getTime() - 10,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let currentTime = new Date().getTime();
    let deadlineTime = state.deadlineTime;
    if (currentTime > deadlineTime) {
      let duration = Math.max(Math.floor((60 / props.pulse) * 1000) - 100, 300);
      return {
        duration,
        deadlineTime: currentTime + duration + 30,
      };
    }
    return null;
  }

  render() {
    let height = 672 / 2;
    let width = 877 / 2;
    return (
      <div
        style={{
          backgroundImage: `url(${pulsoid})`,
          width: width,
          height: height,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"
          rel="stylesheet"
        />
        <div
          style={{
            fontSize: 120,
            color: 'black',
            position: 'absolute',
            top: 80,
            // left: 27,
            zIndex: 5,
            width: width,
            textAlign: 'center',
            fontFamily: "'Press Start 2P', cursive",
          }}
        >
          {this.props.pulse}
        </div>

        <img
          src={heart}
          alt="Heart"
          style={{
            position: 'absolute',
            height: 328 / 2.5,
            width: 320 / 2.5,
            top: 200,
            left: width / 7,
            zIndex: 3,
            animationName: 'pulse_animation',
            animationDuration: this.state.duration + 'ms',
            transformOrigin: '70% 70%',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
          }}
        />

        <span
          style={{
            color: 'black',
            position: 'absolute',
            fontSize: 50,
            top: 240,
            left: 230,
            zIndex: 6,
            fontFamily: "'Press Start 2P', cursive",
          }}
        >
          BPM
        </span>
      </div>
    );
  }
}
