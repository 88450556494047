import React from 'react';
import {useState, useEffect} from 'react';
import {WidgetProps} from '../common';
import css from './ValorantWidget.module.scss';

const SPIKE_TIME = 2000;
const COOL_DOWN_TIME = 60000;
const FAST_GRADIENT_ANIMATION_DURATION = '6s';
const SLOW_GRADIENT_ANIMATION_DURATION = '20s';

export type ValorantWidgetProps = WidgetProps<
  {},
  {
    triggerHeartRate: number;
    defaultColor?: string;
    afterTriggerColor?: string;
  }
>;

export const ValorantWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: ValorantWidgetProps) => {
  const {triggerHeartRate, defaultColor, afterTriggerColor} = configuration;
  const shouldShowEmptyState = emptyStateComponent && !pulse;
  const [spike, setSpike] = useState(false);
  const [spikeCoolDown, setSpikeCoolDown] = useState(false);
  const [canShowSpike, setCanShowSpike] = useState(true);
  const [color, setColor] = useState(configuration.defaultColor);

  const startSpike = () => {
    setSpike(true);
    setTimeout(() => {
      setSpike(false);
    }, SPIKE_TIME);
  };

  const startSpikeCoolDown = () => {
    setSpikeCoolDown(true);
    setTimeout(() => {
      setSpikeCoolDown(false);
    }, COOL_DOWN_TIME);
  };

  useEffect(() => {
    if (pulse >= triggerHeartRate) {
      setColor(afterTriggerColor);
    } else {
      setColor(defaultColor);
    }

    if (pulse >= triggerHeartRate && canShowSpike) {
      startSpike();
      startSpikeCoolDown();
      setCanShowSpike(false);
    }

    if (!spikeCoolDown && pulse < triggerHeartRate) {
      setCanShowSpike(true);
    }
  }, [pulse]);

  return (
    <div style={{height: '100%'}}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 360 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.25"
          x="0.617188"
          width="360"
          height="240"
          fill={spike ? afterTriggerColor : '#D9D9D9'}
        />
        <rect
          x="0.617188"
          width="360"
          height="240"
          fill="url(#paint0_radial_932_269)"
        />
        {shouldShowEmptyState ? (
          emptyStateComponent
        ) : (
          <text
            x="56%"
            y="56.3%"
            dominantBaseline="middle"
            textAnchor="middle"
            className={css.pulse}
            fill={spike ? afterTriggerColor : '#FFFFFF'}
          >
            {pulse}
          </text>
        )}
        {spike && (
          <text
            x="56%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            className={css.spikeText}
          >
            HEART RATE SPIKE
          </text>
        )}

        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M320.617 0H40.6172H0.617188V4V12H28.6172L36.6172 4H324.617L332.617 12H360.617V4V0H320.617ZM40.6172 240H0.617188V236V228H28.6172L36.6172 236H324.617L332.617 228H360.617V236V240H320.617H40.6172Z"
          fill={spike ? afterTriggerColor : '#FFFFFF'}
        />
        <g opacity="0.8">
          <path
            d="M47.6185 131.967L46.2518 130.733C43.8963 128.578 41.9518 126.717 40.4185 125.15C38.8852 123.583 37.6629 122.183 36.7518 120.95C35.8407 119.717 35.2018 118.6 34.8352 117.6C34.4685 116.6 34.2852 115.589 34.2852 114.567C34.2852 112.567 34.9574 110.894 36.3018 109.55C37.6463 108.206 39.3074 107.533 41.2852 107.533C42.5518 107.533 43.724 107.833 44.8018 108.433C45.8796 109.033 46.8185 109.9 47.6185 111.033C48.5518 109.833 49.5407 108.95 50.5852 108.383C51.6296 107.817 52.7518 107.533 53.9518 107.533C55.9296 107.533 57.5907 108.206 58.9352 109.55C60.2796 110.894 60.9518 112.567 60.9518 114.567C60.9518 115.589 60.7685 116.6 60.4018 117.6C60.0352 118.6 59.3963 119.717 58.4852 120.95C57.5741 122.183 56.3518 123.583 54.8185 125.15C53.2852 126.717 51.3407 128.578 48.9852 130.733L47.6185 131.967Z"
            stroke={spike ? afterTriggerColor : '#FFFFFF'}
            stroke-width="3"
          />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_932_269"
            cx="0"
            cy="0"
            r={spike ? '1' : '1'}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(180.617 240) rotate(-90) scale(185 185)"
          >
            <stop stop-color={spike ? afterTriggerColor : color}></stop>
            <stop
              offset="1"
              stop-color={spike ? afterTriggerColor : color}
              stop-opacity="0"
            ></stop>
            {!spike && (
              <animate
                attributeName="r"
                values="1; 1.5; 1"
                dur={
                  pulse >= triggerHeartRate
                    ? FAST_GRADIENT_ANIMATION_DURATION
                    : SLOW_GRADIENT_ANIMATION_DURATION
                }
                repeatCount="indefinite"
              ></animate>
            )}
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};
