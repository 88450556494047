import React from 'react';
import {
  ImagePickerImageConfiguration,
  imagePickerTypeToComponentMap,
  IMAGE_PICKER_TYPES,
  IMAGE_PICKER_TYPE_PRESETS,
} from './ImagePicker.constants';
import {BasePicker} from '../BasePicker/BasePicker';
import {OneOf} from '../../types/internal';

export type ImagePickerProps = {
  selectedItem?: OneOf<typeof IMAGE_PICKER_TYPES> | string | null;
  onSelect?: (image: string) => void;
  itemConfiguration?: Omit<ImagePickerImageConfiguration, 'type'>;
  preset?: keyof typeof IMAGE_PICKER_TYPE_PRESETS | string[];
  renderItem?: (
    item: OneOf<typeof IMAGE_PICKER_TYPES> | string
  ) => React.ReactNode;

  className?: string;
  itemClassName?: string;
};

export const ImagePicker = (props: ImagePickerProps) => {
  const {
    preset = 'basic',
    renderItem = (item: OneOf<typeof IMAGE_PICKER_TYPES>) => {
      const ImageComponent = imagePickerTypeToComponentMap[item];
      return (
        <ImageComponent
          withAnimation={false}
          size={props.itemConfiguration?.size || 40}
          {...(props.itemConfiguration || {})}
        />
      );
    },
  } = props;

  return (
    <BasePicker
      selectedItem={props.selectedItem}
      onSelect={props.onSelect}
      className={props.className}
      itemClassName={props.itemClassName}
      itemConfiguration={props.itemConfiguration}
      items={
        typeof preset === 'string'
          ? (IMAGE_PICKER_TYPE_PRESETS[preset] as unknown as string[])
          : preset
      }
      renderItem={renderItem}
    />
  );
};
