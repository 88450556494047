export const pressStart2PFont = {
  name: 'Press Start II P',
  url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/PressStart2P-Regular.ttf',
};

export const latoFont = {
  name: 'Lato Bold',
  url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Lato-Bold.ttf',
};

export const srirachaFont = {
  name: 'Sriracha',
  url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Sriracha-Regular.ttf',
};

export const FONT_PICKER_DEFAULT_FONTS = [
  {
    name: 'Bubble Bath',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/04B_30__.TTF',
  },
  {
    name: 'Agressive Winter',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/aAgressiveWinter.otf',
  },
  {
    name: 'Asian Ninja',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/aAsianNinja.otf',
  },
  {
    name: 'Beth Ellen',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/BethEllen-Regular.ttf',
  },
  {
    name: 'Black Ops One',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/BlackOpsOne-Regular.ttf',
  },
  {
    name: 'Bungee Inline',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/BungeeInline-Regular.ttf',
  },
  {
    name: 'Chakra Petch',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/ChakraPetch-Regular.ttf',
  },
  {
    name: 'East Sea Dokdo',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/EastSeaDokdo-Regular.ttf',
  },
  // Uncomment when we have a way to handle calculate and adjust font height
  // {
  //   name: 'Elementary Gothic Bookhand',
  //   url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Elementary_Gothic_Bookhand.otf',
  // },
  {
    name: 'Frostbitten Wanker',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/FROSW___.TTF',
  },
  {
    name: 'Geostar Fill',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/GeostarFill-Regular.ttf',
  },
  {
    name: 'Help Me',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/HelpMe.ttf',
  },
  {
    name: 'Karate',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Karate.ttf',
  },
  {
    name: 'Long shot',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Long_Shot.ttf',
  },
  {
    name: 'Morris Roman',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/MorrisRomanBlack.otf',
  },
  {
    name: 'Nova Cut',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/NovaCut-Regular.ttf',
  },
  {
    name: 'Old London',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/OldLondon.ttf',
  },
  {
    name: 'Osaka Sans Serif',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/osaka-re.ttf',
  },
  {
    name: 'Pointless',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Pointless.ttf',
  },
  pressStart2PFont,
  {
    name: 'Qahiri',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Qahiri-Regular.ttf',
  },
  {
    name: 'Rubik Glitch',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/RubikGlitch-Regular.ttf',
  },
  {
    name: 'Rubik Wet Paint',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/RubikWetPaint-Regular.ttf',
  },
  {
    name: 'Silkscreen',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Silkscreen-Regular.ttf',
  },
  {
    name: 'Snowtop Caps',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/SnowtopCaps.otf',
  },
  srirachaFont,
  {
    name: 'The Wild Breath of Zelda',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/TheWildBreathofZelda.otf',
  },
  {
    name: 'Tourney',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/Tourney-Regular.ttf',
  },
  {
    name: 'Zen Dots',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/ZenDots-Regular.ttf',
  },
  {
    name: 'Audiowide',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FAudiowide-Regular.ttf',
  },
  {
    name: 'Bungee Shade',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FBungeeShade-Regular.ttf',
  },
  {
    name: 'Cabin Sketch',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FCabinSketch-Bold.ttf',
  },
  {
    name: 'Chango',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FChango-Regular.ttf',
  },
  {
    name: 'Dancing Script',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FDancingScript-VariableFont_wght.ttf',
  },
  {
    name: 'Eater',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FEater-Regular.ttf',
  },
  {
    name: 'Faster One',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FFasterOne-Regular.ttf',
  },
  {
    name: 'Fredericka the Great',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FFrederickatheGreat-Regular.ttf',
  },
  {
    name: 'Frijole',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FFrijole-Regular.ttf',
  },
  {
    name: 'Graduate',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FGraduate-Regular.ttf',
  },
  {
    name: 'Henny Penny',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FHennyPenny-Regular.ttf',
  },
  {
    name: 'Londrina Outline',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FLondrinaOutline-Regular.ttf',
  },
  {
    name: 'Modak',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FModak-Regular.ttf',
  },
  {
    name: 'Monofett',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FMonofett-Regular.ttf',
  },
  {
    name: 'Monoton',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FMonoton-Regular.ttf',
  },
  {
    name: 'Rampart One',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FRampartOne-Regular.ttf',
  },
  {
    name: 'Rock Salt',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FRockSalt-Regular.ttf',
  },
  {
    name: 'Sacramento',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FSacramento-Regular.ttf',
  },
  {
    name: 'Shadows Into Light',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FShadowsIntoLight-Regular.ttf',
  },
  {
    name: 'Special Elite',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FSpecialElite-Regular.ttf',
  },
  {
    name: 'Wallpoet',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FWallpoet-Regular.ttf',
  },
  {
    name: 'Zilla Slab Highlight',
    url: 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source%2Fadvanced-widget%2Ffonts%2FZillaSlabHighlight-Regular.ttf',
  },
];
