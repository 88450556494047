import React from 'react';

import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import hexToRgba from 'hex-to-rgba';

let ShowRange = 300000;
let Animation = 2000;
let Buffer = 1000;

export class RealTimeGraphWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'realtime',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: Animation,
            },
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },

        markers: {
          size: 0,
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          type: 'datetime',
          range: ShowRange,
        },
        yaxis: {
          tickAmount: 1,
          min: this.props.configuration.min ? this.props.configuration.min : 50,
          max: this.props.configuration.max
            ? this.props.configuration.max
            : undefined,
          labels: {
            offsetX: 50,
            style: {
              color: this.props.configuration.color,
              fontSize: this.props.configuration.fontSize + 'px',
              fontFamily: "'" + this.props.configuration.font + "'",
              cssClass: 'apexcharts-yaxis-label',
            },
            formatter: (value) => {
              return value | 0;
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },

        colors: [hexToRgba(this.props.configuration.lineColor)],
      },
      data: [],
    };
  }

  componentDidMount() {
    this.intervals();
  }

  getNewSeries = (value) => {
    var date = new Date().getTime();

    let data = this.state.data;

    if (this.state.data.length > ShowRange / Animation + Buffer) {
      data = data.slice(Buffer, data.length);
    }

    data.push({
      x: date,
      y: value | 0,
    });

    this.setState({data: data});
  };

  intervals() {
    window.setInterval(() => {
      this.getNewSeries(this.props.pulse ? this.props.pulse : 60);

      ApexCharts.exec('realtime', 'updateSeries', [
        {
          data: this.state.data,
        },
      ]);
    }, Animation);
  }

  render() {
    return (
      <div>
        <link
          href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
          rel={'stylesheet'}
        />
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={[]}
            type="line"
            height="350"
          />
        </div>
      </div>
    );
  }
}
