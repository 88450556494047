import React, {useMemo, useEffect, useState} from 'react';
import {FontOption} from '../../controls/FontPicker/FontPicker';
import {
  ImagePickerImageConfiguration,
  imagePickerTypeToComponentMap,
  IMAGE_PICKER_COLOR_TYPE,
} from '../../controls/ImagePicker/ImagePicker.constants';
import {
  layoutTypeToFlexDirectionMap,
  LAYOUT_TYPES,
} from '../../controls/LayoutPicker/LayoutPicker.constants';
import {OneOf} from '../../types/internal';
import {findColorInRange, findRangeForPulse} from '../../utils/rangeUtils';
import {WidgetProps} from '../common';
import {loadCustumFont} from '../../utils/fontUtils';
import './BpmWidget.scss';
import {IS_SAFARI} from '../../utils/browserUtils';

export const WIDGET_OUTLINE_EFFECT = {
  NONE: 'none',
  SHADOW: 'shadow',
  STROKE: 'stroke',
} as const;

export const BPM_ANIMATION_MODE = {
  NONE: 'none',
  RANGE: 'range',
  PERMANENT: 'permanent',
} as const;

export const BPM_ANIMATION_TYPE = {
  SHAKE_NO: 'shake-no',
  SHAKE_HARD: 'shake-hard',
  SHAKE_SLOW: 'shake-slow',
  SHAKE_HORIZONTAL: 'shake-horizontal',
  SHAKE_OPACITY: 'shake-opacity',
  SHAKE_CRAZY: 'shake-crazy',
  SHAKE_LITTLE: 'shake-little',
  SHAKE: 'shake',
  SHAKE_VERTICAL: 'shake-vertical',
  SHAKE_ROTATE: 'shake-rotate',
  SHAKE_CHUNK: 'shake-chunk',
} as const;

export type BmpWidgetProps = WidgetProps<
  {color?: string; animation?: OneOf<typeof BPM_ANIMATION_TYPE>},
  {
    font?: string | FontOption;
    fontUrl?: string;
    alignment?: AlignSetting;
    image?: ImagePickerImageConfiguration;
    layout?: OneOf<typeof LAYOUT_TYPES>;
    animation?: {
      mode: OneOf<typeof BPM_ANIMATION_MODE>;
      type?: OneOf<typeof BPM_ANIMATION_TYPE>;
    };
    outline?: {
      effect: OneOf<typeof WIDGET_OUTLINE_EFFECT>;
      color?: string;
    };
  }
>;

export const BpmWidget = (props: BmpWidgetProps) => {
  const {
    layout = null,
    image,
    outline,
    animation,
    alignment,
  } = props?.configuration || {};

  const [safariScale, setSafariScale] = useState<number>(0);

  let color: string;
  if (props.configuration) {
    color = findColorInRange(props.configuration.ranges, {pulse: props.pulse});
  } else {
    color = 'black';
  }

  const fontUrl =
    typeof props.configuration?.font === 'object'
      ? props.configuration?.font.url
      : props.configuration?.fontUrl;
  const fontName =
    typeof props.configuration?.font === 'object'
      ? props.configuration?.font.name
      : props.configuration?.font;

  const loadFont = async () => loadCustumFont({name: fontName, url: fontUrl});
  useEffect(() => {
    if (fontName || fontUrl) {
      loadFont();
    }
  }, [fontName, fontUrl]);

  const shouldShowEmptyState = props.emptyStateComponent && !props.pulse;
  const imageConfiguration = props.configuration?.image;

  const {viewBoxWidth, viewBoxHeight} = useMemo(() => {
    const shouldHaveAdditionalWidth = !!imageConfiguration?.size;
    const shouldHaveAdditionalHeight =
      (layout === LAYOUT_TYPES.BOTTOM_UP || layout === LAYOUT_TYPES.TOP_DOWN) &&
      imageConfiguration?.size;

    let viewBoxWidth = 340;
    let viewBoxHeight = 140;

    if (shouldHaveAdditionalWidth) {
      viewBoxWidth += Number(imageConfiguration?.size) + 40;
    }

    if (shouldHaveAdditionalHeight) {
      viewBoxHeight += Number(imageConfiguration?.size) + 40;
    }

    return {viewBoxWidth, viewBoxHeight};
  }, [imageConfiguration?.size, layout]);

  const imageColor = useMemo(() => {
    if (image?.colorType === IMAGE_PICKER_COLOR_TYPE.SYNC) {
      return color;
    } else if (image?.colorType === IMAGE_PICKER_COLOR_TYPE.TRANSPARENT) {
      return 'transparent';
    }

    return image?.color;
  }, [color, image?.colorType, image?.color]);

  const animationClassName = useMemo(() => {
    const range = findRangeForPulse(props.configuration?.ranges, props.pulse);

    if (animation?.mode === BPM_ANIMATION_MODE.RANGE) {
      return `${range?.animation} shake-constant`;
    } else if (animation?.mode === BPM_ANIMATION_MODE.PERMANENT) {
      return `${animation.type} shake-constant`;
    }
    return null;
  }, [
    animation?.mode,
    animation?.type,
    props.configuration?.ranges,
    props.pulse,
  ]);

  const justifyContentProp = useMemo(() => {
    if (
      layout === LAYOUT_TYPES.LEFT_RIGHT ||
      layout === LAYOUT_TYPES.RIGHT_LEFT
    ) {
      return 'flex-start';
    } else if (
      layout === LAYOUT_TYPES.TOP_DOWN ||
      layout === LAYOUT_TYPES.BOTTOM_UP
    ) {
      return 'space-around';
    }

    return alignment ? alignment : 'left';
  }, [layout, alignment]);

  const IconComponent = imagePickerTypeToComponentMap[imageConfiguration?.type];

  // We should not use height: 100% here, because it will break the layout in the OBS
  return (
    <svg
      style={{width: '100%', overflow: 'visible'}}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      ref={(el) => {
        if (el && IS_SAFARI && safariScale === 0) {
          const boundingRect = el.getBoundingClientRect();
          const scale = boundingRect.width / viewBoxWidth;
          setSafariScale(scale);
        }
      }}
    >
      <foreignObject
        style={{
          width: '100%',
          height: '100%',
          overflow: 'visible',
        }}
        xmlns="http://www.w3.org/1999/xhtml"
      >
        {props.configuration && (
          <div
            style={{
              transformOrigin: 'top left',
              transform:
                IS_SAFARI && safariScale
                  ? `scale(${safariScale.toFixed(2)})`
                  : null,
            }}
          >
            <div
              id="heartRate"
              className={animationClassName}
              style={{
                width: viewBoxWidth,
                height: viewBoxHeight,
                padding: '12px 10px',
                display: 'flex',
                flexDirection: layoutTypeToFlexDirectionMap[layout],
                justifyContent: justifyContentProp,
                transform: 'translateZ(0)',
                alignItems: 'center',
                fontSize: '120px',
                lineHeight: '120px',
                color: color,
                fontFamily: `"${fontName}"`,
                textShadow:
                  outline?.effect === WIDGET_OUTLINE_EFFECT.SHADOW
                    ? `3px 3px 8px ${outline?.color}`
                    : null,
                WebkitTextStroke:
                  outline?.effect === WIDGET_OUTLINE_EFFECT.STROKE
                    ? `2px ${outline.color}`
                    : null,
              }}
            >
              {imageConfiguration && (
                <IconComponent
                  color={imageColor}
                  size={imageConfiguration.size}
                  withAnimation={imageConfiguration.withAnimation}
                  stroke={
                    outline?.effect === WIDGET_OUTLINE_EFFECT.STROKE
                      ? outline?.color
                      : null
                  }
                  style={{
                    marginRight:
                      layout === LAYOUT_TYPES.LEFT_RIGHT || !layout ? 30 : 0,
                    marginLeft: layout === LAYOUT_TYPES.RIGHT_LEFT ? 30 : 0,
                    marginBottom: layout === LAYOUT_TYPES.TOP_DOWN ? 20 : 0,
                    marginTop: layout === LAYOUT_TYPES.BOTTOM_UP ? 20 : 0,
                    filter:
                      outline?.effect === WIDGET_OUTLINE_EFFECT.SHADOW
                        ? `drop-shadow(3px 3px 3px ${outline?.color})`
                        : 'none',
                  }}
                />
              )}

              {!fontUrl ||
              fontUrl.includes('googleapis') ||
              fontUrl.endsWith('.css') ? (
                <link
                  href={
                    props.configuration.fontUrl
                      ? props.configuration.fontUrl
                      : `https://fonts.googleapis.com/css?family=${props.configuration.font}`
                  }
                  rel={'stylesheet'}
                />
              ) : null}

              {shouldShowEmptyState ? props.emptyStateComponent : props.pulse}
            </div>
          </div>
        )}
      </foreignObject>
    </svg>
  );
};
