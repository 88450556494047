import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from 'react';
import {WidgetProps} from '../common';
import css from './SilentHill2Widget.module.scss';

const CORE_RED_COLOR = '#C7100D';
const TEXT_COLOR = '#FFFFFF';
const HEART_COLOR = '#F43039';
const BACKGROUND_OPACITY = '0.5';
const STROKE_OPACITY = '0.9';

export type SilentHill2Props = WidgetProps<{}>;

export const SilentHill2Widget = (props: SilentHill2Props) => {
  const shouldShowEmptyState = props.emptyStateComponent && !props.pulse;

  const [animationDuration, setAnimationDuration] = useState(1000);
  const pulseRef = useRef(props.pulse);
  const durationRef = useRef(animationDuration);
  const animationRef = useRef(null);

  const calculateDuration = (pulse: any) => {
    let duration = (60 / pulse) * 1000;
    let normalizedDuration = Math.max(Math.ceil(duration), 300);
    return normalizedDuration;
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('heart rate animated widget with fixed animation');
  }, []);

  useEffect(() => {
    pulseRef.current = props.pulse;
    durationRef.current = calculateDuration(props.pulse);
  }, [props.pulse]);

  return (
    <svg width="100%" height="100%" viewBox="0 0 330 168">
      <mask id="stroke-mask" fill="white">
        <path d="M0 3C0 1.34314 1.34315 0 3 0H327C328.657 0 330 1.34315 330 3V165C330 166.657 328.657 168 327 168H3C1.34314 168 0 166.657 0 165V3Z" />
      </mask>
      <path
        d="M0 3C0 1.34314 1.34315 0 3 0H327C328.657 0 330 1.34315 330 3V165C330 166.657 328.657 168 327 168H3C1.34314 168 0 166.657 0 165V3Z"
        fill="url(#red-gradient)"
        fill-opacity={BACKGROUND_OPACITY}
      />
      <path
        d="M0 3C0 -0.865993 3.13401 -4 7 -4H323C326.866 -4 330 -0.865993 330 3V4C330 4 328.657 4 327 4H3C1.34315 4 0 4 0 4V3ZM330 165C330 168.866 326.866 172 323 172H7C3.13401 172 0 168.866 0 165V164C0 164 1.34315 164 3 164H327C328.657 164 330 164 330 164V165ZM0 168V0V168ZM330 0V168V0Z"
        fill="url(#red-gradient)"
        fill-opacity={STROKE_OPACITY}
        mask="url(#stroke-mask)"
      />
      <defs>
        <linearGradient
          id="red-gradient"
          x1="0"
          y1="84"
          x2="330"
          y2="84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={CORE_RED_COLOR} stop-opacity="0" />
          <stop offset="0.2" stop-color={CORE_RED_COLOR} />
          <stop offset="0.8" stop-color={CORE_RED_COLOR} />
          <stop offset="1" stop-color={CORE_RED_COLOR} stop-opacity="0" />
        </linearGradient>
      </defs>

      <svg
        width="330"
        height="168"
        viewBox="0 0 330 168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <mask
          id="fog-mask"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="330"
          height="168"
        >
          <path
            d="M0 3C0 1.34314 1.34315 0 3 0H327C328.657 0 330 1.34315 330 3V165C330 166.657 328.657 168 327 168H3C1.34314 168 0 166.657 0 165V3Z"
            fill="url(#red-gradient)"
            fill-opacity="0.5"
          />
        </mask>
        <g mask="url(#fog-mask)">
          <rect
            x="-697.805"
            y="-255"
            width="1233.16"
            height="564"
            fill="url(#fog-patern)"
            fill-opacity="0.8"
          />
        </g>
        <defs>
          <pattern
            id="fog-patern"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#fog-image"
              transform="matrix(0.000540249 0 0 0.00118123 0 -0.0108804)"
            />
          </pattern>
          <image
            className={css.cloud}
            id="fog-image"
            width="1851"
            height="865"
            xlinkHref="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/silent-hill-widget/fog.webp"
          ></image>
        </defs>
      </svg>

      <text
        className={css.pulse}
        x="50%"
        y="56%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill={TEXT_COLOR}
        filter="url(#text-shadow)"
      >
        {shouldShowEmptyState ? props.emptyStateComponent : props.pulse}
      </text>
      <defs>
        <filter
          id="text-shadow"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2506_191"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2506_191"
            result="shape"
          />
        </filter>
      </defs>

      <svg
        width="32"
        height="32"
        x="4%"
        y="30%"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#heart-shadow)">
          <path
            d="M16 28.4621L14.4625 27.0746C11.8125 24.6496 9.625 22.5559 7.9 20.7934C6.175 19.0309 4.8 17.4559 3.775 16.0684C2.75 14.6809 2.03125 13.4246 1.61875 12.2996C1.20625 11.1746 1 10.0371 1 8.88711C1 6.63711 1.75625 4.75586 3.26875 3.24336C4.78125 1.73086 6.65 0.974609 8.875 0.974609C10.3 0.974609 11.6188 1.31211 12.8313 1.98711C14.0438 2.66211 15.1 4.76211 16 6.03711C17.05 4.68711 18.1625 2.56836 19.3375 1.93086C20.5125 1.29336 21.775 0.974609 23.125 0.974609C25.35 0.974609 27.2188 1.73086 28.7313 3.24336C30.2438 4.75586 31 6.63711 31 8.88711C31 10.0371 30.7938 11.1746 30.3813 12.2996C29.9688 13.4246 29.25 14.6809 28.225 16.0684C27.2 17.4559 25.825 19.0309 24.1 20.7934C22.375 22.5559 20.1875 24.6496 17.5375 27.0746L16 28.4621Z"
            fill={HEART_COLOR}
          ></path>
          <Animation
            ref={animationRef}
            animationDuration={animationDuration}
            values="1; 0; 1;"
            attributeName="opacity"
            restart="whenNotActive"
            onAnimationEnd={() => {
              setAnimationDuration(durationRef.current);
              animationRef.current.restartAnimation();
            }}
          ></Animation>
        </g>
        <defs>
          <filter
            id="heart-shadow"
            x="0"
            y="0.974609"
            width="32"
            height="30.4883"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2506_196"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2506_196"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </svg>
  );
};

interface AnimationProps {
  animationDuration: number;
  onAnimationEnd?: () => void;
  values: string;
  attributeName: string;
  additive?: 'replace' | 'sum';
  restart?: 'always' | 'whenNotActive' | 'never';
}

// Define the ref type for the Animation component
interface AnimationRef {
  restartAnimation: () => void;
}

// Create the Animation component
const Animation = forwardRef<AnimationRef, AnimationProps>(
  (
    {
      animationDuration,
      onAnimationEnd,
      values,
      attributeName,
      restart = 'whenNotActive',
    },
    ref
  ) => {
    const animateRef = useRef<SVGAnimateElement | null>(null);

    useEffect(() => {
      const animateElement = animateRef.current;

      const handleAnimationEnd = () => {
        if (onAnimationEnd) {
          onAnimationEnd();
        }
      };

      // Attach the event listener for animation end
      if (animateElement) {
        animateElement.addEventListener('endEvent', handleAnimationEnd);
      }

      // Clean up the event listener on component unmount
      return () => {
        if (animateElement) {
          animateElement.removeEventListener('endEvent', handleAnimationEnd);
        }
      };
    }, [onAnimationEnd]);

    useImperativeHandle(ref, () => ({
      restartAnimation: () => {
        if (animateRef.current) {
          animateRef.current.beginElement();
        }
      },
    }));

    return (
      <animate
        ref={animateRef}
        attributeName={attributeName}
        values={values}
        dur={`${animationDuration}ms`}
        restart={restart}
      />
    );
  }
);
