import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartInkIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        d="M 54.049 27.491 C 54.807 26.705 55.564 25.934 56.276 25.208 C 60.768 20.843 66.122 19.449 72.076 21.413 C 77.9 23.332 81.33 27.543 82.147 33.509 C 82.846 37.954 81.298 42.454 77.998 45.583 C 70.613 52.854 63.169 60.073 55.754 67.321 C 55.193 67.843 54.435 68.111 53.664 68.063 L 45.924 68.063 C 44.309 68.063 43.318 67.411 43.114 66.217 C 42.872 64.869 43.613 63.897 45.25 63.393 C 46.109 63.196 46.74 62.476 46.81 61.615 C 46.897 60.838 46.476 60.089 45.757 59.747 C 45.245 59.532 44.693 59.426 44.136 59.436 C 40.788 59.436 37.441 59.436 34.1 59.391 C 33.377 59.367 32.677 59.129 32.094 58.709 C 31.364 58.146 31.134 57.162 31.541 56.345 C 31.901 55.386 32.871 54.779 33.912 54.863 C 34.652 54.961 35.393 54.683 35.873 54.122 C 36.387 53.526 36.471 52.683 36.086 52.001 C 35.725 51.275 34.953 50.834 34.13 50.883 L 25.519 50.883 C 24.553 50.98 23.615 50.527 23.105 49.719 C 22.238 48.344 23.111 46.553 24.748 46.346 C 25.126 46.294 25.504 46.309 25.899 46.258 C 26.936 46.103 27.687 45.208 27.641 44.182 C 27.612 43.142 26.726 42.323 25.664 42.352 L 19.09 42.352 C 18.157 42.445 17.242 42.046 16.689 41.306 C 15.702 39.954 16.525 38.06 18.204 37.815 C 18.613 37.748 19.029 37.733 19.439 37.69 C 20.48 37.58 21.273 36.721 21.279 35.695 C 21.28 34.629 20.421 33.753 19.332 33.709 C 17.939 33.709 16.545 33.709 15.152 33.709 C 13.362 33.851 12.087 32.043 12.856 30.455 C 13.272 29.598 14.185 29.081 15.152 29.159 C 17.931 29.159 20.718 29.159 23.483 29.159 C 24.891 29.159 25.755 28.417 25.755 27.18 C 25.755 25.942 24.861 25.178 23.483 25.193 C 22.498 25.193 21.589 25.097 20.976 24.214 C 19.971 22.919 20.779 21.046 22.428 20.844 C 22.431 20.844 22.434 20.843 22.437 20.843 C 22.85 20.791 23.264 20.774 23.679 20.79 C 29.625 20.79 35.578 20.85 41.53 20.79 C 45.591 20.753 48.831 22.413 51.656 25.082 C 52.482 25.816 53.224 26.645 54.049 27.491 Z"
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
      />
      <path
        d="M 6.003 33.687 C 5.245 33.687 4.488 33.687 3.731 33.687 C 2.52 33.656 1.547 32.701 1.519 31.515 C 1.418 30.326 2.325 29.282 3.541 29.188 C 5.166 29.099 6.795 29.099 8.419 29.188 C 9.675 29.258 10.619 30.335 10.501 31.56 C 10.451 32.753 9.449 33.695 8.229 33.694 C 7.487 33.716 6.745 33.687 6.003 33.687 Z"
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
      />
      <path
        d="M 11.637 37.8 C 13.387 37.8 14.481 39.653 13.606 41.135 C 12.731 42.618 10.544 42.618 9.67 41.135 C 9.469 40.797 9.365 40.414 9.365 40.024 C 9.365 38.796 10.382 37.8 11.637 37.8 Z"
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
