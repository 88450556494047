import React, {useEffect, useRef} from 'react';
import {WidgetProps} from '../common';
import css from './GlowyWidget.module.scss';
import {useRandomId} from '../../hooks/useRandomId';
import {IS_SAFARI} from '../../utils/browserUtils';

export type GlowyWidgetProps = WidgetProps<
  {},
  {lineColor: string; textColor: string; alignment: 'left' | 'center' | 'right'}
>;

const alignmentToTextAnchor = {
  left: 'start',
  center: 'middle',
  right: 'end',
};

export const GlowyWidget = ({pulse, configuration}: GlowyWidgetProps) => {
  let textX = 250;
  if (configuration.alignment === 'left') {
    textX = -30;
  } else if (configuration.alignment === 'right') {
    textX = 530;
  }
  const svgRef = useRef<SVGSVGElement>(null);
  const textShadowFilterId = useRef(useRandomId('glowy-text-shadow')).current;
  const lineShadowFilterId = useRef(useRandomId('glowy-line-shadow')).current;

  useEffect(() => {
    svgRef.current?.style.setProperty('--line-color', configuration.lineColor);
    svgRef.current?.style.setProperty('--text-color', configuration.textColor);
    svgRef.current?.style.setProperty('--alignment', configuration.alignment);
  }, [
    configuration.alignment,
    configuration.lineColor,
    configuration.textColor,
  ]);

  return (
    <svg
      style={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
        transform: 'translateZ(0)',
      }}
      viewBox="-40 0 620 300"
      ref={svgRef}
    >
      <defs>
        <filter id={lineShadowFilterId}>
          <feDropShadow dx={0} dy={0} stdDeviation={1} floodColor="#fff" />
          <feDropShadow
            dx={-1}
            dy={-1}
            stdDeviation={7}
            floodColor={configuration.lineColor}
          />
          <feDropShadow
            dx={0}
            dy={0}
            stdDeviation={14}
            floodColor={configuration.lineColor}
          />
        </filter>

        <filter id={textShadowFilterId}>
          <feDropShadow
            dx={1}
            dy={2}
            stdDeviation={3}
            floodColor={configuration.textColor}
          />
          <feDropShadow
            dx={-1}
            dy={-1}
            stdDeviation={4}
            floodColor={configuration.textColor}
          />
          <feDropShadow
            dx={-1}
            dy={-1}
            stdDeviation={2}
            floodColor={configuration.textColor}
          />
        </filter>
      </defs>

      <g
        className={css.heartbeatContainer}
        style={IS_SAFARI ? {filter: `url(#${lineShadowFilterId})`} : null}
      >
        <path
          className={css.heartbeatPath}
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeWidth: 5,
            strokeLinejoin: 'round',
          }}
          d="M 19.602 145.621 L 89.734 145.343 L 100.878 117.172 L 114.554 166.637 L 128.819 40.931 L 146.818 215.505 L 161.014 103.015 L 171.664 144.177 L 245.713 144.359 C 129.09 82.493 198.003 -29.472 253.77 41.935 C 311.673 -24.697 376.961 75.579 262.344 144.485 L 334.62 145.047 L 345.201 106.385 L 360.386 214.712 L 378.002 42.514 L 391.674 161.355 L 405.043 132.357 L 413.56 144.564 L 485.843 145.249"
        />
      </g>
      <text
        style={IS_SAFARI ? {filter: `url(#${textShadowFilterId})`} : null}
        className={css.textPulse}
        x={textX}
        fill="#fff"
        y="90"
        dominantBaseline="middle"
        textAnchor={alignmentToTextAnchor[configuration.alignment] || 'middle'}
      >
        {pulse}
      </text>
      <text
        style={IS_SAFARI ? {filter: `url(#${textShadowFilterId})`} : null}
        className={css.textBpm}
        x={textX}
        fill="#fff"
        y="180"
        dominantBaseline="middle"
        textAnchor={alignmentToTextAnchor[configuration.alignment] || 'middle'}
      >
        bpm
      </text>
    </svg>
  );
};
