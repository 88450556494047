import React from 'react';
import {WidgetProps} from './common';

export type GaugeWidgetProps = WidgetProps<
  {color?: string},
  {mirrored?: boolean}
>;

const getColors = (
  ranges: GaugeWidgetProps['configuration']['ranges'] = [],
  pulse: number
) => {
  let filledIndex = Math.min(
    ranges?.findIndex((range) => range.to >= pulse),
    ranges?.length - 1
  );
  return ranges.map((_range, index) =>
    index <= filledIndex ? ranges[filledIndex].color : 'none'
  );
};

export const GaugeWidget = (props: GaugeWidgetProps) => {
  const {
    pulse,
    configuration: {ranges, mirrored},
  } = props;
  const fills = getColors(ranges, pulse);

  return (
    <svg
      style={{
        width: '100%',
        height: '100%',
        overflow: 'visible',
        transform: mirrored ? 'scale(-1, 1)' : '',
      }}
      viewBox="0 0 800 800"
    >
      <text
        fontSize="230"
        fill={fills[0]}
        x={mirrored ? '-370' : '370'}
        textAnchor="middle"
        transform={mirrored ? 'scale(-1, 1)' : ''}
        y="438"
      >
        {pulse}
      </text>
      <g>
        <path
          style={{stroke: fills[0], strokeWidth: '10px'}}
          d="M510.2,591.4c-13.5-13.5-35.1-14.1-49.3-1.7c-14.2-12.4-35.8-11.8-49.3,1.7c-14.1,14.1-14.1,37,0,51l47.5,47.5
		l1.8,1.8l1.8-1.8c0.4-0.4,0.8-0.8,1.2-1.3l45-45c0.4-0.4,0.9-0.8,1.3-1.2C524.3,628.3,524.3,605.5,510.2,591.4z M506.7,638.9
		c-0.4,0.4-0.8,0.7-1.1,1.1l-44.6,44.6l-45.7-45.7c-12.1-12.1-12.1-31.9,0-44c6.1-6.1,14-9.1,22-9.1c8,0,15.9,3,22,9.1l1.8,1.8
		l1.8-1.8c12.1-12.1,31.9-12.1,44,0C518.8,607,518.8,626.8,506.7,638.9z"
        />
      </g>
      <g>
        <path
          style={{stroke: fills[0], strokeWidth: '10px'}}
          fill={fills[0]}
          d="M359.5,596.8L359.5,596.8c-27.7-0.1-55.1-4.9-81.5-14.3c-39.1-13.9-72.8-36.6-100.2-67.3
		c-1.5-1.7-3-3.5-4.5-5.2l0,0l-60.8,51.2l0,0l0,0c1.9,2.3,3.9,4.6,5.9,6.8l0,0c17.7,19.8,37.7,37.3,59.7,52.2
		c22.6,15.3,47.2,27.8,73.1,37c34.9,12.4,71.2,18.8,108,18.8l0,0L359.5,596.8L359.5,596.8z"
        />
      </g>
      <g>
        <path
          style={{stroke: fills[0], strokeWidth: '10px'}}
          fill={fills[1]}
          strokeWidth="5"
          stroke="yellow"
          d="M149.6,476.7L149.6,476.7l-68.5,40.4l0,0c-12.1-20.5-21.9-42.3-29.3-64.9c-7.4-22.8-12.3-46.4-14.5-70.1
		c-2-21.1-1.8-42.4,0.4-63.5c2.3-21.6,6.7-43,13.3-63.8c0.9-2.9,1.9-5.8,2.8-8.6l0,0l0,0l75.1,26l0,0c-0.7,2.1-1.5,4.3-2.1,6.5
		c-9.9,31.2-13.4,63.5-10.3,95.9C119.9,410.4,131.3,445.6,149.6,476.7L149.6,476.7z"
        />
      </g>
      <g>
        <path
          style={{stroke: fills[0], strokeWidth: '10px'}}
          fill={fills[2]}
          d="M310.9,112.8L310.9,112.8c-2.2,0.5-4.5,1-6.7,1.5c-67.7,15.9-125.5,59.9-158.6,120.5l0,0l0,0l-69.7-38.1l0,0
		c11.1-20.3,24.4-39.4,39.5-56.8c14.8-17,31.5-32.6,49.6-46.2c17.9-13.5,37.3-25.1,57.6-34.6c20.3-9.5,41.7-17,63.5-22.1
		c2.9-0.7,5.9-1.3,8.8-2l0,0v0L310.9,112.8z"
        />
      </g>
      <g>
        <path
          style={{stroke: fills[0], strokeWidth: '10px'}}
          fill={fills[3]}
          d="M599.7,134.3L599.7,134.3l-58.9,53.5l0,0c-1.5-1.7-3.1-3.3-4.7-5l0-0.1c-26.3-27.4-58-47.8-94.1-60.7
		c-29.3-10.4-59.7-15.2-90.4-14.1l0,0l0,0l-2.8-79.4l0,0c20-0.7,40.2,0.5,60,3.5c20.3,3.1,40.4,8.2,59.9,15.1
		c24,8.5,46.9,19.8,68.1,33.6c20.6,13.4,39.7,29.2,56.7,46.9C595.6,129.8,597.7,132.1,599.7,134.3L599.7,134.3z"
        />
      </g>
      <g>
        <path
          style={{stroke: fills[0], strokeWidth: '10px'}}
          fill={fills[4]}
          d="M682.8,380.2c-1.7,19.6-5.2,39.1-10.5,58.2l0,0c-0.8,2.9-1.7,5.8-2.5,8.7l0,0l0,0l-76-23.3l0,0
		c0.7-2.2,1.3-4.4,1.9-6.6c7.9-28.6,10.5-58,7.8-87.3c-3.6-38.8-16.6-76.6-37.7-109.5l0,0l0,0l66.9-42.9l0,0
		c13.9,21.7,25.2,45,33.6,69.3c8.4,24.5,14,50,16.3,75.7C684.4,341.6,684.5,361.1,682.8,380.2z"
        />
      </g>
    </svg>
  );
};
