import React, {useEffect, useRef} from 'react';
import {WidgetProps} from '../common';
import css from './BeatSaberWidget.module.scss';
import {findColorInRange} from '../../utils/rangeUtils';
import {useRandomId} from '../../hooks/useRandomId';
import {IS_SAFARI} from '../../utils/browserUtils';

export type BeatSaberWidgetProps = WidgetProps<
  {color: string},
  {heartColor: string; withHeart: boolean}
>;

const findDuration = (heartRate: number) => {
  if (heartRate < 60) {
    return '5000ms';
  } else if (heartRate < 80) {
    return '4000ms';
  } else if (heartRate < 110) {
    return '3000ms';
  } else if (heartRate < 140) {
    return '2000ms';
  } else {
    return '1000ms';
  }
};

export const BeatSaberWidget = ({
  pulse,
  configuration,
}: BeatSaberWidgetProps) => {
  const svgRef = React.useRef<SVGSVGElement>(null);
  const {heartColor} = configuration;
  const textColor = findColorInRange(configuration.ranges, {pulse});
  const textShadowFilterId = useRef(useRandomId('glowy-text-shadow')).current;
  const heartShadowFilterId = useRef(useRandomId('glowy-heart-shadow')).current;

  const duration = findDuration(pulse);
  useEffect(() => {
    svgRef.current?.style.setProperty('--line-color', heartColor);
    svgRef.current?.style.setProperty('--text-color', textColor);
    svgRef.current?.style.setProperty('--duration', duration);
  }, [configuration.heartColor, textColor, duration]);

  return (
    <svg
      style={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
      viewBox="-50 0 900 400"
      ref={svgRef}
    >
      <defs>
        <filter id={textShadowFilterId}>
          <feDropShadow dx={0} dy={0} stdDeviation={8} floodColor="#fff" />
          <feDropShadow
            dx={-3}
            dy={-3}
            stdDeviation={2}
            floodColor={textColor}
          />
          <feDropShadow
            dx={-1}
            dy={-1}
            stdDeviation={4}
            floodColor={textColor}
          />
          <feDropShadow
            dx={-1}
            dy={-1}
            stdDeviation={3}
            floodColor={textColor}
          />
        </filter>
        <filter id={heartShadowFilterId}>
          <feDropShadow dx={0} dy={0} stdDeviation={6} floodColor="#fff" />
          <feDropShadow
            dx={-1}
            dy={-1}
            stdDeviation={6}
            floodColor={heartColor}
          />
          <feDropShadow
            dx={-1}
            dy={1}
            stdDeviation={4}
            floodColor={heartColor}
          />
        </filter>
      </defs>

      {configuration.withHeart ? (
        <g
          className={css.heartbeatContainer}
          style={IS_SAFARI ? {filter: `url(#${heartShadowFilterId})`} : null}
        >
          <g transform="translate(-20, 150) scale(0.025,-0.025)" stroke="none">
            <path
              className={css.heartbeatPath}
              d="M1870 4966 c-870 -115 -1341 -1224 -802 -1888 425 -523 1404 -1674 1578 -1854 252 -263 465 -268 708 -18 90 92 881 1022 1066 1253 39 48 176 214 306 369 279 334 341 436 394 643 300 1174 -1093 1988 -1995 1166 -46 -42 -103 -77 -125 -77 -22 0 -79 35 -125 77 -298 271 -628 379 -1005 329z m374 -268 c159 -34 291 -104 442 -235 134 -117 270 -177 351 -156 18 5 57 15 87 21 30 7 116 67 190 133 625 556 1586 143 1586 -681 0 -364 -18 -390 -1367 -1978 -485 -571 -504 -584 -655 -448 -75 68 -554 615 -758 866 -49 61 -94 115 -100 120 -6 6 -51 60 -100 120 -49 61 -212 254 -361 430 -332 391 -370 446 -420 607 -212 685 399 1349 1105 1201z"
            />
          </g>
        </g>
      ) : null}

      <text
        fill="#fff"
        className={css.textPulse}
        x="200"
        y="184"
        dominantBaseline="auto"
        style={IS_SAFARI ? {filter: `url(#${textShadowFilterId})`} : null}
      >
        {pulse}
      </text>
    </svg>
  );
};
