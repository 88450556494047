import React from 'react';
import {useState, useEffect} from 'react';
import {WidgetProps} from '../common';
import css from './StarWarsWidget.module.scss';
import classNames from 'classnames';

const ANIMATION_TIME = 800;
const MAX_PULSE_ARRAY_LENGTH = 20;
const TIME_TO_SHOW_PULSE_AFTER_DELAY = 2700;

export type StarWarsWidgetProps = WidgetProps<{}>;

export const StarWarsWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: StarWarsWidgetProps) => {
  const [canShowPulse, setCanShowPulse] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const shouldShowEmptyState = emptyStateComponent && !pulse;
  const [pulseArray, setPulseArray] = useState<{key: string; pulse: number}[]>(
    []
  );

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (canShowPulse === true) {
      setPulseArray((prevPulseArray) => [
        ...prevPulseArray,
        {key: crypto.randomUUID(), pulse},
      ]);

      if (pulseArray.length > MAX_PULSE_ARRAY_LENGTH) {
        setPulseArray((prevPulse) =>
          [...prevPulse].slice(-MAX_PULSE_ARRAY_LENGTH)
        );
      }

      setCanShowPulse(false);

      setTimeout(() => {
        setCanShowPulse(true);
      }, ANIMATION_TIME);
    }

    const newTimeoutId = setInterval(() => {
      setPulseArray((prevPulseArray) => [
        ...prevPulseArray,
        {key: crypto.randomUUID(), pulse},
      ]);
      setCanShowPulse(false);
      const timeoutId1 = setTimeout(() => {
        setCanShowPulse(true);
      }, ANIMATION_TIME);
    }, TIME_TO_SHOW_PULSE_AFTER_DELAY);

    setTimeoutId(newTimeoutId);
  }, [pulse]);

  return (
    <div style={{height: '100%'}}>
      <svg width="100%" height="100%" viewBox="0 0 500 500">
        <foreignObject
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <div className={css.starWars}>
            <div className={css.crawlContainer}>
              {shouldShowEmptyState
                ? emptyStateComponent
                : pulseArray.map(({key, pulse}, index) => (
                    <div
                      className={classNames(css.crawl, css.crawlAnimation)}
                      key={key}
                    >
                      <text>{pulse}</text>
                    </div>
                  ))}
            </div>
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};
