import React, {useEffect, useState} from 'react';
import {WidgetProps} from '../common';
import {FontOption} from '../../controls/FontPicker/FontPicker';
import css from './JumpScareCounterWidget.module.scss';
import {loadCustumFont} from '../../utils/fontUtils';
import classNames from 'classnames';
import {convertHexToRgbMatrix} from '../../utils/colorUtils';
import {
  BACKGROUND_TYPE_TO_URL,
  JumpScareBackgroundType,
  JumpScareSecondaryImageType,
  MIN_TIME_BETWEEN_TRIGGER,
  SECONDARY_IMAGE_ACTIVE_TIME,
  SECONDARY_IMAGE_TYPE_TO_URL,
} from './JumpScareCounterWidget.constants';

export type JumpScareCounterWidgetProps = WidgetProps<
  {},
  {
    backgroundType?: JumpScareBackgroundType;
    backgroundColor?: string;

    secondaryImageType?: JumpScareSecondaryImageType;

    bpmText?: {font?: FontOption; color?: string};
    description: string;

    triggerHeartRate: number;
  }
>;

const DEFAULT_CONFIGURATION: JumpScareCounterWidgetProps['configuration'] = {
  backgroundColor: '#000000',

  description: '',
  triggerHeartRate: 120,
  bpmText: {color: '#ff0000'},
  ranges: [],
};

export const JumpScareCounterWidget = (props: JumpScareCounterWidgetProps) => {
  const {pulse} = props;
  const configuration = {
    ...DEFAULT_CONFIGURATION,
    ...props.configuration,
  };

  const [jumpscareCount, setJumpscareCount] = useState(0);
  const [lastTriggeredTime, setLastTriggeredTime] = useState(0);
  const [recentlyTriggered, setRecentlyTriggered] = useState(false);

  const secondaryImageUrl = recentlyTriggered
    ? SECONDARY_IMAGE_TYPE_TO_URL[configuration.secondaryImageType]?.active
    : SECONDARY_IMAGE_TYPE_TO_URL[configuration.secondaryImageType]?.default;

  useEffect(() => {
    if (
      pulse > configuration.triggerHeartRate &&
      Date.now() - lastTriggeredTime > MIN_TIME_BETWEEN_TRIGGER
    ) {
      setJumpscareCount(jumpscareCount + 1);
      setLastTriggeredTime(Date.now());
      setRecentlyTriggered(true);

      setTimeout(() => {
        setRecentlyTriggered(false);
      }, SECONDARY_IMAGE_ACTIVE_TIME);
    }
  }, [pulse]);

  useEffect(() => {
    if (configuration?.bpmText?.font) {
      loadCustumFont(configuration?.bpmText?.font);
    }
  }, [configuration?.bpmText?.font]);

  return (
    <svg width="100%" height="100%" viewBox="0 0 420 500">
      <defs>
        <filter id="bbx">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values={convertHexToRgbMatrix(
              configuration.backgroundColor ||
                DEFAULT_CONFIGURATION.backgroundColor
            )}
          ></feColorMatrix>
        </filter>
      </defs>

      {configuration.backgroundType && (
        <image
          href={BACKGROUND_TYPE_TO_URL[configuration.backgroundType]}
          width="380px"
          height="380px"
          filter="url(#bbx)"
          style={{position: 'relative', left: -10}}
        />
      )}

      <foreignObject
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div
          className={css.container}
          style={{
            fontFamily: configuration.bpmText?.font?.name,
            color: configuration.bpmText?.color,
          }}
        >
          <div className={css.pulse}>{jumpscareCount}</div>

          {configuration.description && (
            <div className={css.description}>{configuration.description}</div>
          )}

          {secondaryImageUrl && (
            <>
              {configuration.secondaryImageType ===
              JumpScareSecondaryImageType.SKELETON ? (
                <img
                  src={secondaryImageUrl}
                  className={classNames(css.secondaryImageSkeleton, {
                    [css.secondaryImageSkeleton]:
                      configuration.secondaryImageType ===
                      JumpScareSecondaryImageType.SKELETON,

                    [css.secondaryImageSimple]:
                      configuration.backgroundType ===
                      JumpScareBackgroundType.SIMPLE,
                    [css.secondaryImageCracked]:
                      configuration.backgroundType ===
                      JumpScareBackgroundType.CRACKED,
                    [css.secondaryImageOnGrass]:
                      configuration.backgroundType ===
                      JumpScareBackgroundType.ON_GRASS,
                    [css.secondaryImageRectangle]:
                      configuration.backgroundType ===
                      JumpScareBackgroundType.RECTANGLE,
                  })}
                />
              ) : (
                <div
                  style={{backgroundImage: `url("${secondaryImageUrl}")`}}
                  className={classNames(css.secondaryImage)}
                />
              )}
            </>
          )}
        </div>
      </foreignObject>
    </svg>
  );
};
