import React from 'react';

export class TextWidget extends React.Component {
  findRange = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return range;
      }
    }
    return null;
  };

  render() {
    let range = null;
    if (this.props.configuration) {
      range = this.findRange(this.props.configuration.ranges, this.props.pulse);
    }

    if (range != null) {
      return (
        <>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/csshake/1.5.3/csshake.min.css"
          />

          <div
            style={{
              backgroundColor: 'transparent',
              fontSize: '120px',
              fontFamily: "'" + this.props.configuration.font + "'",
              color: range.color,
              textAlign: this.props.configuration.alignment
                ? this.props.configuration.alignment
                : 'left',
            }}
            className={'fill-window shake-constant ' + range.animation}
            id={'heartRate'}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
              rel={'stylesheet'}
            />
            {range.text}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}
