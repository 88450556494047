// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uYjOS1kG6iXDhLdq9yaF{stroke-dasharray:1000;stroke-dashoffset:1000;animation:Vn9wkwD_PNUyQOwjIRwk 1s infinite}.jxS97ehRwKXt1KVBb1VT{stroke-dasharray:300;stroke-dashoffset:300;animation:UV1U82duQROKVjABvXaK 1s infinite}@keyframes UV1U82duQROKVjABvXaK{from{stroke-dashoffset:300}to{stroke-dashoffset:0}}@keyframes Vn9wkwD_PNUyQOwjIRwk{from{stroke-dashoffset:1000}to{stroke-dashoffset:0}}", "",{"version":3,"sources":["webpack://./src/controls/ImagePicker/images/EcgIcon/EcgIcon.module.scss"],"names":[],"mappings":"AAKA,sBAJE,qBAKqB,CAJrB,sBAIqB,CACrB,0CAAA,CAGF,sBATE,oBAUqB,CATrB,qBASqB,CACrB,0CAAA,CAGF,gCACE,KACE,qBAAA,CAEF,GACE,mBAAA,CAAA,CAIJ,gCACE,KACE,sBAAA,CAEF,GACE,mBAAA,CAAA","sourcesContent":["@mixin strkokeDash($dash, $name) {\n  stroke-dasharray: $dash;\n  stroke-dashoffset: $dash;\n}\n\n.animate1 {\n  @include strkokeDash(1000, dash);\n  animation: dash1000 1s infinite;\n}\n\n.animate2 {\n  @include strkokeDash(300, dash);\n  animation: dash300 1s infinite;\n}\n\n@keyframes dash300 {\n  from {\n    stroke-dashoffset: 300;\n  }\n  to {\n    stroke-dashoffset: 0;\n  }\n}\n\n@keyframes dash1000 {\n  from {\n    stroke-dashoffset: 1000;\n  }\n  to {\n    stroke-dashoffset: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animate1": "uYjOS1kG6iXDhLdq9yaF",
	"dash1000": "Vn9wkwD_PNUyQOwjIRwk",
	"animate2": "jxS97ehRwKXt1KVBb1VT",
	"dash300": "UV1U82duQROKVjABvXaK"
};
export default ___CSS_LOADER_EXPORT___;
