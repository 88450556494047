import React from 'react';
import p from './pixel_heart.png';
import './Widgets.css';
export class PixelHeartWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 1000,
      deadlineTime: new Date().getTime() - 10,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let currentTime = new Date().getTime();
    let deadlineTime = state.deadlineTime;
    if (currentTime > deadlineTime) {
      let duration = Math.max(Math.floor((60 / props.pulse) * 1000) - 100, 300);
      return {
        duration,
        deadlineTime: currentTime + duration + 30,
      };
    }
    return null;
  }

  render() {
    return (
      <img
        alt={'something went wrong'}
        style={{
          height: '50%',
          color: 'transparent',
          animationName: 'pulse_animation',
          animationDuration: this.state.duration + 'ms',
          transformOrigin: '70% 70%',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
        }}
        src={
          this.props.configuration.imageUrl
            ? this.props.configuration.imageUrl
            : p
        }
      ></img>
    );
  }
}
