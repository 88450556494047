// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Diablo\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/diablo-style/Diablo Light.ttf\")}.edpNYsc6qNgCEfxHEKt5{font-family:\"Diablo\";color:\"#fff\";stroke:#000;stroke-width:2px;text-shadow:none}.eS131SVCERL7Bo3eaxtR~defs stop{transition:.5s}", "",{"version":3,"sources":["webpack://./src/widgets/DiabloWidget/DiabloWidget.module.scss"],"names":[],"mappings":"AAAA,WACE,oBAAA,CACA,2GAAA,CAGF,sBACE,oBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA,CACA,gBAAA,CAGF,gCACE,cAAA","sourcesContent":["@font-face {\n  font-family: 'Diablo';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/diablo-style/Diablo Light.ttf');\n}\n\n.bpm {\n  font-family: 'Diablo';\n  color: '#fff';\n  stroke: #000;\n  stroke-width: 2px;\n  text-shadow: none;\n}\n\n.heart ~ defs stop {\n  transition: 0.5s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bpm": "edpNYsc6qNgCEfxHEKt5",
	"heart": "eS131SVCERL7Bo3eaxtR"
};
export default ___CSS_LOADER_EXPORT___;
