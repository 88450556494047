import React, {useEffect} from 'react';
import {ResizableTextPath} from '../../common/ResizableTextPath';
import {FontOption} from '../../controls/FontPicker/FontPicker';
import {SPEED_TEST_ARROW_TYPES} from '../../controls/SpeedTestArrowPicker/SpeedTestArrowPicker.constants';
import {
  AlfaArrow,
  HeartArrow,
  NeedleArrow,
  SpeedTestArrow,
} from '../../controls/SpeedTestArrowPicker/SpeedTestArrows/SpeedTestArrows';
import {OneOf} from '../../types/internal';
import {loadCustumFont} from '../../utils/fontUtils';
import {WidgetProps} from '../common';
import css from './SpeedTestWidget.module.scss';
import {useRandomId} from '../../hooks/useRandomId';

type GradientRange = {
  color: string;
  offset: number;
};

export type SpeedTestWidgetProps = WidgetProps<
  {},
  {
    max?: number;
    min?: number;
    bpmText?: {
      color?: string;
      font?: FontOption;
    };
    additionalText?: {
      color?: string;
      value?: string;
      show?: boolean;
    };
    arrow?: {
      show?: boolean;
      color?: string;
      type?: OneOf<typeof SPEED_TEST_ARROW_TYPES>;
    };
    arc?: {
      gradientRanges?: {
        color: string;
        offset: number;
      }[];
      strokeWidth?: number;
      backgroundColor?: string;
      showShadow?: boolean;
    };
    marksOptions?: {
      color?: string;
      withLines?: boolean;
      withNumbers?: boolean;
      quantity?: number;
    };
  }
>;

const DEFAULT_GRADIENT_RANGES: GradientRange[] = [
  {color: '#00b7fe', offset: 0},
  {color: '#50edf5', offset: 25},
  {color: '#6cffd1', offset: 50},
  {color: '#75ff9a', offset: 75},
  {color: '#7cff74', offset: 100},
];

const ADDITIONAL_TEXT_MAX_WIDTH = 200;

export const SpeedTestWidget = ({
  pulse,
  configuration,
}: SpeedTestWidgetProps) => {
  const {
    marksOptions = {},
    min = 50,
    max = 150,
    arc: {
      gradientRanges = DEFAULT_GRADIENT_RANGES,
      strokeWidth = 40,
      showShadow,
      backgroundColor: arcBackgroundColor = '#223050',
    } = {},
    additionalText = {
      color: '#fff',
      value: 'PULSOID',
      show: true,
    },
    bpmText,
    arrow,
  } = configuration;
  const arrowColor = arrow?.color || '#fff';
  const textColor = bpmText?.color || '#fff';
  const marksColor = marksOptions.color || '#fff';

  const arrowType = arrow?.type || 'speedtest';
  const arrowMinAngle = -40;
  const arrowMaxAngle = 220;
  const {
    withLines = false,
    withNumbers = true,
    quantity: markQuantity = 6,
  } = marksOptions;

  const getArrowAngle = () => {
    const anglePulse = Math.min(Math.max(pulse, min), max);
    return (
      arrowMinAngle +
      ((anglePulse - min) * (arrowMaxAngle - arrowMinAngle)) / (max - min)
    );
  };
  const mainArcDasharray = 800;
  const getArcDashoffset = () => {
    const anglePulse = Math.min(Math.max(pulse, min), max);
    return (
      mainArcDasharray -
      ((anglePulse - min) * (mainArcDasharray - 45)) / (max - min)
    );
  };

  const loadFont = async () => loadCustumFont(bpmText?.font);
  useEffect(() => {
    if (bpmText?.font?.url) {
      loadFont();
    }
  }, [bpmText?.font]);

  const svgId = {
    arcGradient: useRandomId('arcGradient'),
    shadowFilter: useRandomId('shadowFilter'),
  };

  return (
    <svg
      className={css.speed}
      width="100%"
      height="100%"
      viewBox="-20 -40 400 380"
    >
      <defs>
        <linearGradient
          id={svgId.arcGradient}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          {gradientRanges.map((range, index) => (
            <stop
              key={index}
              offset={`${range.offset}%`}
              stopColor={range.color}
            />
          ))}
        </linearGradient>
      </defs>
      <defs>
        <filter id={svgId.shadowFilter}>
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
        </filter>
      </defs>

      {showShadow ? (
        <path
          style={{
            fill: 'none',
            fillRule: 'evenodd',
            strokeWidth: Math.max(strokeWidth + 40 * 0.85, 52),
            strokeMiterlimit: 1,
            strokeOpacity: 0.1,
            transformOrigin: '180px 180px',
            transform: 'scale(0.85, 0.85)',
            strokeDasharray: `${mainArcDasharray} 1000`,
            strokeDashoffset: getArcDashoffset(),
            transition: 'stroke-dashoffset 0.5s linear',
          }}
          stroke={`url(#${svgId.arcGradient})`}
          filter={`url(#${svgId.shadowFilter})`}
          d="m 66.906305,293.33748 a 160.10918,160.10918 0 0 1 10e-7,-226.428566 a 160.10918,160.10918 0 0 1 226.428574,10e-7 a 160.10918,160.10918 0 0 1 -10e-6,226.428565"
        />
      ) : null}

      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          strokeWidth: strokeWidth - 2,
          strokeMiterlimit: 4,
          stroke: arcBackgroundColor,
          strokeOpacity: 0.5,
        }}
        d="m 66.906305,293.33748 a 160.10918,160.10918 0 0 1 10e-7,-226.428566 a 160.10918,160.10918 0 0 1 226.428574,10e-7 a 160.10918,160.10918 0 0 1 -10e-6,226.428565"
      />
      <path
        style={{
          fill: 'none',
          fillRule: 'evenodd',
          strokeWidth: strokeWidth,
          strokeMiterlimit: 4,
          strokeDasharray: `${mainArcDasharray} 1000`,
          strokeDashoffset: getArcDashoffset(),
          transition: 'stroke-dashoffset 0.5s linear',
        }}
        stroke={`url(#${svgId.arcGradient})`}
        d="m 66.906305,293.33748 a 160.10918,160.10918 0 0 1 10e-7,-226.428566 a 160.10918,160.10918 0 0 1 226.428574,10e-7 a 160.10918,160.10918 0 0 1 -10e-6,226.428565"
      />

      {withLines || withNumbers
        ? new Array(markQuantity).fill(0).map((_, index) => {
            const startAngle = 226;
            const endAngle = 494;

            const markText = Math.round(
              min + (index * (max - min)) / (markQuantity - 1)
            );

            const angle =
              startAngle +
              (index * (endAngle - startAngle)) / (markQuantity - 1);
            return (
              <>
                {withLines ? (
                  <line
                    key={index}
                    className={css.speedMark}
                    x1="180"
                    y1="14"
                    x2="180"
                    y2="26"
                    transform={`rotate(${angle} 180 180)`}
                    style={{stroke: marksColor}}
                  />
                ) : null}
                {withNumbers ? (
                  <text
                    x={
                      166 +
                      (140 - strokeWidth / 2) *
                        Math.cos((angle * Math.PI) / 180 - Math.PI / 2)
                    }
                    y={
                      185 +
                      (140 - strokeWidth / 2) *
                        Math.sin((angle * Math.PI) / 180 - Math.PI / 2)
                    }
                    className={css.speedMarkLabel}
                    style={{fill: marksColor}}
                  >
                    {markText}
                  </text>
                ) : null}
              </>
            );
          })
        : null}

      {additionalText?.show ? (
        <ResizableTextPath
          maxWidth={200}
          pathToFollow={<path d="M 0 220 H 360" fill="transparent" />}
          text={additionalText?.value}
          fontSize={28}
          fontFamily="Arial"
          fill={additionalText?.color}
          method="align"
          lengthAdjust="spacingAndGlyphs"
        />
      ) : null}

      <text
        x="50%"
        y="280"
        transform="translate(-20, 0)"
        className={css.pulseText}
        dominantBaseline="middle"
        textAnchor="middle"
        style={{fill: textColor, fontFamily: `"${bpmText?.font?.name}"`}}
      >
        {pulse}
      </text>

      {arrow?.show ? (
        <>
          {arrowType === SPEED_TEST_ARROW_TYPES.SPEEDTEST ? (
            <SpeedTestArrow
              max={max}
              pulse={pulse}
              angle={getArrowAngle()}
              color={arrowColor}
            />
          ) : null}
          {arrowType === SPEED_TEST_ARROW_TYPES.HEART ? (
            <HeartArrow
              max={max}
              pulse={pulse}
              angle={getArrowAngle()}
              color={arrowColor}
            />
          ) : null}
          {arrowType === SPEED_TEST_ARROW_TYPES.NEEDLE ? (
            <NeedleArrow
              max={max}
              pulse={pulse}
              angle={getArrowAngle()}
              color={arrowColor}
            />
          ) : null}
          {arrowType === SPEED_TEST_ARROW_TYPES.ALFA ? (
            <AlfaArrow
              max={max}
              pulse={pulse}
              angle={getArrowAngle()}
              color={arrowColor}
            />
          ) : null}
        </>
      ) : null}
    </svg>
  );
};
