import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import './Widgets.css';

export const HeartAnimationWidget = (props) => {
  const [animationDuration, setAnimationDuration] = useState(1000);
  const pulseRef = useRef(props.pulse);
  const durationRef = useRef(animationDuration);
  const animationRef = useRef(null);

  const calculateDuration = (pulse) => {
    let duration = (60 / pulse) * 1000;
    let normalizedDuration = Math.max(Math.ceil(duration), 300);
    return normalizedDuration;
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('heart rate animated widget with fixed animation');
  }, []);

  useEffect(() => {
    pulseRef.current = props.pulse;
    durationRef.current = calculateDuration(props.pulse);
  }, [props.pulse]);

  return (
    <div
      style={{
        backgroundColor: props.fromLaunch
          ? props.configuration.backgroundColor
          : 'transparent',
      }}
      className={'fill-window'}
    >
      {props.configuration && (
        <svg width="150" height="150" viewBox="-700 -700 2400 2400">
          <g transform="translate(400 400)">
            <path
              transform="translate(-200 -200),scale(25)"
              style={{
                transformOrigin: 'center',
                transformBox: 'fill-box',
              }}
              fill={props.configuration.color}
              d="M51.911,16.242C51.152,7.888,45.239,1.827,37.839,1.827c-4.93,0-9.444,2.653-11.984,6.905
                c-2.517-4.307-6.846-6.906-11.697-6.906c-7.399,0-13.313,6.061-14.071,14.415c-0.06,0.369-0.306,2.311,0.442,5.478
                c1.078,4.568,3.568,8.723,7.199,12.013l18.115,16.439l18.426-16.438c3.631-3.291,6.121-7.445,7.199-12.014
                C52.216,18.553,51.97,16.611,51.911,16.242z"
            />
            <AnimationTransform
              ref={animationRef}
              animationDuration={animationDuration}
              values="1; 1.3; 1.15; 1.3; 1.3; 1;"
              attributeName="transform"
              type="scale"
              additive="sum"
              restart="whenNotActive"
              onAnimationEnd={() => {
                setAnimationDuration(durationRef.current);
                animationRef.current.restartAnimation();
              }}
            ></AnimationTransform>
          </g>
        </svg>
      )}
    </div>
  );
};

const AnimationTransform = React.forwardRef(
  (
    {
      animationDuration,
      onAnimationEnd,
      values,
      attributeName,
      type,
      additive,
      restart,
    },
    ref
  ) => {
    const animateRef = useRef(null);

    useEffect(() => {
      const animateElement = animateRef.current;

      // Function to be called when animation ends
      const handleAnimationEnd = () => {
        if (onAnimationEnd) {
          onAnimationEnd();
        }
      };

      // Attach the event listener
      animateElement.addEventListener('endEvent', handleAnimationEnd);

      // Clean up the event listener on component unmount
      return () => {
        animateElement.removeEventListener('endEvent', handleAnimationEnd);
      };
    }, [onAnimationEnd]);

    useImperativeHandle(ref, () => ({
      getAnimationElement: () => animateRef.current,
      restartAnimation: () => animateRef.current.beginElement(),
    }));

    return (
      <animateTransform
        ref={animateRef}
        attributeName={attributeName}
        type={type}
        values={values}
        dur={`${animationDuration}ms`}
        additive={additive}
        restart={restart}
      />
    );
  }
);
