// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Lato Bold\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Lato-Bold.ttf\") format(\"opentype\")}.pxz96doXg0HuLNp86okx{position:relative;top:248px;left:-114px;font-size:45px;font-family:\"Lato Bold\";text-align:center;color:#3c306a}.E7kUeiE0G2J1pNKE2qdj{position:relative;top:245px;left:-114px;font-size:14px;font-family:\"Lato Bold\";text-align:center;color:#3c306a}", "",{"version":3,"sources":["webpack://./src/widgets/UnicornWidget/UnicornWidget.module.scss"],"names":[],"mappings":"AAAA,WACE,uBAAA,CACA,oHAAA,CAIF,sBACE,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,cAAA,CACA,uBAAA,CACA,iBAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,cAAA,CACA,uBAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":["@font-face {\n  font-family: 'Lato Bold';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Lato-Bold.ttf')\n    format('opentype');\n}\n\n.pulse {\n  position: relative;\n  top: 248px;\n  left: -114px;\n\n  font-size: 45px;\n  font-family: 'Lato Bold';\n  text-align: center;\n  color: #3c306a;\n}\n\n.bpm {\n  position: relative;\n  top: 245px;\n  left: -114px;\n\n  font-size: 14px;\n  font-family: 'Lato Bold';\n  text-align: center;\n  color: #3c306a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "pxz96doXg0HuLNp86okx",
	"bpm": "E7kUeiE0G2J1pNKE2qdj"
};
export default ___CSS_LOADER_EXPORT___;
