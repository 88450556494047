import React from 'react';

export class PictureWidget extends React.Component {
  findPicture = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return range.pictureUrl === undefined ? (
          <></>
        ) : (
          <img alt="something bad is happened" src={range.pictureUrl} />
        );
      }
    }

    return (
      <img
        alt="something bad is happened"
        src={ranges[ranges.length - 1].pictureUrl}
      />
    );
  };

  render() {
    let picture;
    if (this.props.configuration) {
      picture = this.findPicture(
        this.props.configuration.ranges,
        this.props.pulse
      );
    } else {
      picture = <></>;
    }

    return (
      <>
        {this.props.configuration && (
          <div className={'fill-window'}>{picture}</div>
        )}
      </>
    );
  }
}
