export const MetaEcgWidget = 'c59b65e8-1c8b-4af2-b8f2-fd22fa9062a4'; // +
export const MetaBpmWidget = 'f8a131ce-6a47-4fc8-a1af-631a4d97644b';
export const MetaPixelWidget = '085f1f88-d599-4857-89b3-d84c4ed665b8';
export const MetaCaloriesWidget = '7a78ac9f-a682-46b5-ab6c-606ee631bbb2';
export const MetaRealHeartWidget = '55af6abf-e378-4c9d-84bf-bc7565fafaf5';
export const MetaPictureWidget = '18fd6009-c908-4e6e-a461-920108cd2998';
export const MetaSoundWidget = '8549a7b5-e0b4-4149-b60e-d52ca0462c93';
export const MetaGraphWidget = 'bbffeb93-7d72-4afb-a99b-37916f057810';
export const MetaMaxWidget = 'b8bb76b6-28be-4216-a45e-c8eff31ec547';
export const MetaHeartAnimationWidget = 'bc5ba654-fcff-4ab8-b446-182d111068ba';
export const MetaTextWidget = 'c87d7887-c3e2-487b-b04d-dab540261f10';
export const MetaValorantWidget = '9ac10e79-4da7-47ab-85c4-32665f740521';
export const MetaVideoWidget = 'e3e94f6f-60e8-490b-a670-5e62abe0d241';
export const MetaFitnessZoneWidget = 'b92399a1-8516-49df-8dd4-70bd6010ade0';
export const MetaPulsoidMedicalWidget = '487a7270-cd96-40c8-951e-5c39024526a9';
export const MetaStromnoWdiget = '8162cd92-90cb-4454-86d5-03bca4cab4e9';
export const MetaCyberpunkHeartWidget = '143da3c1-3c62-4959-9a76-a660a83f3871';
export const MetaBpmPercentageWidget = 'ad65cdc6-5c4f-476c-980f-68fc460776a3';
export const MetaWindowsNtWidget = 'b828337f-cdb0-49d2-8e55-9b1285cd0d22';
export const MetaGaugeWidget = '45d0787f-f6be-4176-8076-6e39d2893868';
export const MetaBeatsSaberWidget = '2654f68c-39e5-4fe6-bf7f-5f8ee82b4808';
export const MetaPulsoidStromnoWidget = '7d8bec8f-9505-4305-bb1e-611dd9ee1760';
export const MetaBpmBasicWidget = 'bf6bdf21-10a7-4a28-97ec-a239e6c77f8b';
export const MetaBpmAdvancedWidget = '7958fba1-53e5-4da6-b15b-4789cecd148f';
export const MetaGeometryDashWidget = 'dddd6ad4-2d4d-4cb4-a36b-cf255fb50002';
export const MeatBeatsSaberV2Widget = 'f0654a12-3a59-4beb-b567-c5febda19c48';
export const MeatDeathloopWidget = '3c455966-f232-4383-b271-8aaae10dc6c5';
export const MetaHalloweenMoon = 'a9a9b2b7-4084-4865-8ab8-3602290053c9';
export const MetaSoundWidgetV2 = '0eca0651-08ce-43d3-87db-0ac07f2a2122';
export const MetaJumpScareCounterWidget =
  '74979489-b18d-4916-9fa5-13578413579d';
export const MetaGraphV2 = 'b51157e8-204c-4923-b55a-a8a417de14e1';
export const MetaGlowyWidget = '024bead1-8cbe-4cff-9c22-da43cd564ef0';
export const MetaMinecraftWidget = '09ffc325-8c85-4464-b191-e5982c4f2c46';
export const MetaReferralWidget = '01035006-495e-42b1-8785-31b3eab8f46a';
export const MetaResidentEvilWidget = 'e3a6a781-3bbc-471c-bc52-9d98c89b42a7';
export const MetaDeadByDaylightWidget = '36e6464c-0508-409b-8e1d-336a70dced0d';
export const MetaDeadSpaceWidget = 'd938e320-e758-48c5-808f-f9e104fb833b';
export const MetaChristmasWidget = '128a8629-f77c-4ecb-a432-d4e059dcd616';
export const MetaSpeedtestWidget = 'a19dec49-0566-423b-9b2a-22c7da7f52ce';
export const MetaPrideBpmBasicWidget = '01baa99c-307d-44ca-bd7a-6b5eed40d664';
export const MetaDiabloWidget = 'd666c1ad-0249-11ee-be56-0242ac120002';
export const MetaUnicornWidget = '5594c58e-d11c-4013-bc6d-884f5e371461';
export const MetaAnimeBarWidget = '45f9cc8c-d063-481a-9bd9-c5c632146c39';
export const MetaAlanWakeWidget = '5f75d534-40a8-4809-9260-c11ffd1f368b';
export const MetaAloneInTheDarkWidget = '8179164d-de75-4761-afa2-c3b3dbc8dfac';
export const MetaFalloutWidget = '5d8ee005-12ec-40b9-a052-9b60137709a1';
export const MetaStarWarsWidget = '12db780a-72cc-419a-980b-ea082c4b168d';
export const MetaValorantWidgetV2 = '627b1fba-9ab1-4d43-afb4-8f78281432f0';
export const MetaEldenRingWidget = '4e58c40b-d5bd-4129-9117-46b385b941e4';
export const MetaSilentHill2Widget = '2d1e8e9d-c271-4580-b28c-0012d7e07be1';
export const MetaPredictionWidget = '73298bd1-ed0a-44df-bd90-b74ad74af751';

export const AllMetaIds = [
  MetaPredictionWidget,
  MetaEcgWidget,
  MetaBpmWidget,
  MetaPixelWidget,
  MetaCaloriesWidget,
  MetaRealHeartWidget,
  MetaPictureWidget,
  MetaSoundWidget,
  MetaGraphWidget,
  MetaMaxWidget,
  MetaHeartAnimationWidget,
  MetaTextWidget,
  MetaValorantWidget,
  MetaVideoWidget,
  MetaFitnessZoneWidget,
  MetaPulsoidMedicalWidget,
  MetaStromnoWdiget,
  MetaCyberpunkHeartWidget,
  MetaBpmPercentageWidget,
  MetaWindowsNtWidget,
  MetaGaugeWidget,
  MetaBeatsSaberWidget,
  MetaPulsoidStromnoWidget,
  MetaBpmBasicWidget,
  MetaBpmAdvancedWidget,
  MetaGeometryDashWidget,
  MeatBeatsSaberV2Widget,
  MeatDeathloopWidget,
  MetaHalloweenMoon,
  MetaSoundWidgetV2,
  MetaJumpScareCounterWidget,
  MetaGraphV2,
  MetaGlowyWidget,
  MetaMinecraftWidget,
  MetaReferralWidget,
  MetaResidentEvilWidget,
  MetaDeadByDaylightWidget,
  MetaDeadSpaceWidget,
  MetaChristmasWidget,
  MetaSpeedtestWidget,
  MetaPrideBpmBasicWidget,
  MetaDiabloWidget,
  MetaUnicornWidget,
  MetaAnimeBarWidget,
  MetaAlanWakeWidget,
  MetaAloneInTheDarkWidget,
  MetaFalloutWidget,
  MetaStarWarsWidget,
  MetaValorantWidgetV2,
  MetaEldenRingWidget,
  MetaSilentHill2Widget,
];
