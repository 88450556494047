import React, {useMemo} from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartPictureIcon = (
  {
    color = '#000',
    size = 100,
    style,
    withAnimation = false,
    stroke,
  }: HeartIconProps,
  imageUrl: string
) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="65 65 200 200"
        width={size}
        height={size}
        style={style}
      >
        <image href={imageUrl} height="330" width="330" />
        {withAnimation && <HeartIconAnimateTransform />}
      </svg>
    </>
  );
};

export const HeartGayMen = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/gay_men_heart.png'
  );
export const HeartIntersex = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/intersex_heart.png'
  );
export const HeartLesbian = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/lesbian_heart.png'
  );
export const HeartNonBinary = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/nonbinary_heart.png'
  );
export const HeartPanSexual = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/pansexual_heart.png'
  );
export const HeartPride = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/pride_heart.png'
  );
export const HeartPrideProgress = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/progress_pride_flag.png'
  );
export const HeartTransGender = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/trangender_heart.png'
  );

export const HeartAsexual = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/asexual_heart.png'
  );
export const HeartBisexual = (props: HeartIconProps) =>
  HeartPictureIcon(
    props,
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/bisexual_heart.png'
  );
