import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartSimpleIcon2 = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
        d="M 80.464 28.756 C 80.854 36.516 77.304 43.126 73.014 49.236 C 66.164 58.986 57.334 66.716 47.744 73.656 C 45.924 74.976 44.034 76.206 42.194 77.506 C 41.674 77.876 41.264 78.096 40.614 77.626 C 28.524 68.986 17.224 59.496 8.894 46.986 C 5.834 42.386 3.284 37.516 2.474 31.986 C 0.734 20.216 6.804 10.986 18.214 8.536 C 27.394 6.596 36.104 11.336 40.124 19.936 C 40.384 20.486 40.534 21.146 41.264 21.196 C 41.994 21.246 42.264 20.486 42.534 19.906 C 48.73 5.98 67.678 3.984 76.64 16.313 C 78.458 18.813 79.665 21.705 80.164 24.756 C 80.374 26.079 80.474 27.416 80.464 28.756 Z"
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
