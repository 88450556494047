import React, {useEffect} from 'react';
import {findColorInRange} from '../../utils/rangeUtils';
import css from './DeadSpaceWidget.module.scss';

const colors = {
  level0: '#7accb1',
  level1: '#85b698',
  level2: '#91a07e',
  level3: '#9c8a65',
  level4: '#a7744c',
  level5: '#b25e33',
  level6: '#bd4819',
  level7: '#c83200',

  border: '#a68d5b',
};

export type DeadSpaceWidgetProps = {
  pulse: number;
  glitch: boolean;
};

const ranges = [
  {from: 0, to: 60, color: colors.level0},
  {from: 61, to: 75, color: colors.level1},
  {from: 76, to: 90, color: colors.level2},
  {from: 91, to: 105, color: colors.level3},
  {from: 106, to: 120, color: colors.level4},
  {from: 121, to: 135, color: colors.level5},
  {from: 136, to: 150, color: colors.level6},
  {from: 151, to: 999, color: colors.level7},
];

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const getGlitchProbability = (pulse: number) => {
  if (pulse <= 85) {
    return 0.025;
  } else if (pulse <= 100) {
    return 0.1;
  } else {
    return 0.15;
  }
};

export const DeadSpaceWidget = ({pulse}: DeadSpaceWidgetProps) => {
  const [glitch, setGlitch] = React.useState(false);
  const [glitchInProgress, setGlitchInProgress] = React.useState(false);
  const glitchProbability = React.useRef(getGlitchProbability(pulse));

  const pulseH = Math.floor(pulse / 100);
  const pulseT = Math.floor((pulse - pulseH * 100) / 10);
  const pulseU = pulse - pulseH * 100 - pulseT * 10;

  const heartColor = findColorInRange(ranges, {pulse});
  const hrIntensityIndex = ranges.findIndex((r) => r.color === heartColor);

  const glitchTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const startGlitch = async () => {
    setGlitch(true);
    await delay(100);
    setGlitch(false);
    await delay(300);

    setGlitch(true);
    await delay(200);
    setGlitch(false);
    await delay(80);

    setGlitch(true);
    await delay(400);
    setGlitch(false);
    await delay(10);

    setGlitch(true);
    await delay(2000);
    setGlitch(false);
  };

  const runGlitchTimer = () => {
    if (glitchTimeout.current) {
      clearTimeout(glitchTimeout.current);
    }

    glitchTimeout.current = setTimeout(async () => {
      await checkGlitch();

      runGlitchTimer();
    }, 2000);
  };

  const checkGlitch = async () => {
    if (glitchInProgress) {
      return;
    }

    if (Math.random() < glitchProbability.current) {
      setGlitchInProgress(true);
      await startGlitch();
      setGlitchInProgress(false);
    }
  };

  useEffect(runGlitchTimer, []);
  useEffect(() => {
    glitchProbability.current = getGlitchProbability(pulse);
  }, [pulse]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="336 155 428 245"
      width="100%"
      height="100%"
    >
      <defs>
        <pattern id="dotted-text" viewBox="0,0,5,5" width="4%" height="2.5%">
          <circle
            cx="2.5"
            cy="2.5"
            r="1.2"
            style={{stroke: 'none', fill: colors.border}}
          />
        </pattern>
      </defs>

      <clipPath id="borderline-cp">
        <use href="#widget-borderline" />
      </clipPath>

      <g id="design">
        <g id="background">
          <path
            className={css.background__outer}
            d="m337 394.9v-239.3h418.1v203.5l-35.9 35.8z"
          />
          <path
            className={css.background__topBar}
            d="m449.2 195.6l-30-30h295.9v30z"
          />

          <path
            className={css.background__inner}
            d="m347 354.9v-189.3h58.1l40 40h280v-40h20v189.3l-10 10h-378.1z"
          />
          <image
            opacity={0.9}
            x="313px"
            y="100px"
            width="490px"
            height="350px"
            clipPath="url(#borderline-cp)"
            href={
              'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/deadspace/overlay1.png'
            }
            result="img"
          />
        </g>

        <g style={{opacity: 0.3}}>
          <g id="heart-projection-grid">
            <path
              className={css.netGridLine}
              style={{opacity: 0.1}}
              d="m404.3 225.6h250.8v111.9h-250.8z"
            />
            <path
              style={{opacity: 0.3}}
              className={css.netGridLine}
              d="m441.5 225.6h223.6v104.5h-223.6z"
            />
            <path
              style={{opacity: 0.4}}
              className={css.netGridLine}
              d="m478.8 225.6h196.3v97.1h-196.3z"
            />
            <path
              style={{opacity: 0.5}}
              className={css.netGridLine}
              d="m516.1 225.6h169v89.7h-169z"
            />
            <path
              style={{opacity: 0.6}}
              className={css.netGridLine}
              d="m553.3 225.6h141.8v82.3h-141.8z"
            />
            <path
              style={{opacity: 0.7}}
              className={css.netGridLine}
              d="m590.6 225.6h114.5v74.9h-114.5z"
            />
            <path
              style={{opacity: 0.9}}
              className={css.netGridLine}
              d="m627.8 225.6h87.3v67.5h-87.3z"
            />
            <path className={css.netGridLine} d="m665.1 225.6h60v60h-60z" />

            <path
              className={css.strokedNetGridLine}
              d="m725.1 285.3l-80 59.6"
            />
            <path
              className={css.strokedNetGridLine}
              d="m665.1 285.6l-298.1 59.3"
            />
          </g>
          <g id="heart-square-grid">
            <path className={css.netGridLine} d="m665.1 225.6h10v10h-10z" />
            <path className={css.netGridLine} d="m675.1 225.6h10v10h-10z" />
            <path className={css.netGridLine} d="m685.1 225.6h10v10h-10z" />
            <path className={css.netGridLine} d="m695.1 225.6h10v10h-10z" />
            <path className={css.netGridLine} d="m705.1 225.6h10v10h-10z" />
            <path className={css.netGridLine} d="m715.1 225.6h10v10h-10z" />
            <path className={css.netGridLine} d="m665.1 235.6h10v10h-10z" />
            <path className={css.netGridLine} d="m675.1 235.6h10v10h-10z" />
            <path className={css.netGridLine} d="m685.1 235.6h10v10h-10z" />
            <path className={css.netGridLine} d="m695.1 235.6h10v10h-10z" />
            <path className={css.netGridLine} d="m705.1 235.6h10v10h-10z" />
            <path className={css.netGridLine} d="m715.1 235.6h10v10h-10z" />
            <path className={css.netGridLine} d="m665.1 245.6h10v10h-10z" />
            <path className={css.netGridLine} d="m675.1 245.6h10v10h-10z" />
            <path className={css.netGridLine} d="m685.1 245.6h10v10h-10z" />
            <path className={css.netGridLine} d="m695.1 245.6h10v10h-10z" />
            <path className={css.netGridLine} d="m705.1 245.6h10v10h-10z" />
            <path className={css.netGridLine} d="m715.1 245.6h10v10h-10z" />
            <path className={css.netGridLine} d="m665.1 255.6h10v10h-10z" />
            <path className={css.netGridLine} d="m675.1 255.6h10v10h-10z" />
            <path className={css.netGridLine} d="m685.1 255.6h10v10h-10z" />
            <path className={css.netGridLine} d="m695.1 255.6h10v10h-10z" />
            <path className={css.netGridLine} d="m705.1 255.6h10v10h-10z" />
            <path className={css.netGridLine} d="m715.1 255.6h10v10h-10z" />
            <path className={css.netGridLine} d="m665.1 265.6h10v10h-10z" />
            <path className={css.netGridLine} d="m675.1 265.6h10v10h-10z" />
            <path className={css.netGridLine} d="m685.1 265.6h10v10h-10z" />
            <path className={css.netGridLine} d="m695.1 265.6h10v10h-10z" />
            <path className={css.netGridLine} d="m705.1 265.6h10v10h-10z" />
            <path className={css.netGridLine} d="m715.1 265.6h10v10h-10z" />
            <path className={css.netGridLine} d="m665.1 275.6h10v10h-10z" />
            <path className={css.netGridLine} d="m675.1 275.6h10v10h-10z" />
            <path className={css.netGridLine} d="m685.1 275.6h10v10h-10z" />
            <path className={css.netGridLine} d="m695.1 275.6h10v10h-10z" />
            <path className={css.netGridLine} d="m705.1 275.6h10v10h-10z" />
            <path className={css.netGridLine} d="m715.1 275.6h10v10h-10z" />
          </g>
        </g>

        <path
          id="heart"
          style={{fill: heartColor}}
          d="m715.1 243.6v10.1l-20 19.9-20-19.9v-10.1l5-5h8l7 7 7-7h8z"
        />

        <g id="top-bpm-indicator">
          <path
            style={{fill: colors.level0}}
            d="m449.2 195.6l-30-30h35.2l30 30z"
          />
          <path
            style={{fill: colors.level1, opacity: hrIntensityIndex > 0 ? 1 : 0}}
            d="m484.4 195.6l-30-30h35.3l30 30z"
          />
          <path
            style={{fill: colors.level2, opacity: hrIntensityIndex > 1 ? 1 : 0}}
            d="m519.7 195.6l-30-30h35.2l30 30z"
          />
          <path
            style={{fill: colors.level3, opacity: hrIntensityIndex > 2 ? 1 : 0}}
            d="m554.9 195.6l-30-30h35.2l30 30z"
          />
          <path
            style={{fill: colors.level4, opacity: hrIntensityIndex > 3 ? 1 : 0}}
            d="m590.1 195.6l-30-30h35.3l30 30z"
          />
          <path
            fill="url(#bg)"
            d="m625.4 195.6l-30-30h35.2l30 30z"
            style={{fill: colors.level5, opacity: hrIntensityIndex > 4 ? 1 : 0}}
          />
          <path
            style={{fill: colors.level6, opacity: hrIntensityIndex > 5 ? 1 : 0}}
            d="m660.6 195.6l-30-30h35.2l30 30z"
          />
          <path
            style={{fill: colors.level7, opacity: hrIntensityIndex > 6 ? 1 : 0}}
            d="m695.8 195.6l-30-30h49.3v30z"
          />

          <g id="top-bpm-indicator__border">
            <path
              className={css.topIndicatorBorderLine}
              d="m449.2 195.6l-30-30h295.9v30z"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m454.4 165.6l30 30"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m489.7 165.6l30 30"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m524.9 165.6l30 30"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m560.1 165.6l30 30"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m595.4 165.6l30 30"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m630.6 165.6l30 30"
            />
            <path
              className={css.topIndicatorBorderLine}
              d="m665.8 165.6l30 30"
            />
          </g>
        </g>

        <image
          clipPath="url(#borderline-cp)"
          opacity={0.2}
          x="313px"
          y="100px"
          width="490px"
          height="350px"
          href={
            'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/deadspace/overlay1.png'
          }
          result="img"
        />

        <g id="widget-borders">
          <path
            id="widget-borderline"
            className={css.widgetBorderLine}
            d="m337 155.6h418.1v203.5l-35.9 35.8h-382.2z"
          />
          <path
            className={css.widgetBorderLine}
            d="m532.5 374.9h192.6l-10 10h-192.6z"
          />
          <path className={css.widgetBorderLine} d="m522.5 384.9l-10 10" />
          <path className={css.widgetBorderLine} d="m725.1 374.9l10-10" />
          <path
            className={css.widgetBorderLine}
            d="m347 354.9v-189.3h58.1l40 40h280v-40h20v189.3l-10 10h-378.1z"
          />
          <path className={css.widgetBorderLine} d="m665.1 225.6h60v60h-60z" />
          <g id="&lt;Group&gt;">
            <path className={css.widgetBorderLine} d="m453 344.9h-86" />
            <path className={css.widgetBorderLine} d="m549.1 344.9h-86.1" />
            <path className={css.widgetBorderLine} d="m645.1 344.9h-86" />
          </g>
        </g>

        <g id="text-layer" className={css.text}>
          <text
            id="heart rate monitor "
            style={{transform: 'matrix(1,0,0,1,359.04,383.949)'}}
          >
            <tspan
              x="0"
              y="0"
              className={`${css.footerText} ${
                glitch ? css.text__glitched : ''
              }`}
              dx="0 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1"
            >
              heart rate monitor
            </tspan>
          </text>
          <text id="HR" style={{transform: 'matrix(1,0,0,1,367.04,205.646)'}}>
            <tspan
              x={glitch ? '2' : '0'}
              y="0"
              className={`${css.headerText} ${
                glitch ? css.text__glitched : ''
              }`}
              dx="0 -2 -2"
            >
              HR
            </tspan>
          </text>
          <text id="bpm" style={{transform: 'matrix(1,0,0,1,704,344.887)'}}>
            <tspan
              x={glitch ? '-36' : '-41.7'}
              y="0"
              className={`${css.bpmText} ${glitch ? css.text__glitched : ''}`}
              dx="0 -2 -2 -2"
            >
              bpm
            </tspan>
          </text>
        </g>

        <g id="bpm">
          <text style={{transform: 'matrix(1,0,0,1,410.045,334.824)'}}>
            <tspan
              x="-45.2"
              y="0"
              className={css.bpmNumber}
              fill={glitch ? 'url(#dotted-text)' : '#ffffff'}
            >
              {pulseH > 0 ? pulseH : pulseT}
            </tspan>
          </text>
          <text style={{transform: 'matrix(1,0,0,1,506.054,334.824)'}}>
            <tspan
              x="-45.2"
              y="0"
              className={css.bpmNumber}
              fill={glitch ? 'url(#dotted-text)' : '#ffffff'}
            >
              {pulseH > 0 ? pulseT : pulseU}
            </tspan>
          </text>
          <text style={{transform: 'matrix(1,0,0,1,602.062,334.824)'}}>
            <tspan
              x="-45.2"
              y="0"
              className={css.bpmNumber}
              fill={glitch ? 'url(#dotted-text)' : '#ffffff'}
            >
              {pulseH > 0 ? pulseU : ''}
            </tspan>
          </text>
        </g>

        {glitch ? (
          <g id="bpm-glitch">
            <text style={{transform: 'matrix(1,0,0,1,427.045,334.824)'}}>
              <tspan
                x="-45.2"
                y="0"
                className={css.bpmNumber__glitched}
                fill="#ffffff"
              >
                {pulseH > 0 ? pulseH : pulseT}
              </tspan>
            </text>
            <text style={{transform: 'matrix(1,0,0,1,518.054,334.824)'}}>
              <tspan
                x="-45.2"
                y="0"
                className={css.bpmNumber__glitched}
                fill="#ffffff"
              >
                {pulseH > 0 ? pulseT : pulseU}
              </tspan>
            </text>
            <text style={{transform: 'matrix(1,0,0,1,614.062,334.824)'}}>
              <tspan
                x="-45.2"
                y="0"
                className={css.bpmNumber__glitched}
                fill="#ffffff"
              >
                {pulseH > 0 ? pulseU : ''}
              </tspan>
            </text>
          </g>
        ) : null}
      </g>
    </svg>
  );
};
