import React from 'react';
import './minecraft-widget.css';

export class MinecraftWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      max: 0,
      samples: [],
      calories: 0,
    };
  }

  sampling = () => {
    let max = this.state.max;
    if (max > 50) {
      let samples = this.state.samples;
      samples.push(max);
      this.setState({samples, max: -1000, calories: this.calories(samples)});
    }
  };

  componentDidMount() {
    let intervalId = setInterval(this.sampling, 10000);
    this.setState({intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  calories = (samples) => {
    let total = 0;
    for (let i = 0; i < samples.length; i++) {
      try {
        let sample = samples[i];
        let calories = this.caloriesForSample(
          sample,
          10,
          this.props.configuration.weight,
          this.props.configuration.age
        );
        if (Number.isInteger(calories)) {
          total += calories;
        }
      } catch (e) {}
    }
    return total;
  };

  caloriesForSample = (heartRate, timeInSeconds, weight, age) => {
    return Math.floor(
      ((-55.0969 + 0.6309 * heartRate + 0.1988 * weight + 0.2017 * age) /
        4.184) *
        60 *
        (0.000277778 * timeInSeconds)
    );
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({max: Math.max(this.state.max, nextProps.pulse)});
  }

  calculateBackground = (pulse) => {
    let suffix = '';
    if (this.props.configuration.animate) {
      if (pulse >= 100) {
        suffix = 'fast.png';
      } else {
        suffix = 'slow.png';
      }
    } else {
      suffix = 'static.png';
    }

    let prefix = '';
    if (this.props.configuration.showCalories) {
      prefix = 'm2-';
    } else {
      prefix = 'm1-';
    }

    return `https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/minecraft/${prefix}${suffix}`;
  };

  render() {
    let height = 308;
    let width = 460;
    let color = 'white';

    let caloriesStyle = {
      fontSize: 80,
      top: 86,
    };

    let calories = this.state.calories;
    if (calories.toString(10).length === 4) {
      caloriesStyle = {
        fontSize: 60,
        top: 105,
      };
    }

    return (
      <div
        style={{
          backgroundImage: `url(${this.calculateBackground(this.props.pulse)})`,
          width: width,
          height: height,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <div
          style={{
            fontSize: 80,
            color,
            position: 'relative',
            top: 185,
            left: -226,
            zIndex: 5,
            textAlign: 'right',
            fontFamily: 'Minecraft',
            textShadow: '4px 6px 4px black',
          }}
        >
          {this.props.pulse}
        </div>

        {this.props.configuration.showCalories && (
          <div
            style={{
              color,
              position: 'relative',
              left: -32,
              zIndex: 10,
              textAlign: 'right',
              fontFamily: 'Minecraft',
              textShadow: '4px 6px 4px black',
              ...caloriesStyle,
            }}
          >
            {calories}
          </div>
        )}
      </div>
    );
  }
}
