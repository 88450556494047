import classNames from 'classnames';
import React from 'react';
import css from './SpeedTestArrows.module.scss';
import {useRandomId} from '../../../hooks/useRandomId';
import {IS_SAFARI} from '../../../utils/browserUtils';

export type ArrorProps = {
  pulse?: number;
  max?: number;
  angle?: number;
  color?: string;

  wrapInSvg?: boolean;
};

const SvgWrapper = ({
  children,
  shouldWrap = false,
}: {
  children: React.ReactNode;
  shouldWrap: boolean;
}) =>
  shouldWrap ? (
    <svg
      width="100%"
      height="100%"
      viewBox="80 60 100 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  ) : (
    <>{children}</>
  );

export const HeartArrow = ({
  pulse,
  max,
  angle,
  wrapInSvg = false,
}: ArrorProps) => {
  return (
    <SvgWrapper shouldWrap={wrapInSvg}>
      <g
        className={classNames(css.arrowLine, {
          [css.arrowLineMax]: pulse && max && pulse >= max,
        })}
        style={{
          transform: `rotate(${angle}deg)`,
        }}
      >
        <path
          style={{fill: 'none', stroke: '#5ADBFF', strokeWidth: '3px'}}
          d="M 57.767 180.084 L 179.918 176.066 C 185.26 176.37 185.141 184.912 180.049 185.121 L 57.614 180.634 L 57.767 180.084 Z"
        />
        <path
          d="M 65.736 174.145 C 65.893 177.241 64.476 179.88 62.762 182.319 C 60.03 186.211 56.505 189.296 52.676 192.066 C 51.951 192.595 51.196 193.085 50.46 193.604 C 50.251 193.75 50.089 193.838 49.828 193.651 C 45.003 190.202 40.491 186.413 37.167 181.421 C 35.946 179.585 34.93 177.642 34.605 175.434 C 33.911 170.737 36.333 167.052 40.889 166.071 C 44.553 165.3 48.027 167.192 49.633 170.625 C 49.737 170.844 49.796 171.105 50.089 171.129 C 50.381 171.148 50.489 170.844 50.597 170.612 C 53.07 165.054 60.633 164.257 64.209 169.178 C 64.937 170.175 65.419 171.33 65.618 172.548 C 65.702 173.075 65.743 173.611 65.736 174.145 Z"
          fill="#CBFF4D"
          stroke="#EBF2FA"
          transform="matrix(-0.006588, 0.999978, -0.999978, -0.006588, 230.272723, 130.896657)"
        />
      </g>
    </SvgWrapper>
  );
};

export const SpeedTestArrow = ({
  pulse,
  max,
  angle,
  wrapInSvg,
  color = '#fff',
}: ArrorProps) => {
  const gradientId = useRandomId('gradient');
  return (
    <SvgWrapper shouldWrap={wrapInSvg}>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="50%" stopColor={color} />

          <stop offset="90%" stopColor="rgba(255,255,255,0)" />
        </linearGradient>
      </defs>
      <polygon
        className={classNames(css.arrowLine, {
          [css.arrowLineMax]: pulse >= max,
        })}
        fill={`url(#${gradientId}`}
        points="180,168 180,192  75,188  75,172"
        style={{
          fillOpacity: 0.9,
          transform: `rotate(${angle}deg)`,
        }}
      />
    </SvgWrapper>
  );
};

export const NeedleArrow = ({
  pulse,
  max,
  angle,
  color,
  wrapInSvg,
}: ArrorProps) => {
  const filterId = useRandomId('needle-glow');

  return (
    <SvgWrapper shouldWrap={wrapInSvg}>
      <defs>
        <filter id={filterId}>
          <feDropShadow dx="1" dy="0" stdDeviation="2" floodColor="red" />
          <feDropShadow dx="-1" dy="0" stdDeviation="2" floodColor="red" />
        </filter>
      </defs>
      <g
        className={classNames(css.arrowLine, {
          [css.arrowLineMax]: pulse >= max,
        })}
        style={{
          transform: `rotate(${angle}deg)`,
        }}
      >
        <path
          className={css.arrowGlow}
          style={IS_SAFARI ? {filter: `url(#${filterId})`} : null}
          fill={color}
          d="M 51.218 180.248 L 183.027 179.316 C 188.369 179.62 188.411 182.869 183.319 183.078 L 51.218 180.248 Z"
        />
        <ellipse
          className={css.arrowGlow}
          style={IS_SAFARI ? {filter: `url(#${filterId})`} : null}
          fill={color}
          cx="178.075"
          cy="180.962"
          rx="10"
          ry="10"
        />
      </g>
    </SvgWrapper>
  );
};

export const AlfaArrow = ({
  pulse,
  max,
  angle,
  wrapInSvg,
  color = '#000000',
}: ArrorProps) => {
  const arrowGradientId = useRandomId('arrow-gradient');
  const metalGradientId = useRandomId('metal-gradient');

  return (
    <SvgWrapper shouldWrap={wrapInSvg}>
      <defs>
        <linearGradient id={arrowGradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0.4" stop-color="#A30000"></stop>
          <stop offset="0.5" stop-color="#750000"></stop>
          <stop offset="0.52" stop-color="#D10000"></stop>
        </linearGradient>
        <linearGradient id={metalGradientId} x1="10%" y1="100%">
          <stop offset="0.1" stop-color="white"></stop>
          <stop offset="0.95" stop-color="#757374"></stop>
        </linearGradient>
      </defs>
      <g
        className={classNames(css.arrowLine, {
          [css.arrowLineMax]: pulse >= max,
        })}
        style={{
          transform: `rotate(${angle}deg)`,
        }}
      >
        <path
          fill={`url(#${arrowGradientId})`}
          stroke={color}
          d="M 51.218 180.248 L 183.107 175.512 C 183.048 175.398 189.865 180.536 189.878 180.627 C 189.94 180.648 183.126 185.538 183.069 185.542 L 51.218 180.248 Z"
        />
        <ellipse
          fill={`url(#${metalGradientId})`}
          stroke={color}
          cx="177.287"
          cy="180.391"
          rx="8"
          ry="8"
        />
      </g>
    </SvgWrapper>
  );
};
