import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import css from './FontPicker.module.scss';
import {FONT_PICKER_DEFAULT_FONTS} from './FontPicker.constants';
import {loadCustumFont} from '../../utils/fontUtils';

export type FontPickerProps = {
  selectedFont?: FontOption;
  onFontChange: (font: FontOption) => void;
  className?: string;
  placeholder?: string;
  onFontError?: (error: Error) => void;
  fonts?: {name: string; url: string}[];

  withUpload?: boolean;
  onFontUploadClick?: () => void;
};

export type FontOption = {
  name: string;
  url: string;
};

export const FontPicker = (props: FontPickerProps) => {
  const {placeholder = 'Select font', fonts = FONT_PICKER_DEFAULT_FONTS} =
    props;

  const [selectedFont, setSelectedFont] = useState(props.selectedFont);
  const [isOpen, setIsOpen] = useState(false);
  const [isListExpanded, setIsListExpanded] = useState(false);
  const rootEl = useRef<HTMLDivElement>();
  const listEl = useRef<HTMLUListElement>();

  const closePicker = useCallback(() => {
    setIsListExpanded(false);

    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  }, []);

  const closeOnClickOutside = (event: MouseEvent) => {
    if (!rootEl.current?.contains(event.target as Node)) {
      closePicker();
    }
  };

  const uploadFonts = async () => {
    if (selectedFont) {
      // Upload selected font first
      await loadCustumFont(
        {name: selectedFont.name, url: selectedFont.url},
        {onError: props.onFontError}
      );

      // Upload other fonts
      await Promise.all(
        fonts.map(async (font) =>
          loadCustumFont(font, {onError: props.onFontError})
        )
      );
    }
  };

  useEffect(() => {
    setSelectedFont(props.selectedFont);
  }, [props.selectedFont]);

  useEffect(() => {
    document.addEventListener('click', closeOnClickOutside);

    () => {
      document.removeEventListener('click', closeOnClickOutside);
    };
  }, []);

  useEffect(() => {
    uploadFonts();
  }, [fonts]);

  const onPickerClick = () => {
    if (isOpen) {
      closePicker();
    } else {
      setIsOpen(true);
      setTimeout(() => {
        setIsListExpanded(true);
      }, 0);
    }
  };

  const selectFont = useCallback(
    (font: FontOption) => {
      setSelectedFont(font);
      props.onFontChange(font);
      closePicker();
    },
    [props.onFontChange]
  );

  return (
    <div className={classNames(props.className, css.root)} ref={rootEl}>
      <button
        className={classNames(css.button, {
          [css.open]: isOpen,
          [css.fontSelected]: selectedFont,
        })}
        style={{fontFamily: `"${selectedFont?.name}"`}}
        onClick={onPickerClick}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            closePicker();
          } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
            (listEl?.current?.firstChild as HTMLElement)?.focus();
          }
        }}
      >
        <span className={css.fontNameText}>
          {selectedFont?.name || placeholder}
        </span>

        <span className={css.buttonArrow} />
      </button>

      {isOpen && (
        <ul
          className={classNames(css.fontList, {[css.expanded]: isListExpanded})}
          ref={listEl}
        >
          {props.withUpload && (
            <li
              className={classNames(css.fontItem, css.uploadFont, {
                [css.selected]: !selectedFont,
              })}
              onClick={() => {
                closePicker();
                props.onFontUploadClick();
              }}
            >
              <span className={css.fontNameText}>Add font</span>
            </li>
          )}

          {fonts.map((font) => (
            <li
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  selectFont(font);
                } else if (e.key === 'Escape') {
                  closePicker();
                } else if (e.key === 'ArrowDown') {
                  e.preventDefault();
                  (e.target.nextSibling as HTMLElement)?.focus();
                } else if (e.key === 'ArrowUp') {
                  e.preventDefault();
                  (e.target.previousSibling as HTMLElement)?.focus();
                }
              }}
              tabIndex={0}
              key={font.name}
              title={font.name}
              className={classNames(css.fontItem, {
                [css.selected]: font.name === selectedFont?.name,
              })}
              style={{fontFamily: `"${font.name}"`}}
              onClick={() => selectFont(font)}
            >
              <span className={css.fontNameText}>
                {font?.name || placeholder}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
