import React from 'react';

export class BpmPercentageWidget extends React.Component {
  render() {
    let percentage = 0;

    if (this.props.pulse > this.props.configuration.max) {
      if (this.props.configuration.allowOver100) {
        percentage = Math.floor(
          (this.props.pulse * 100) / this.props.configuration.max
        );
      } else {
        percentage = 100;
      }
    } else {
      percentage = Math.floor(
        (this.props.pulse * 100) / this.props.configuration.max
      );
    }

    return (
      <>
        {this.props.configuration && (
          <div
            style={{
              fontSize: '20vh',
              fontFamily: this.props.configuration.font,
              color: this.props.configuration.fontColor,
              textAlign: this.props.configuration.alignment
                ? this.props.configuration.alignment
                : 'left',
            }}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
              rel={'stylesheet'}
            />
            {percentage}%
          </div>
        )}
      </>
    );
  }
}
