import React from 'react';
import {OneOf} from '../../types/internal';
import {
  BasePicker,
  BasePickerDefaultItemConfiguration,
} from '../BasePicker/BasePicker';
import {HeartSimpleIcon} from '../ImagePicker/images/HeartIcon/heartSimpleIcon';
import {
  layoutTypeToFlexDirectionMap,
  LAYOUT_TYPES,
} from './LayoutPicker.constants';

export type LayoutPickerProps = {
  selectedItem?: typeof LAYOUT_TYPES[keyof typeof LAYOUT_TYPES] | null;
  onSelect?: (layout: string) => void;

  itemConfiguration?: BasePickerDefaultItemConfiguration;
  className?: string;
  itemClassName?: string;
};

export const LayoutPicker = (props: LayoutPickerProps) => {
  return (
    <BasePicker
      selectedItem={props.selectedItem}
      onSelect={props.onSelect}
      className={props.className}
      itemClassName={props.itemClassName}
      itemConfiguration={props.itemConfiguration}
      items={Object.values(LAYOUT_TYPES)}
      withNone={false}
      renderItem={(item: OneOf<typeof LAYOUT_TYPES>) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: layoutTypeToFlexDirectionMap[item],
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <HeartSimpleIcon size={15} />
            <div>123</div>
          </div>
        );
      }}
    />
  );
};
