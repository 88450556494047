import React from 'react';
import {WidgetProps} from '../common';
import css from './AloneInTheDark.module.scss';

export type AloneInTheDarkProps = WidgetProps<{}>;

export const AloneInTheDarkWidget = (props: AloneInTheDarkProps) => {
  const shouldShowEmptyState = props.emptyStateComponent && !props.pulse;
  const animationDuration =
    Math.max(Math.floor((60 / props.pulse) * 1000), 300) * 2;

  return (
    <svg width="100%" height="100%" viewBox="0 0 248 248">
      <path
        opacity="0.16"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125 122.171V34H123V122.001L61 60L59.5859 61.4141L121.172 123H34V125H122.172L60.3047 186.865L61.7227 188.279L123 127.001V214H125V126.829L186.863 188.693L188.277 187.279L126 125H214V123H127L189 61L187.586 59.5859L125 122.171Z"
        fill="#D5CDB9"
      />
      <g opacity="0.2">
        <circle cx="124" cy="124" r="124" fill="black" />
        <circle
          cx="124"
          cy="124"
          r="123"
          stroke="#D5CDB9"
          stroke-opacity="0.7"
          stroke-width="2"
        />
      </g>

      <svg
        x={180}
        opacity={0.3}
        width="66"
        height="61"
        viewBox="0 0 66 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur={`${animationDuration}ms`}
          repeatCount="indefinite"
        />
        <g filter="url(#filter0_di_1627_210)">
          <path
            d="M33 55.975L29.925 53.2C24.625 48.35 20.25 44.1625 16.8 40.6375C13.35 37.1125 10.6 33.9625 8.55 31.1875C6.5 28.4125 5.0625 25.9 4.2375 23.65C3.4125 21.4 3 19.125 3 16.825C3 12.325 4.5125 8.5625 7.5375 5.5375C10.5625 2.5125 14.3 1 18.75 1C21.6 1 24.2375 1.675 26.6625 3.025C29.0875 4.375 31.2 6.325 33 8.875C35.1 6.175 37.325 4.1875 39.675 2.9125C42.025 1.6375 44.55 1 47.25 1C51.7 1 55.4375 2.5125 58.4625 5.5375C61.4875 8.5625 63 12.325 63 16.825C63 19.125 62.5875 21.4 61.7625 23.65C60.9375 25.9 59.5 28.4125 57.45 31.1875C55.4 33.9625 52.65 37.1125 49.2 40.6375C45.75 44.1625 41.375 48.35 36.075 53.2L33 55.975Z"
            fill="url(#paint0_linear_1627_210)"
          />
        </g>
        <defs>
          <filter
            id="filter0_di_1627_210"
            x="0"
            y="0"
            width="66"
            height="60.9746"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1627_210"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1627_210"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.955524 0 0 0 0 0.187762 0 0 0 0 0.221776 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_1627_210"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1627_210"
            x1="3"
            y1="29.0851"
            x2="63"
            y2="29.0851"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.455" stop-color="#CA1E27" />
            <stop offset="0.505" stop-color="#A70F16" />
          </linearGradient>
        </defs>
      </svg>

      <foreignObject
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div
          style={{
            width: 248,
            height: 248,
            lineHeight: 'initial',
          }}
        >
          <div className={css.pulse}>
            {shouldShowEmptyState ? props.emptyStateComponent : props.pulse}
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};
