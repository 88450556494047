import React from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import '../App.css';
import hexToRgba from 'hex-to-rgba';

export class FitnessZoneWidget extends React.Component {
  isInRange = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return true;
      }
    }

    return false;
  };

  calculateBars = (ranges) => {
    let colors = [];
    let series = [];

    let min = Math.min.apply(
      null,
      ranges.map((range) => range.from)
    );

    let scale = 1;
    let prevRange;

    for (let i = 0; i < ranges.length; i++) {
      let range = ranges[i];
      if (prevRange && range.from > prevRange.to) {
        let difference = range.from - prevRange.to;
        if (difference === 1) {
          colors.push(range.color);
          series.push(Math.floor((range.to - range.from + 1) * scale));
          prevRange = range;
          continue;
        } else {
          colors.push('#ffffff00');
          series.push(Math.floor(scale * difference));
        }
      }
      colors.push(range.color);
      series.push(Math.floor((range.to - range.from) * scale));
      prevRange = range;
    }

    return {
      colors,
      series,
      scale,
      min,
    };
  };

  constructor(props) {
    super(props);

    let {colors, series, scale, min} = this.calculateBars(
      props.configuration.ranges
    );

    this.state = {
      options: {
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          parentHeightOffset: 0,
          id: 'vertical',
          type: 'bar',
          stacked: true,
          // stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories: [''],
        },
        fill: {
          colors: colors.map((color) => hexToRgba(color)),
          opacity: 1,
        },
        legend: {
          show: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          show: false,
        },
      },
      series: series.map((data) => {
        return {name: 'test', data: [data]};
      }),
      scale: scale,
      min: min,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    ApexCharts.exec('vertical', 'clearAnnotations');
    if (this.isInRange(nextProps.configuration.ranges, nextProps.pulse)) {
      let level = (nextProps.pulse - this.state.min) * this.state.scale;
      ApexCharts.exec('vertical', 'addYaxisAnnotation', {
        y: level,
        label: {
          style: {
            background: 'black',
            cssClass: 'apexcharts-yaxis-annotation-label',
            padding: {},
          },
        },
        borderColor: 'black',
        strokeDashArray: 0,
        borderWidth: 3,
      });
    } else {
    }
  }

  render() {
    return (
      <>
        {this.props.configuration && (
          <div style={{width: 300, height: 600}}>
            <Chart
              ref={this.chart}
              options={this.state.options}
              series={this.state.series}
              height={'100%'}
              type="bar"
            />
          </div>
        )}
      </>
    );
  }
}
