import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartRibbonIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
        d="M 19.033 31.889 L 41.456 55.44 L 63.835 31.944 L 48.406 15.748 C 48.872 15.748 49.113 15.657 49.355 15.657 C 54.797 15.657 60.247 15.657 65.689 15.657 C 66.156 15.669 66.604 15.852 66.957 16.173 C 71.907 21.325 76.84 26.496 81.755 31.69 C 81.842 31.78 81.919 31.889 81.945 31.917 L 41.464 74.444 L 0.862 31.798 C 1.131 31.619 1.388 31.419 1.63 31.201 C 6.329 26.264 11.038 21.346 15.713 16.382 C 16.171 15.863 16.824 15.584 17.498 15.621 C 22.784 15.621 28.071 15.621 33.349 15.621 L 34.452 15.621 L 19.033 31.889 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
        d="M 47.871 16.173 L 56.84 25.557 L 41.533 41.626 L 26.234 25.567 L 35.116 16.255 L 41.464 22.939 C 43.646 20.621 45.784 18.374 47.871 16.173 Z"
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
