import React from 'react';

export type HeartIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  withAnimation?: boolean;
  stroke?: string;
};

export const HeartIconAnimateTransform = () => (
  <animateTransform
    attributeName="transform"
    type="scale"
    values="0.8; 1.1; 1; 1.1; 1.1; 0.8;"
    dur="500ms"
    repeatCount="indefinite"
    additive="sum"
  />
);
