import React from 'react';

export class HalloweenMoonWidget extends React.Component {
  findRange = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return range;
      }
    }
    if (ranges.length !== 0) {
      return ranges[ranges.length - 1];
    } else {
      return null;
    }
  };

  render() {
    let range = this.findRange(
      this.props.configuration.ranges,
      this.props.pulse
    );
    let url = range
      ? range.image
      : 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/halloween/graves/green-anim-small.png';

    return (
      <div className={'fill-window'}>
        <div
          style={{
            backgroundImage: `url(${url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: 500,
            height: 387,
          }}
        >
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Flavors&display=swap"
            rel="stylesheet"
          />
          <div
            style={{
              fontSize: 100,
              color: 'black',
              position: 'relative',
              top: 150,
              left: 25,
              zIndex: 5,
              textAlign: 'center',
              fontFamily: "'Flavors', cursive",
            }}
          >
            {this.props.pulse}
          </div>
          <div
            style={{
              color: 'black',
              position: 'relative',
              fontSize: 40,
              top: 140,
              zIndex: 6,
              left: 27,
              textAlign: 'center',
              fontFamily: "'Flavors', cursive",
            }}
          >
            BPM
          </div>
        </div>
      </div>
    );
  }
}
