export class PulseSpeedStrategy {
  constructor() {
    this.stratagies = [];
  }

  add(from, to, time) {
    this.stratagies.push({from: from, to: to, time: time});
  }

  get(bpm) {
    for (var i = 0; i < this.stratagies.length; i++) {
      var s = this.stratagies[i];
      if (bpm >= s.from && bpm <= s.to) {
        return s.time;
      }
    }
    return null;
  }
}
