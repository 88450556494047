// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Inter\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/silent-hill-widget/inter_variable_font_silent_hill.ttf\") format(\"opentype\")}.NyhY1tBsGYIG_ixtl6an{font-family:\"Inter\";font-size:108px;font-feature-settings:\"tnum\"}.kMWBWcIMzzw2TPpMFotu{filter:brightness(0)}.XTvf5gLSqcVWXWTg0q6I{animation:vaYMHpFcox2KhAxEaaiw 1.25s ease-in-out infinite}@keyframes vaYMHpFcox2KhAxEaaiw{0%{opacity:1}50%{opacity:.1}100%{opacity:1}}", "",{"version":3,"sources":["webpack://./src/widgets/SilentHill2Widget/SilentHill2Widget.module.scss"],"names":[],"mappings":"AAAA,WACE,mBAAA,CACA,yIAAA,CAIF,sBACE,mBAAA,CACA,eAAA,CACA,4BAAA,CAGF,sBACE,oBAAA,CAGF,sBACE,yDAAA,CAGF,gCACE,GACE,SAAA,CAEF,IACE,UAAA,CAEF,KACE,SAAA,CAAA","sourcesContent":["@font-face {\n  font-family: 'Inter';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/silent-hill-widget/inter_variable_font_silent_hill.ttf')\n    format('opentype');\n}\n\n.pulse {\n  font-family: 'Inter';\n  font-size: 108px;\n  font-feature-settings: 'tnum';\n}\n\n.cloud {\n  filter: brightness(0);\n}\n\n.fadePulseAnimation {\n  animation: fadePulse 1.25s ease-in-out infinite;\n}\n\n@keyframes fadePulse {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.1;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "NyhY1tBsGYIG_ixtl6an",
	"cloud": "kMWBWcIMzzw2TPpMFotu",
	"fadePulseAnimation": "XTvf5gLSqcVWXWTg0q6I",
	"fadePulse": "vaYMHpFcox2KhAxEaaiw"
};
export default ___CSS_LOADER_EXPORT___;
