export enum JumpScareBackgroundType {
  SIMPLE = 'simple',
  CRACKED = 'cracked',
  ON_GRASS = 'onGrass',
  RECTANGLE = 'rectangle',
}

export enum JumpScareSecondaryImageType {
  HAND = 'hand',
  SKELETON = 'skeleton',
  GHOST = 'ghost',
}

export const BACKGROUND_TYPE_TO_URL = {
  [JumpScareBackgroundType.SIMPLE]:
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/background-svg/bg-3.svg',
  [JumpScareBackgroundType.CRACKED]:
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/background-svg/bg-2.svg',
  [JumpScareBackgroundType.ON_GRASS]:
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/background-svg/bg-4.svg',
  [JumpScareBackgroundType.RECTANGLE]:
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/background-svg/bg-1.svg',
};

export const SECONDARY_IMAGE_TYPE_TO_URL = {
  [JumpScareSecondaryImageType.HAND]: {
    default:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/hand-dafault.png',
    active:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/hand-scared.png',
    picker:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/hand.png',
  },

  [JumpScareSecondaryImageType.SKELETON]: {
    default:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/skeleton-default.png',
    active:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/skeleton-scared.png',
    picker:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/skeleton.png',
  },

  [JumpScareSecondaryImageType.GHOST]: {
    default:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/ghost-default.png',
    active:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/ghost-scared.png',
    picker:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/jumpscare/ghost.png',
  },
};

export const MIN_TIME_BETWEEN_TRIGGER = 60_000;
export const SECONDARY_IMAGE_ACTIVE_TIME = 2_000;
