import React from 'react';
import {WidgetProps} from '../common';
import css from './UnicornWidget.module.scss';
import {findRangeForPulse} from '../../utils/rangeUtils';

type UnicornWidgetProps = WidgetProps<{image: string}>;

export const UnicornWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: UnicornWidgetProps) => {
  const shouldShowEmptyState = emptyStateComponent && !pulse;
  const {image} = findRangeForPulse(configuration?.ranges, pulse);

  return (
    <svg width="100%" height="100%" viewBox="0 0 500 500">
      <foreignObject
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: 500,
            height: 500,
            lineHeight: 'initial',
          }}
        >
          <div className={css.pulse}>
            {shouldShowEmptyState ? emptyStateComponent : pulse}
          </div>

          <div className={css.bpm}>BPM</div>
        </div>
      </foreignObject>
    </svg>
  );
};
