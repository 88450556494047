import React from 'react';
import {HeartIconProps} from './heartIcon.constants';

export const HeartPulsoidIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
        fillRule="evenodd"
        d="M 79.927 24.622 L 79.927 42.86 L 41.362 81.424 L 41.316 81.377 L 41.268 81.424 L 2.704 42.86 L 2.704 24.622 L 19.474 7.852 L 21.638 5.666 L 23.428 3.898 L 23.521 3.99 L 24.918 5.387 L 26.267 6.736 L 41.316 21.786 L 56.365 6.736 L 59.109 3.99 L 59.203 3.898 L 60.994 5.666 L 63.157 7.852 L 79.927 24.622 Z M 74.344 26.925 L 59.203 11.806 L 45.27 25.739 L 45.316 25.785 L 41.362 29.74 L 41.316 29.694 L 41.268 29.74 L 37.338 25.785 L 37.362 25.739 L 23.428 11.806 L 8.286 26.925 L 8.286 40.556 L 41.316 73.563 L 74.344 40.556 L 74.344 26.925 Z"
      ></path>

      {withAnimation && (
        <animateTransform
          attributeName="transform"
          type="scale"
          values="0.8; 1.1; 1; 1.1; 1.1; 0.8;"
          dur="500ms"
          repeatCount="indefinite"
          additive="sum"
        />
      )}
    </svg>
  );
};
