import React from 'react';

export class CaloriesWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      max: 0,
      samples: [],
    };
  }

  sampling = () => {
    let max = this.state.max;
    if (max > 50) {
      let samples = this.state.samples;
      samples.push(max);
      this.setState({samples, max: -1000});
    }
  };

  componentDidMount() {
    let intervalId = setInterval(this.sampling, 10000);
    this.setState({intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  calories = () => {
    let total = 0;
    for (let i = 0; i < this.state.samples.length; i++) {
      try {
        let sample = this.state.samples[i];
        let calories = this.caloriesForSample(
          sample,
          10,
          this.props.configuration.weight,
          this.props.configuration.age
        );
        if (Number.isInteger(calories)) {
          total += calories;
        }
      } catch (e) {}
    }
    return total;
  };

  caloriesForSample = (heartRate, timeInSeconds, weight, age) => {
    return Math.floor(
      ((-55.0969 + 0.6309 * heartRate + 0.1988 * weight + 0.2017 * age) /
        4.184) *
        60 *
        (0.000277778 * timeInSeconds)
    );
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({max: Math.max(this.state.max, nextProps.pulse)});
  }

  render() {
    return (
      <>
        {this.props.configuration && (
          <div
            style={{
              backgroundColor: this.props.fromLaunch
                ? this.props.configuration.backgroundColor
                : 'transparent',
              fontSize: '60vh',
              fontFamily: this.props.configuration.font,
              color: this.props.configuration.fontColor,
              textAlign: this.props.configuration.alignment
                ? this.props.configuration.alignment
                : 'left',
            }}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
              rel={'stylesheet'}
            />
            {Math.abs(Math.floor(this.calories()))}
          </div>
        )}
      </>
    );
  }
}
