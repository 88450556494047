import React from 'react';
import {findRangeForPulse} from '../utils/rangeUtils';
import {WidgetProps} from './common';

export type HalloweenMoonWidgetProps = WidgetProps<{image?: string}>;

export const HalloweenMoonWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: HalloweenMoonWidgetProps) => {
  const ranges = configuration?.ranges || [];

  const shouldShowEmptyState = emptyStateComponent && !pulse;

  let range = findRangeForPulse(ranges, pulse);
  let url = range
    ? range.image
    : 'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/halloween/graves/green-anim-small.png';

  return (
    <svg width="100%" height="100%" viewBox="0 0 500 387">
      <foreignObject
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div
          style={{
            backgroundImage: `url(${url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: 500,
            height: 387,
          }}
        >
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Flavors&display=swap"
            rel="stylesheet"
          />
          <div
            style={{
              fontSize: 100,
              color: 'black',
              position: 'relative',
              top: 150,
              left: 25,
              zIndex: 5,
              textAlign: 'center',
              fontFamily: "'Flavors', cursive",
            }}
          >
            {shouldShowEmptyState ? emptyStateComponent : pulse}
          </div>
          <div
            style={{
              color: 'black',
              position: 'relative',
              fontSize: 40,
              top: 140,
              zIndex: 6,
              left: 27,
              textAlign: 'center',
              fontFamily: "'Flavors', cursive",
            }}
          >
            BPM
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};
