// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Montserrat\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/alone-in-the-dark/Montserrat-Light.ttf\")}.a5NmA2Ea75Ncm2sfLTnY{position:relative;top:60px;font-family:Montserrat;font-size:100px;font-weight:400;line-height:121.9px;letter-spacing:.01em;text-align:center;color:#d5cdb9}", "",{"version":3,"sources":["webpack://./src/widgets/AloneInTheDarkWidget/AloneInTheDark.module.scss"],"names":[],"mappings":"AAAA,WACE,wBAAA,CACA,oHAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,sBAAA,CACA,eAAA,CACA,eAAA,CACA,mBAAA,CACA,oBAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":["@font-face {\n  font-family: 'Montserrat';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/alone-in-the-dark/Montserrat-Light.ttf');\n}\n\n.pulse {\n  position: relative;\n  top: 60px;\n  font-family: Montserrat;\n  font-size: 100px;\n  font-weight: 400;\n  line-height: 121.9px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  color: #d5cdb9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "a5NmA2Ea75Ncm2sfLTnY"
};
export default ___CSS_LOADER_EXPORT___;
