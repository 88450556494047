import React from 'react';

export class JumpScareCounterWidget extends React.Component {
  state = {
    lastTriggerTime: 0,
    jumpScareCounter: 0,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    let pulse = nextProps.pulse;
    let currentTime = Date.now();
    if (
      currentTime - this.state.lastTriggerTime > 60000 &&
      pulse > this.props.configuration.triggerHeartRate
    ) {
      this.setState({
        lastTriggerTime: currentTime,
        jumpScareCounter: this.state.jumpScareCounter + 1,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.configuration && (
          <div
            style={{
              fontSize: '60vh',
              textAlign: this.props.alignment,
              fontFamily: this.props.configuration.font,
              color: this.props.configuration.color,
            }}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
              rel={'stylesheet'}
            />
            {this.state.jumpScareCounter}
          </div>
        )}
      </>
    );
  }
}
