import React from 'react';

import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import hexToRgba from 'hex-to-rgba';

export class RealTimeGraphWidgetV2 extends React.Component {
  Animation = 1000;
  id = (Math.random() + 1).toString(36).substring(7);

  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.intervals();
  }

  getNewSeries = (value) => {
    var date = new Date().getTime();

    let data = this.state.data;

    data.push({
      x: date,
      y: value | 0,
    });

    this.setState({data: data});
  };

  intervals() {
    this.intervalID = window.setInterval(() => {
      this.getNewSeries(this.props.pulse ? this.props.pulse : 60);

      ApexCharts.exec(this.id, 'updateSeries', [
        {
          data: this.state.data,
        },
      ]);
    }, this.Animation);
    this.truncateIntervalId = window.setInterval(() => {
      let thresholdDate =
        new Date().getTime() -
        Math.floor(this.props.configuration.duration * 1.5);
      let data = this.state.data;
      if (data.length >= 1 && data[0].x < thresholdDate) {
        let filteredDate =
          thresholdDate + Math.floor(this.props.configuration.duration * 0.5);
        let filtered = data.filter((d) => d.x > filteredDate);
        this.setState({data: filtered}, () => {
          ApexCharts.exec(
            this.id,
            'updateSeries',
            [
              {
                data: this.state.data,
              },
            ],
            false
          ).then(() => {
            ApexCharts.exec(this.id, 'updateSeries', [
              {
                data: this.state.data,
              },
            ]);
          });
        });
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearInterval(this.truncateIntervalId);
  }

  render() {
    let fill = {};
    if (this.props.configuration.fillingMode === 'one_color') {
      if (this.props.configuration.fillingStyle === 'solid') {
        fill = {
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              colorStops: [
                {
                  offset: 0,
                  color: this.props.configuration.fillingModeColor,
                  opacity: 1,
                },
              ],
            },
          },
        };
      } else if (
        this.props.configuration.fillingStyle === 'gradual_transparency'
      ) {
        fill = {
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              colorStops: [
                {
                  offset: 0,
                  color: this.props.configuration.fillingModeColor,
                  opacity: 1,
                },
                {
                  offset: 20,
                  color: this.props.configuration.fillingModeColor,
                },
                {
                  offset: 60,
                  color: this.props.configuration.fillingModeColor,
                },
                {
                  offset: 100,
                  color: this.props.configuration.fillingModeColor,
                  opacity: 0,
                },
              ],
            },
          },
        };
      }
    } else if (this.props.configuration.fillingMode === 'gradient') {
      if (this.props.configuration.fillingStyle === 'solid') {
        fill = {
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              colorStops: [
                {
                  offset: 0,
                  color: '#EB656F',
                  opacity: 1,
                },
                {
                  offset: 20,
                  color: '#FAD375',
                  opacity: 1,
                },
                {
                  offset: 60,
                  color: '#95DA74',
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: '#61DBC3',
                  opacity: 1,
                },
              ],
            },
          },
        };
      } else if (
        this.props.configuration.fillingStyle === 'gradual_transparency'
      ) {
        fill = {
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              colorStops: [
                {
                  offset: 0,
                  color: '#EB656F',
                  opacity: 1,
                },
                {
                  offset: 20,
                  color: '#FAD375',
                },
                {
                  offset: 60,
                  color: '#95DA74',
                },
                {
                  offset: 100,
                  color: '#61DBC3',
                  opacity: 0,
                },
              ],
            },
          },
        };
      }
    }

    return (
      <div>
        <link
          href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
          rel={'stylesheet'}
        />
        <div id="chart">
          <ReactApexChart
            options={{
              chart: {
                id: this.id,
                animations: {
                  enabled: true,
                  easing: 'linear',
                  dynamicAnimation: {
                    speed: this.Animation,
                  },
                },
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth',
                width:
                  7 /
                  (this.props.configuration.lineStrength === 'light' ? 2 : 1),
              },

              markers: {
                size: 0,
              },
              xaxis: {
                labels: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                type: 'datetime',
                range: this.props.configuration.duration,
              },
              yaxis: {
                tickAmount: 1,
                min: this.props.configuration.min
                  ? this.props.configuration.min
                  : 50,
                max: this.props.configuration.max
                  ? this.props.configuration.max
                  : undefined,
                labels: {
                  show: this.props.configuration.showLabel,
                  style: {
                    color: this.props.configuration.color,
                    fontSize: this.props.configuration.fontSize + 'px',
                    fontFamily: "'" + this.props.configuration.font + "'",
                    cssClass: 'apexcharts-yaxis-label',
                  },
                  formatter: (value) => {
                    return value | 0;
                  },
                },
              },
              legend: {
                show: false,
              },
              grid: {
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
                padding: {
                  top: 100,
                },
              },
              tooltip: {
                enabled: false,
              },
              ...fill,
              colors: [hexToRgba(this.props.configuration.lineColor)],
            }}
            series={[]}
            key={this.props.configuration.fillingMode}
            type={
              this.props.configuration.fillingMode === 'none' ? 'line' : 'area'
            }
            height={'500px'}
          />
        </div>
      </div>
    );
  }
}
