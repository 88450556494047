import React from 'react';
import {OneOf} from '../../types/internal';
import {
  BasePicker,
  BasePickerDefaultItemConfiguration,
} from '../BasePicker/BasePicker';
import {SPEED_TEST_ARROW_TYPES} from './SpeedTestArrowPicker.constants';
import {
  AlfaArrow,
  HeartArrow,
  NeedleArrow,
  SpeedTestArrow,
} from './SpeedTestArrows/SpeedTestArrows';

export type SpeedTestArrowPickerProps = {
  selectedItem?:
    | typeof SPEED_TEST_ARROW_TYPES[keyof typeof SPEED_TEST_ARROW_TYPES]
    | null;
  onSelect?: (layout: string) => void;

  itemConfiguration?: BasePickerDefaultItemConfiguration;
  className?: string;
  itemClassName?: string;
};

export const SpeedTestArrowPicker = (props: SpeedTestArrowPickerProps) => {
  return (
    <BasePicker
      selectedItem={props.selectedItem}
      onSelect={props.onSelect}
      className={props.className}
      itemClassName={props.itemClassName}
      itemConfiguration={props.itemConfiguration}
      items={Object.values(SPEED_TEST_ARROW_TYPES)}
      withNone={true}
      renderItem={(type: OneOf<typeof SPEED_TEST_ARROW_TYPES>) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            {type === SPEED_TEST_ARROW_TYPES.SPEEDTEST ? (
              <SpeedTestArrow color="#000" wrapInSvg />
            ) : null}
            {type === SPEED_TEST_ARROW_TYPES.HEART ? (
              <HeartArrow wrapInSvg />
            ) : null}
            {type === SPEED_TEST_ARROW_TYPES.NEEDLE ? (
              <NeedleArrow color="#000" wrapInSvg />
            ) : null}
            {type === SPEED_TEST_ARROW_TYPES.ALFA ? (
              <AlfaArrow wrapInSvg />
            ) : null}
          </div>
        );
      }}
    />
  );
};
