import React from 'react';
import './Widgets.css';
export class GeometryDashWidget extends React.Component {
  state = {
    animationDuration: 1000,
    deadlineTime: new Date().getTime() - 10,
  };

  findColor = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return range.color === undefined ? 'transparent' : range.color;
      }
    }
    if (ranges.length === 0) {
      return null;
    } else {
      return ranges[ranges.length - 1].color;
    }
  };

  static getDerivedStateFromProps(props, state) {
    let currentTime = new Date().getTime();
    let deadlineTime = state.deadlineTime;
    if (currentTime > deadlineTime) {
      let animationDuration = Math.max(
        Math.floor((60 / props.pulse) * 1000) - 100,
        300
      );
      return {
        animationDuration,
        deadlineTime: currentTime + animationDuration + 30,
      };
    }
    return null;
  }

  geometry_dash_id = 'a' + (Math.random() + 1).toString(36).substring(7) + 'b';
  geometry_dash_class =
    'a' + (Math.random() + 1).toString(36).substring(7) + 'b';
  hsl_var_suffix = 'a' + (Math.random() + 1).toString(36).substring(7) + 'b';
  gradient_heart_main_id =
    'a' + (Math.random() + 1).toString(36).substring(7) + 'b';
  gradient_heart_back_id =
    'a' + (Math.random() + 1).toString(36).substring(7) + 'b';
  gradient_number_main_id =
    'a' + (Math.random() + 1).toString(36).substring(7) + 'b';
  gradient_number_back_id =
    'a' + (Math.random() + 1).toString(36).substring(7) + 'b';

  render() {
    let color;
    if (this.props.configuration) {
      let colorConfig = this.findColor(
        this.props.configuration.ranges,
        this.props.pulse
      );
      if (colorConfig == null) {
        color = 'black';
      } else {
        color = colorConfig;
      }
    } else {
      color = 'black';
    }

    let innerBorderColor = this.props.configuration.innerBorderColor
      ? this.props.configuration.innerBorderColor
      : 'black';
    let outerBorderColor = this.props.configuration.outerBorderColor
      ? this.props.configuration.outerBorderColor
      : 'white';

    return (
      <>
        {this.props.configuration && (
          <>
            <style>
              {`
                            :root {
                                --wht${this.hsl_var_suffix}: 3px;
                                --m-wht${this.hsl_var_suffix}:-3px;
                                --blc${this.hsl_var_suffix}:3px;
                                --m-blc${this.hsl_var_suffix}:-3px;
                                --color${this.hsl_var_suffix}:${color};
                            }
                           #${this.geometry_dash_id}{
                                animation: pulse_animation ${
                                  this.state.animationDuration
                                }ms 400ms ease infinite;
                            }

                            .${this.geometry_dash_class} {
                                filter:
                                ${
                                  this.props.configuration.innerBorderEnabled
                                    ? `
                                  drop-shadow(var(--m-blc${this.hsl_var_suffix}) var(--m-blc${this.hsl_var_suffix}) 0px ${innerBorderColor})
                                    drop-shadow(var(--m-blc${this.hsl_var_suffix}) var(--blc${this.hsl_var_suffix}) 0px ${innerBorderColor})
                                    drop-shadow(var(--blc${this.hsl_var_suffix}) var(--m-blc${this.hsl_var_suffix}) 0px ${innerBorderColor})
                                    drop-shadow(var(--blc${this.hsl_var_suffix}) var(--blc${this.hsl_var_suffix}) 0px ${innerBorderColor})
                                `
                                    : ''
                                }

                                 ${
                                   this.props.configuration.outerBorderEnabled
                                     ? `
                                   drop-shadow(var(--m-wht${this.hsl_var_suffix}) var(--m-wht${this.hsl_var_suffix}) 0px ${outerBorderColor})
                                    drop-shadow(var(--m-wht${this.hsl_var_suffix}) var(--wht${this.hsl_var_suffix}) 0px ${outerBorderColor})
                                    drop-shadow(var(--wht${this.hsl_var_suffix}) var(--m-wht${this.hsl_var_suffix}) 0px ${outerBorderColor})
                                    drop-shadow(var(--wht${this.hsl_var_suffix}) var(--wht${this.hsl_var_suffix}) 0px ${outerBorderColor})
                                `
                                     : ''
                                 }


                                    ${
                                      this.props.configuration.shadowEnabled
                                        ? 'drop-shadow(3px 3px 10px black)'
                                        : ''
                                    }
                            }
                         `}
            </style>

            <div style={{paddingLeft: '10vw', paddingTop: '10vh'}}>
              <div style={{display: 'inline-block'}}>
                <svg
                  viewBox="10 -10 140 200"
                  id={this.geometry_dash_id}
                  className={this.geometry_dash_class}
                  height={'30vh'}
                >
                  <defs>
                    <linearGradient
                      xmlns="http://www.w3.org/2000/svg"
                      id={this.gradient_heart_main_id}
                      gradientTransform="rotate(90)"
                    >
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '),100%, 78%)'
                        }
                      />
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '), 100%, 50%)'
                        }
                      />
                    </linearGradient>
                    <linearGradient
                      xmlns="http://www.w3.org/2000/svg"
                      id={this.gradient_heart_back_id}
                      gradientTransform="rotate(90)"
                    >
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '), 70%, 50%)'
                        }
                      />
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '), 70%, 35%)'
                        }
                      />
                    </linearGradient>
                  </defs>
                  {this.props.configuration.heartEnabled && (
                    <>
                      <g
                        stroke="none"
                        fill={`url(#${this.gradient_heart_back_id})`}
                        transform="matrix(1.6, 0, 0, 1.6, 10, 33)"
                      >
                        <path
                          id="firstNumBackPath"
                          d="M 11 0 L 33 0 L 33 11 L 55 11 L 55 0 L 77 0 L 77 11 L 88 11 L 88 44 L 77 44 L 77 55 L 66 55 L 66 66 L 55 66 L 55 77 L 33 77 L 33 66 L 22 66 L 22 55 L 11 55 L 11 44 L 0 44 L 0 11 L 11 11 Z"
                        />
                      </g>
                      <g
                        stroke="none"
                        fill={`url(#${this.gradient_heart_main_id})`}
                        transform="matrix(1.6, 0, 0, 1.6, 10, 20)"
                      >
                        <path
                          id="firstNumMainPath"
                          d="M 11 0 L 33 0 L 33 11 L 55 11 L 55 0 L 77 0 L 77 11 L 88 11 L 88 44 L 77 44 L 77 55 L 66 55 L 66 66 L 55 66 L 55 77 L 33 77 L 33 66 L 22 66 L 22 55 L 11 55 L 11 44 L 0 44 L 0 11 L 11 11 Z"
                        />
                      </g>
                    </>
                  )}
                </svg>
              </div>
              <div style={{display: 'inline-block'}}>
                <svg
                  viewBox="160 -10 600 200"
                  className={this.geometry_dash_class}
                  height={'30vh'}
                >
                  <defs>
                    <linearGradient
                      xmlns="http://www.w3.org/2000/svg"
                      id={this.gradient_number_main_id}
                      gradientTransform="rotate(90)"
                    >
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '),100%, 78%)'
                        }
                      />
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '), 100%, 50%)'
                        }
                      />
                    </linearGradient>
                    <linearGradient
                      xmlns="http://www.w3.org/2000/svg"
                      id={this.gradient_number_back_id}
                      gradientTransform="rotate(90)"
                    >
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '), 70%, 50%)'
                        }
                      />
                      <stop
                        offset="60%"
                        stopColor={
                          'hsl(var(--color' +
                          this.hsl_var_suffix +
                          '), 70%, 35%)'
                        }
                      />
                    </linearGradient>
                  </defs>

                  {this.getSvgFor(this.props.pulse, 220)}
                </svg>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  getSvgFor = (number, initialOffset) => {
    let paths = {
      1: 'M 33.2 0 L 33.2 66.6 L 22.2 66.6 L 22.2 55.4 L 11.1 55.4 L 11.1 22.1 L 0 22.1 L 0 11.1 L 11.1 11.1 L 11.1 0 L 33.2 0 Z',
      2: 'M 55.4 11.1 L 55.4 33.2 L 44.3 33.2 L 44.3 44.3 L 22.1 44.3 L 22.1 55.6 L 44.3 55.6 L 44.3 66.6 L 11.1 66.6 L 11.1 55.4 L 0 55.4 L 0 44.4 L 11.1 44.4 L 11.1 33.3 L 33.3 33.3 L 33.3 11 L 22.1 11 L 22.1 22.1 L 0 22.1 L 0 0 L 44.3 0 L 44.3 11.1 L 55.4 11.1 Z',
      3: 'M 55.4 33.3 L 55.4 55.4 L 44.3 55.4 L 44.3 66.6 L 33.2 66.6 L 33.2 77.7 L 22.2 77.7 L 22.2 66.6 L 11.1 66.6 L 11.1 55.4 L 0 55.4 L 0 44.4 L 22.1 44.4 L 22.1 55.6 L 33.3 55.6 L 33.3 33.2 L 22.2 33.2 L 22.2 22.2 L 33.3 22.2 L 33.3 11 L 11.1 11 L 11.1 0 L 55.4 0 L 55.4 22.1 L 44.3 22.1 L 44.3 33.3 L 55.4 33.3 Z',
      4: 'M 66.5 33.3 L 66.5 44.3 L 55.4 44.3 L 55.4 77.7 L 44.4 77.7 L 44.4 66.6 L 33.3 66.6 L 33.3 44.3 L 0 44.3 L 0 11.1 L 11.1 11.1 L 11.1 0 L 22.1 0 L 22.1 33.3 L 33.3 33.3 L 33.3 11.1 L 44.4 11.1 L 44.4 0 L 55.4 0 L 55.4 33.3 L 66.5 33.3 Z',
      5: 'M 55.4 33.3 L 55.4 55.4 L 44.3 55.4 L 44.3 66.6 L 11.1 66.6 L 11.1 55.6 L 33.3 55.6 L 33.3 44.3 L 0 44.3 L 0 0 L 44.3 0 L 44.3 11 L 22.1 11 L 22.1 22.2 L 44.3 22.2 L 44.3 33.3 L 55.4 33.3 Z',
      6: 'M 55.4 33.3 L 55.4 66.6 L 44.3 66.6 L 44.3 77.7 L 11.1 77.7 L 11.1 66.6 L 0 66.6 L 0 11.1 L 11.1 11.1 L 11.1 0 L 33.2 0 L 33.2 11 L 22.1 11 L 22.1 22.2 L 44.3 22.2 L 44.3 33.3 L 55.4 33.3 Z M 22.2 66.6 L 33.2 66.6 L 33.2 44.4 L 22.2 44.4 L 22.2 66.6 Z',
      7: 'M 55.4 0 L 55.4 33.2 L 44.3 33.2 L 44.3 44.3 L 33.2 44.3 L 33.2 55.4 L 22.1 55.4 L 22.1 66.6 L 0 66.6 L 0 44.4 L 11.1 44.4 L 11.1 33.3 L 22.2 33.3 L 22.2 11 L 0 11 L 0 0 L 55.4 0 Z',
      8: 'M 66.5 11.1 L 66.5 55.4 L 55.4 55.4 L 55.4 66.6 L 22.2 66.6 L 22.2 55.4 L 0 55.4 L 0 22.2 L 11.1 22.2 L 11.1 0 L 55.4 0 L 55.4 11.1 L 66.5 11.1 Z M 22.2 44.3 L 44.3 44.3 L 44.3 33.3 L 22.2 33.3 L 22.2 44.3 Z M 33.3 22.1 L 44.3 22.1 L 44.3 11.1 L 33.3 11.1 L 33.3 22.1 Z',
      9: 'M 55.4 11.1 L 55.4 66.6 L 44.3 66.6 L 44.3 77.7 L 22.2 77.7 L 22.2 66.7 L 33.3 66.7 L 33.3 55.4 L 11.1 55.4 L 11.1 44.3 L 0 44.3 L 0 11.1 L 11.1 11.1 L 11.1 0 L 44.3 0 L 44.3 11.1 L 55.4 11.1 Z M 22.2 33.2 L 33.2 33.2 L 33.2 11.1 L 22.2 11.1 L 22.2 33.2 Z',
      0: 'M 55.4 11.1 L 55.4 55.4 L 44.3 55.4 L 44.3 66.6 L 11.1 66.6 L 11.1 55.4 L 0 55.4 L 0 11.1 L 11.1 11.1 L 11.1 0 L 44.3 0 L 44.3 11.1 L 55.4 11.1 Z M 22.2 44.3 L 33.2 44.3 L 33.2 11.1 L 22.2 11.1 L 22.2 44.3 Z',
    };

    let offsets = {
      1: 120,
      2: 160,
      3: 170,
      4: 180,
      5: 170,
      6: 180,
      7: 180,
      8: 200,
      9: 170,
      0: 150,
    };

    let offsetAcc = initialOffset;
    let processedPaths = [];

    this.props.pulse
      .toString()
      .split('')
      .map(Number)
      .forEach((number, index) => {
        processedPaths.push(
          <>
            <g
              stroke="none"
              fill={`url(#${this.gradient_heart_back_id})`}
              transform={`matrix(2, 0, 0, 2, ${offsetAcc}, 13)`}
            >
              <path d={paths[number]} />
            </g>
            <g
              stroke="none"
              fill={`url(#${this.gradient_number_main_id})`}
              transform={`matrix(2, 0, 0, 2, ${offsetAcc}, 0)`}
            >
              <path d={paths[number]} />
            </g>
          </>
        );
        offsetAcc += offsets[number];
      });

    return processedPaths;
  };
}
