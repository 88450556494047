import * as React from 'react';
import css from './EcgIcon.module.scss';

export type EcgIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  withAnimation?: boolean;
};

export const EcgIcon = ({
  color = '#000',
  size = 100,
  withAnimation,
  style,
}: EcgIconProps) => {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 280 242">
      <path
        className={withAnimation ? css.animate1 : ''}
        d={
          'm 4.2,200.5 c 0,0 45.4,3.2 62.2,-2 2.9,-0.9 4.04,-5.7 6,-8 1.4,-1.6 2.9,-4.7 5,-5 2.2,-0.3 5.8,3.5 7.2,5.2 2,2.5 5.2,9 5.9,9.4 2.6,1.7 14.9,1.3 14.9,1.3 l 6.5,29.6 9,-226.5 6,258 8.9,-61.5 c 0,0 30,2.1 41.1,-3 6,-2.8 10.4,-11.4 14.5,-16.5 6.2,-7.8 11.5,-21 19.5,-27 2.4,-1.8 6.5,-3.5 9.5,-3.5 2.9,0.02 6.7,1.7 9,3.5 7.06,5.4 12.5,16.6 17.5,24 3,4.4 7,10.5 10.3,14.5 2.02,2.4 4.6,6.8 7.6,7.6 6.4,1.8 15.7,0.6 22.3,0.6 3.6,0 15.9,-0.3 15.9,-0.3'
        }
        style={{
          strokeWidth: '6px',
          stroke: color,
          fill: 'none',
          strokeLinecap: 'round',
        }}
      />
    </svg>
  );
};
