import React from 'react';
import {PulseSpeedStrategy} from '../utils/pulseUtils';

export class DeathloopWidget extends React.Component {
  findColor = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return range.color === undefined ? 'transparent' : range.color;
      }
    }
    return ranges[ranges.length - 1].color;
  };

  constructor(props) {
    super(props);

    var strategy = new PulseSpeedStrategy();
    strategy.add(0, 70, 1500);
    strategy.add(71, 90, 1200);
    strategy.add(91, 100, 1000);
    strategy.add(101, 115, 800);
    strategy.add(116, 1000, 700);
    // eslint-disable-next-line
    this.state = {
      strategy: strategy,
      animation: true,
      animationKey: String(Date.now()),
      pulseDuration: 500,
    };
  }

  animationDone = () => {
    this.setState({
      animation: false,
      pulseDuration: this.state.strategy.get(this.props.pulse),
      animationKey: String(Date.now()),
    });
  };

  render() {
    let height = 637;
    let width = 545;

    return (
      <div
        style={{
          backgroundImage: `url(https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/deathloop-back-with-ecg.png)`,
          width: width,
          height: height,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap"
          rel="stylesheet"
        />
        <div
          style={{
            fontSize: 220,
            color: '#f9dcc5',
            position: 'absolute',
            top: 125,
            left: 0,
            width: 545,
            zIndex: 5,
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          {this.props.pulse}
        </div>
        <span
          style={{
            color: '#f9dcc5',
            position: 'relative',
            fontSize: 60,
            top: 360,
            left: 155,
            zIndex: 6,
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          ♥ BPM
        </span>
      </div>
    );
  }
}
