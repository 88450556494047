import {Ranges} from '../widgets/common';

export const findColorInRange = <T extends {color?: string}>(
  ranges: Ranges<T> = [],
  {pulse, defaultColor = 'transparent'}: {pulse: number; defaultColor?: string}
) => {
  for (let range of ranges) {
    if (range.from <= pulse && range.to >= pulse) {
      return range.color === undefined ? defaultColor : range.color;
    }
  }
  return ranges?.[ranges.length - 1]?.color || defaultColor;
};

export const findRangeForPulse = <T>(
  ranges: Ranges<T> = [],
  pulse: number,
  fallback?: T
) => {
  for (let range of ranges) {
    if (range.from <= pulse && range.to >= pulse) {
      return range;
    }
  }

  if (ranges.length === 0) {
    return null;
  }

  return fallback || ranges?.[ranges.length - 1];
};

export class PulseSpeedStrategy {
  stratagies: {from: number; to: number; time: number}[] = [];

  add(from: number, to: number, time: number) {
    this.stratagies.push({from: from, to: to, time: time});
  }

  get(bpm: number) {
    for (var i = 0; i < this.stratagies.length; i++) {
      var s = this.stratagies[i];
      if (bpm >= s.from && bpm <= s.to) {
        return s.time;
      }
    }
    return null;
  }
}
