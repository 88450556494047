import React from 'react';
import './CyberpunkHeart.css';

export class CyberpunkHeartWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationDuration: 5,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pulse <= 85) {
      return {
        animationDuration: 5,
      };
    } else if (props.pulse > 85 && props.pulse <= 105) {
      return {
        animationDuration: 4,
      };
    } else if (props.pulse > 105 && props.pulse <= 120) {
      return {
        animationDuration: 3,
      };
    } else if (props.pulse > 120 && props.pulse <= 140) {
      return {
        animationDuration: 2,
      };
    } else if (props.pulse > 140) {
      return {
        animationDuration: 1,
      };
    }
    return null;
  }

  render() {
    return (
      <img
        alt={'something went wrong'}
        style={{
          animation: `cyberpunk-heart-blink-keyframes ${this.state.animationDuration}s step-start  infinite`,
          '-webkit-animation': `cyberpunk-heart-blink-keyframes ${this.state.animationDuration}s step-start  infinite`,
        }}
        src="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/cyberpunk-heart.png"
      ></img>
    );
  }
}
