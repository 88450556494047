import React, {useEffect, useState} from 'react';
import {WidgetProps} from '../common';
import {PulseSpeedStrategy, findRangeForPulse} from '../../utils/rangeUtils';
import css from './ResidentEvilWidget.module.scss';
import {loadCustumFont} from '../../utils/fontUtils';
import {pressStart2PFont} from '../../controls/FontPicker/FontPicker.constants';

export type ResidentEvilWidgetProps = WidgetProps<{
  speed?: number;
  color: string;
  text: string;
}>;

export const ResidentEvilWidget = ({
  pulse,
  configuration,
}: ResidentEvilWidgetProps) => {
  useEffect(() => {
    loadCustumFont(pressStart2PFont);
  }, []);

  const {ranges} = configuration;
  const activeRange = findRangeForPulse(ranges, pulse, {
    text: 'invalid',
    color: 'black',
  });
  const color = activeRange.color;
  const text = activeRange.text;

  const generatePulseStrategy = () => {
    const strategy = new PulseSpeedStrategy();
    ranges.forEach((range) => {
      const speed = Number(range?.speed) || 0;
      strategy.add(range.from, range.to, (5 - speed) * 500);
    });
    return strategy;
  };

  const pulseSpeedStrategy = generatePulseStrategy();

  const [svgAnimationKey, setSvgAnimationKey] = useState(`${Date.now()}`);
  const [pulseDuration, setPulseDuration] = useState(
    pulseSpeedStrategy.get(pulse)
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 187"
      className="resident-evil-widget-container"
      style={{
        position: 'relative',
        background:
          "url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/resident-evil-status/resident-evil-status-background.png')",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100%',
        height: '100%',
      }}
    >
      <path
        key={svgAnimationKey}
        fill="none"
        stroke={color}
        strokeWidth={2}
        d="M 3.513 30.6 L 30.633 31.078 L 38.49 21.763 L 52.845 46.32 L 59.766 8.537 L 73.97 47.094 L 75.956 30.564 C 75.956 30.564 94.393 30.194 94.393 30.225 C 94.393 30.256 101.672 23.121 101.672 23.121 C 101.672 23.121 107.777 31.575 107.745 31.575 C 107.713 31.575 115.565 45.279 115.565 45.31 C 115.565 45.341 121.937 11.163 121.937 11.163 C 121.937 11.163 123.983 33.3 123.983 33.334 C 123.983 33.368 135.918 32.936 135.918 32.936"
        className={css.ekg}
        onAnimationEnd={() => {
          setSvgAnimationKey(`${Date.now()}`);
          setPulseDuration(pulseSpeedStrategy.get(pulse));
        }}
        style={{animationDuration: `${pulseDuration}ms`}}
      />
      <text
        x="345px"
        y="82px"
        fontSize="46px"
        fontFamily="'Press Start 2P', sans-serif"
        textAnchor="end"
        fill={color}
      >
        {pulse}
      </text>
      <defs>
        <path id="svg-text-path" d="M 0 135 H 485" fill="transparent" />
      </defs>
      <text x="25px" y="10px">
        <textPath
          startOffset="50%"
          textAnchor="middle"
          fontSize="20"
          fontFamily="'Press Start 2P', sans-serif"
          textLength={text.length > 6 ? '140px' : null}
          fill={color}
          xlinkHref="#svg-text-path"
          method="align"
          lengthAdjust="spacingAndGlyphs"
        >
          {text}
        </textPath>
      </text>
    </svg>
  );
};
