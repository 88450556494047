import classNames from 'classnames';
import React, {useState} from 'react';
import css from './BasePicker.module.scss';

export type BasePickerDefaultItemConfiguration = {
  color?: string;
  size?: number;
};

export type BasePickerProps<
  ItemType = string,
  ItemConfiguration = BasePickerDefaultItemConfiguration
> = {
  selectedItem?: string | null;
  onSelect?: (layout: string) => void;
  itemConfiguration?: ItemConfiguration;

  className?: string;
  itemClassName?: string;

  items: ItemType[];
  renderItem: (item: ItemType) => React.ReactNode;
  withNone?: boolean;
};

export const BasePicker = (props: BasePickerProps) => {
  let [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const {withNone = true} = props;

  return (
    <div className={[css.picker, props.className || ''].join(' ')}>
      {withNone ? (
        <div
          className={classNames(
            css.pickerItem,
            css.pickerItemEmpty,
            selectedItem === null && css.selected,
            props.itemClassName
          )}
          onClick={() => {
            setSelectedItem(null);
            props.onSelect?.(null);
          }}
          style={{
            height: props.itemConfiguration?.size || 40,
            width: props.itemConfiguration?.size || 40,
          }}
        >
          None
        </div>
      ) : null}
      {props.items.map((item) => {
        return (
          <div
            key={item}
            onClick={() => {
              setSelectedItem(item);
              props.onSelect?.(item);
            }}
            className={classNames(
              css.pickerItem,
              selectedItem === item && css.selected,
              props.itemClassName
            )}
            style={{
              height: props.itemConfiguration?.size || 40,
              width: props.itemConfiguration?.size || 40,
            }}
          >
            {props.renderItem(item)}
          </div>
        );
      })}
    </div>
  );
};
