import React from 'react';
import './BeatsSaberWidget.css';

export class PulsoidStromnoWidget extends React.Component {
  render() {
    return (
      <>
        {this.props.configuration && (
          <div
            style={{
              backgroundImage: `url(https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/stromno-x-pulsoid-a-watch-500px.png)`,
              // width: width,
              height: 500,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap"
              rel="stylesheet"
            />
            <div
              style={{
                fontSize: 90,
                color: '#cab418',
                position: 'absolute',
                top: 227,
                left: 102,
                zIndex: 5,
                textAlign: 'center',
                fontFamily: 'Lato',
                width: 300,
              }}
            >
              {this.props.pulse}
            </div>
          </div>
        )}
      </>
    );
  }
}
