import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartFragmentedSquaresIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 18.685 7.855 L 18.685 14.911 L 11.629 14.911 L 11.629 7.855 L 18.685 7.855 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 27.679 7.846 L 27.679 14.921 L 20.641 14.921 L 20.641 7.846 L 27.679 7.846 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 9.701 23.83 L 2.626 23.83 L 2.626 16.755 L 9.701 16.755 L 9.701 23.83 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 11.619 23.811 L 11.619 16.736 L 18.666 16.736 L 18.666 23.811 L 11.619 23.811 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 27.66 23.849 L 20.632 23.849 L 20.632 16.736 L 27.66 16.736 L 27.66 23.849 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 54.518 23.839 L 47.481 23.839 L 47.481 16.746 L 54.518 16.746 L 54.518 23.839 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 56.39 16.727 L 63.4 16.727 L 63.4 23.802 L 56.39 23.802 L 56.39 16.727 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 65.365 16.718 L 72.402 16.718 L 72.402 23.821 L 65.384 23.821 L 65.365 16.718 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 81.33 23.839 L 74.321 23.839 L 74.321 16.746 L 81.33 16.746 L 81.33 23.839 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 2.635 25.674 L 9.701 25.674 L 9.701 32.786 L 2.635 32.786 L 2.635 25.674 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 47.453 68.694 L 47.453 61.601 L 54.5 61.601 L 54.5 68.694 L 47.453 68.694 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 54.509 59.813 L 47.49 59.813 L 47.49 52.701 L 54.509 52.701 L 54.509 59.813 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 20.613 59.804 L 20.613 52.71 L 27.669 52.71 L 27.669 59.804 L 20.613 59.804 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 18.685 25.702 L 18.685 32.758 L 11.629 32.758 L 11.629 25.702 L 18.685 25.702 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 20.622 25.674 L 27.669 25.674 L 27.669 32.758 L 20.622 32.758 L 20.622 25.674 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 65.356 43.745 L 72.402 43.745 L 72.402 50.848 L 65.356 50.848 L 65.356 43.745 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 47.472 43.735 L 54.5 43.735 L 54.5 50.848 L 47.472 50.848 L 47.472 43.735 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 54.518 25.683 L 54.518 32.777 L 47.472 32.777 L 47.472 25.683 L 54.518 25.683 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 27.65 50.867 L 20.641 50.867 L 20.641 43.763 L 27.65 43.763 L 27.65 50.867 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 18.657 50.848 L 11.619 50.848 L 11.619 43.773 L 18.657 43.773 L 18.657 50.848 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 65.365 34.845 L 72.402 34.845 L 72.402 41.957 L 65.384 41.957 L 65.365 34.845 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 2.645 34.864 L 9.701 34.864 L 9.701 41.957 L 2.645 41.957 L 2.645 34.864 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 11.619 34.854 L 18.685 34.854 L 18.685 41.939 L 11.619 41.939 L 11.619 34.854 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 27.679 34.882 L 27.679 41.939 L 20.641 41.939 L 20.641 34.882 L 27.679 34.882 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 54.509 41.957 L 47.462 41.957 L 47.462 34.864 L 54.509 34.864 L 54.509 41.957 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 38.516 41.948 L 38.516 34.873 L 45.544 34.873 L 45.544 41.948 L 38.516 41.948 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 63.409 34.864 L 63.409 41.948 L 56.39 41.948 L 56.39 34.864 L 63.409 34.864 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 81.33 32.795 L 74.274 32.795 L 74.274 25.683 L 81.293 25.683 L 81.33 32.795 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 72.402 25.692 L 72.402 32.777 L 65.374 32.777 L 65.374 25.692 L 72.402 25.692 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 63.4 32.786 L 56.39 32.786 L 56.39 25.692 L 63.4 25.692 L 63.4 32.786 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 45.534 50.848 L 38.525 50.848 L 38.525 43.745 L 45.534 43.745 L 45.534 50.848 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 38.516 32.767 L 38.516 25.683 L 45.553 25.683 L 45.553 32.767 L 38.516 32.767 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 63.409 43.735 L 63.409 50.848 L 56.381 50.848 L 56.381 43.735 L 63.409 43.735 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 38.553 52.701 L 45.534 52.701 L 45.534 59.785 L 38.553 59.785 L 38.553 52.701 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 72.402 7.771 L 72.402 14.93 L 65.29 14.93 L 65.29 12.871 C 65.29 11.402 65.29 9.933 65.29 8.464 C 65.29 8.07 65.29 7.762 65.842 7.771 L 72.402 7.771 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 56.381 59.794 L 56.381 52.701 L 63.381 52.701 L 63.381 59.794 L 56.381 59.794 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 45.562 61.61 L 45.562 68.694 L 38.516 68.694 L 38.516 61.61 L 45.562 61.61 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 56.381 7.818 L 63.381 7.818 L 63.381 14.921 L 56.381 14.921 L 56.381 7.818 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 45.562 70.575 L 45.562 77.632 L 38.544 77.632 L 38.544 70.575 L 45.562 70.575 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 36.56 41.967 L 29.578 41.967 L 29.578 34.873 L 36.56 34.873 L 36.56 41.967 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 74.433 41.957 L 74.433 34.873 L 81.433 34.873 L 81.433 41.957 L 74.433 41.957 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 29.56 43.754 L 36.541 43.754 L 36.541 50.829 L 29.56 50.829 L 29.56 43.754 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 29.55 32.777 L 29.55 25.702 L 36.55 25.702 L 36.55 32.777 L 29.55 32.777 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 29.541 23.821 L 29.541 16.746 L 36.56 16.746 L 36.56 23.821 L 29.541 23.821 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 29.55 52.701 L 36.55 52.701 L 36.55 59.804 L 29.55 59.804 L 29.55 52.701 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 36.541 68.704 L 29.569 68.704 L 29.569 61.601 L 36.541 61.601 L 36.541 68.704 Z"
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
