// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Inter\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Inter-VariableFont_slnt_Valorant.ttf\") format(\"opentype\")}.NR6qZg720rngV0VzuTuz{font-size:144px;font-family:\"Inter\";opacity:80%;font-weight:500;font-feature-settings:\"tnum\"}.OXqeiutpkHX2LoYIWwwS{font-size:22px;fill:#fff;font-family:\"Inter\";font-weight:700;letter-spacing:3px}", "",{"version":3,"sources":["webpack://./src/widgets/ValorantWidget/ValorantWidget.module.scss"],"names":[],"mappings":"AAAA,WACE,mBAAA,CACA,2IAAA,CAGF,sBACE,eAAA,CACA,mBAAA,CACA,WAAA,CACA,eAAA,CACA,4BAAA,CAEF,sBACE,cAAA,CACA,SAAA,CACA,mBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":["@font-face {\n  font-family: 'Inter';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/Inter-VariableFont_slnt_Valorant.ttf')\n    format('opentype');\n}\n.pulse {\n  font-size: 144px;\n  font-family: 'Inter';\n  opacity: 80%;\n  font-weight: 500;\n  font-feature-settings: 'tnum';\n}\n.spikeText {\n  font-size: 22px;\n  fill: #ffffff;\n  font-family: 'Inter';\n  font-weight: 700;\n  letter-spacing: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "NR6qZg720rngV0VzuTuz",
	"spikeText": "OXqeiutpkHX2LoYIWwwS"
};
export default ___CSS_LOADER_EXPORT___;
