import React from 'react';
import './Valorant.css';

export class ValorantWidget extends React.Component {
  render() {
    return (
      <>
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap"
          rel="stylesheet"
        />
        {this.props.configuration && (
          <div className="container">
            <img
              src="https://pulsoid-magi.nyc3.digitaloceanspaces.com/widget-source/valorant.png"
              alt="Snow"
            />
            <div className="centered">{this.props.pulse}</div>
          </div>
        )}
      </>
    );
  }
}
