type RgbMatrixColor = {
  r: number;
  g: number;
  b: number;
  a: number;
};

function hexToRgb(hex: string): RgbMatrixColor {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex6 = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex6
  );
  const base = 1 / 255;
  return result
    ? {
        r: parseInt(result[1], 16) * base,
        g: parseInt(result[2], 16) * base,
        b: parseInt(result[3], 16) * base,
        a: result[4] ? parseInt(result[4], 16) * base : 1,
      }
    : null;
}

function computeMatrixColor(rgbColor: RgbMatrixColor): string {
  return `0 0 0 0 ${rgbColor.r} 
    0 0 0 0 ${rgbColor.g} 
    0 0 0 0 ${rgbColor.b} 
    0 0 0 ${rgbColor.a} 0`;
}

export function convertHexToRgbMatrix(hexColor: string) {
  const rgbColor: RgbMatrixColor = hexToRgb(hexColor);
  return computeMatrixColor(rgbColor);
}
