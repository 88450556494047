import React from 'react';
import {findRangeForPulse} from '../../utils/rangeUtils';
import {WidgetProps} from '../common';
import css from './ChristmasWidget.module.scss';

export type ChristmasWidgetProps = WidgetProps<
  {image?: string},
  {color?: string}
>;

const DEFAULT_COLOR = '#003e03';

export const ChristmasWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: ChristmasWidgetProps) => {
  const ranges = configuration?.ranges || [];
  const range = findRangeForPulse(ranges, pulse);
  const shouldShowEmptyState = emptyStateComponent && !pulse;

  const color = configuration?.color || DEFAULT_COLOR;

  const backgroundImageUrl =
    range.image ||
    'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/winter/blue-gif.png';

  return (
    <svg width="100%" height="100%" viewBox="0 0 500 500">
      <foreignObject
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: 500,
            height: 500,
            lineHeight: 'initial',
          }}
        >
          <div className={css.pulse} style={{color}}>
            {shouldShowEmptyState ? emptyStateComponent : pulse}
          </div>

          <div className={css.bpm} style={{color}}>
            BPM
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};
