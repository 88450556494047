import React from 'react';
import './referral-widget.css';

export class ReferralWidget extends React.Component {
  state = {
    scene: 1,
  };

  componentDidMount() {
    let intervalId = setTimeout(this.trigger, 15000);
    this.setState({intervalId});
  }

  trigger = () => {
    if (this.state.scene === 1) {
      this.setState({scene: 2}, () => setTimeout(this.trigger, 15000));
    } else if (this.state.scene === 2) {
      if (this.props.configuration.displayMode === 'once_in_a_period') {
        this.setState({scene: 3}, () =>
          setTimeout(this.trigger, this.props.configuration.duration)
        );
      } else {
        this.setState({scene: 1}, () => setTimeout(this.trigger, 15000));
      }
    } else if (this.state.scene === 3) {
      this.setState({scene: 1}, () => setTimeout(this.trigger, 15000));
    }
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    let scene = <></>;
    switch (this.state.scene) {
      case 1:
        scene = (
          <img
            width="300px"
            src="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/brand-assets/referral-banner.png"
            alt={''}
          />
        );
        break;
      case 2:
        scene = (
          <div style={{width: '400px'}}>
            <p id="line1">Create an account</p>

            <p id="line2">
              {this.props.configuration.referralUrl.replaceAll('https://', '')}
            </p>

            <p id="line3">& get an additional trial week</p>
          </div>
        );
        break;
      default:
    }

    return <div style={{padding: '100px'}}>{scene}</div>;
  }
}
