export class PredictionClient {
  async performRpc<T>(method: string, params: any): Promise<T> {
    let response = await fetch('https://pulsoid.net/v1/api/public/rpc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: '1',
        method: method,
        params: params,
      }),
    });
    let body = await response.json();
    return body.result as T;
  }

  async startPrediction(
    accessToken: string,
    title: string,
    outcomes: string[],
    predictionWindowInSeconds: number
  ) {
    return this.performRpc<Prediction>('startPrediction', {
      accessToken,
      title,
      outcomes,
      predictionWindowInSeconds,
    });
  }

  async resolvePrediction(
    accessToken: string,
    predictionId: string,
    winningOutcomeId: string
  ) {
    return this.performRpc<any>('resolvePrediction', {
      accessToken,
      predictionId,
      winningOutcomeId,
    });
  }
}

export type PredictionOutcome = {
  id: string;
  title: string;
};

export type Prediction = {
  predictionId: string | null;
  outcomes: PredictionOutcome[];
};

export function getOutcomeIdByTitle(
  prediction: Prediction,
  searchString: string
): string | undefined {
  const outcome = prediction.outcomes.find(
    (outcome) => outcome.title === searchString
  );
  return outcome ? outcome.id : undefined;
}
