export const resolveFontUrl = ({
  fontUrl,
  font,
  defaultFontUrl,
}: {
  fontUrl?: string;
  font?: string;
  defaultFontUrl?: string;
}) => {
  if (fontUrl) {
    return fontUrl;
  }

  if (font) {
    return `https://fonts.googleapis.com/css?family=${font}`;
  }

  return defaultFontUrl;
};

export const loadCustumFont = async (
  {
    name,
    url,
    file,
  }: {
    name: string;
  } & ({url: string; file?: File} | {file: File; url?: string}),
  {onError}: {onError?: (error: Error) => void} = {}
) => {
  if (file || (!!url && !url.includes('googleapis') && !url.endsWith('.css'))) {
    try {
      const font = new FontFace(
        name,
        file ? await file.arrayBuffer() : `url(${url})`
      );
      await font.load();
      (document.fonts as any).add(font);
    } catch (e) {
      console.error(`Failed to load font ${name} from ${url}`, e);
      onError?.(e);
    }
  }
};
