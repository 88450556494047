import pulsoid from '../pulsoid-medical-equipment.png';
import SvgLines from 'react-mt-svg-lines';
import React from 'react';
import {PulseSpeedStrategy} from '../utils/pulseUtils';

export class PulsoidMedicalWidget extends React.Component {
  findColor = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return range.color === undefined ? 'transparent' : range.color;
      }
    }
    return ranges[ranges.length - 1].color;
  };

  constructor(props) {
    super(props);

    var strategy = new PulseSpeedStrategy();
    strategy.add(0, 70, 1500);
    strategy.add(71, 90, 1200);
    strategy.add(91, 100, 1000);
    strategy.add(101, 115, 800);
    strategy.add(116, 1000, 700);
    this.state = {
      strategy: strategy,
      animation: true,
      animationKey: String(Date.now()),
      pulseDuration: 500,
    };
  }

  animationDone = () => {
    this.setState({
      animation: false,
      pulseDuration: this.state.strategy.get(this.props.pulse),
      animationKey: String(Date.now()),
    });
  };

  render() {
    let height = 637;
    let width = 545;
    let color;
    if (this.props.configuration) {
      color = this.findColor(this.props.configuration.ranges, this.props.pulse);
    } else {
      color = 'green';
    }

    return (
      <div
        style={{
          backgroundImage: `url(${pulsoid})`,
          width: width,
          height: height,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <div
          style={{
            fontSize: 180,
            color,
            position: 'absolute',
            top: 34,
            left: 0,
            width: 490,
            zIndex: 5,
            textAlign: 'right',
          }}
        >
          {this.props.pulse}
        </div>
        <SvgLines
          animate={this.state.animationKey}
          duration={this.state.pulseDuration}
          callback={this.animationDone}
        >
          <svg
            style={{
              height: 270,
              width: 270,
              position: 'absolute',
              top: 125,
              left: 60,
              zIndex: 7,
            }}
            viewBox="0 0 300 300"
          >
            <path
              ref={(ref) => (this.path = ref)}
              d={
                'm 4.2,200.5 c 0,0 45.4,3.2 62.2,-2 2.9,-0.9 4.04,-5.7 6,-8 1.4,-1.6 2.9,-4.7 5,-5 2.2,-0.3 5.8,3.5 7.2,5.2 2,2.5 5.2,9 5.9,9.4 2.6,1.7 14.9,1.3 14.9,1.3 l 6.5,29.6 9,-226.5 6,258 8.9,-61.5 c 0,0 30,2.1 41.1,-3 6,-2.8 10.4,-11.4 14.5,-16.5 6.2,-7.8 11.5,-21 19.5,-27 2.4,-1.8 6.5,-3.5 9.5,-3.5 2.9,0.02 6.7,1.7 9,3.5 7.06,5.4 12.5,16.6 17.5,24 3,4.4 7,10.5 10.3,14.5 2.02,2.4 4.6,6.8 7.6,7.6 6.4,1.8 15.7,0.6 22.3,0.6 3.6,0 15.9,-0.3 15.9,-0.3'
              }
              style={{stroke: 'green', fill: 'none', strokeWidth: '5px'}}
            ></path>
          </svg>
        </SvgLines>
        <span
          style={{
            color: 'green',
            position: 'absolute',
            fontSize: 40,
            top: 250,
            left: 330,
            zIndex: 6,
          }}
        >
          BPM
        </span>
      </div>
    );
  }
}
