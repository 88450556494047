import React from 'react';
import {OneOf} from '../../types/internal';
import {EcgIcon} from './images/EcgIcon/EcgIcon';
import {EcgIcon2} from './images/EcgIcon/EcgIcon2';
import {HeartBrushIcon} from './images/HeartIcon/HeartBrushIcon';
import {HeartFragmentedSquaresIcon} from './images/HeartIcon/HeartFragmentedSquaresIcon';
import {HeartInkIcon} from './images/HeartIcon/HeartInkIcon';
import {HeartLinedIcon} from './images/HeartIcon/heartLinedIcon';
import {HeartNestedIcon} from './images/HeartIcon/HeartNestedIcon';
import {HeartPixelIcon} from './images/HeartIcon/HeartPixelIcon';
import {HeartPulsoidIcon} from './images/HeartIcon/HeartPulsoidIcon';
import {HeartPuzzleIcon} from './images/HeartIcon/HeartPuzzleIcon';
import {HeartRibbonIcon} from './images/HeartIcon/HeartRibbonIcon';
import {HeartSimpleIcon} from './images/HeartIcon/heartSimpleIcon';
import {HeartSimpleIcon2} from './images/HeartIcon/HeartSimpleIcon2';
import {
  HeartAsexual,
  HeartBisexual,
  HeartGayMen,
  HeartIntersex,
  HeartLesbian,
  HeartNonBinary,
  HeartPanSexual,
  HeartPride,
  HeartPrideProgress,
  HeartTransGender,
} from './images/HeartIcon/PrideHeartOutline';

export const IMAGE_PICKER_TYPES = {
  ECG: 'ecg',
  ECG_2: 'ecg2',
  HEART: 'heart',
  HEART_2: 'heart2',
  HEART_LINED: 'heartLined',
  HEART_NESTED: 'heartNested',
  HEART_PIXEL: 'heartPixel',
  HEART_PUZZLE: 'heartPuzzle',
  HEART_RIBBON: 'heartRibbon',
  HEART_INK: 'heartInk',
  HEART_FRAGMENTED_SQUARES: 'heartFragmentedSquares',
  HEART_BRUSH: 'heartBrush',
  HEART_PULSOID: 'heartPulsoid',
  HEART_GAY_MEN: 'heartGayMen',
  HEART_INTERSEX: 'heartIntersex',
  HEART_LESBIAN: 'heartLesbian',
  HEART_NON_BINARY: 'heartNonBinary',
  HEART_PAN_SEXUAL: 'heartPanSexual',
  HEART_PRIDE: 'heartPride',
  HEART_PRIDE_PROGRESS: 'heartPrideProgress',
  HEART_TRANS_GENDER: 'heartTransGender',
  HEART_ASEXUAL: 'heartAsexual',
  HEART_BISEXUAL: 'heartBisexual',
} as const;

export const IMAGE_PICKER_COLOR_TYPE = {
  STATIC: 'static',
  SYNC: 'sync',
  TRANSPARENT: 'transparent',
} as const;

export type ImagePickerImageConfiguration = {
  withAnimation?: boolean;
  color?: string;
  size?: number;
  type: OneOf<typeof IMAGE_PICKER_TYPES>;
  colorType?: OneOf<typeof IMAGE_PICKER_COLOR_TYPE>;
};

export type ImagePickerImageProps = Omit<
  ImagePickerImageConfiguration,
  'type'
> & {style?: React.CSSProperties; stroke?: string};

export const imagePickerTypeToComponentMap: {
  [key in OneOf<typeof IMAGE_PICKER_TYPES>]: React.FC<ImagePickerImageProps>;
} = {
  ecg: EcgIcon,
  ecg2: EcgIcon2,
  heart: HeartSimpleIcon,
  heartLined: HeartLinedIcon,
  heartNested: HeartNestedIcon,
  heartPixel: HeartPixelIcon,
  heartPuzzle: HeartPuzzleIcon,
  heartRibbon: HeartRibbonIcon,
  heartInk: HeartInkIcon,
  heartFragmentedSquares: HeartFragmentedSquaresIcon,
  heartBrush: HeartBrushIcon,
  heartPulsoid: HeartPulsoidIcon,
  heart2: HeartSimpleIcon2,
  heartGayMen: HeartGayMen,
  heartIntersex: HeartIntersex,
  heartLesbian: HeartLesbian,
  heartNonBinary: HeartNonBinary,
  heartPanSexual: HeartPanSexual,
  heartPride: HeartPride,
  heartPrideProgress: HeartPrideProgress,
  heartTransGender: HeartTransGender,
  heartAsexual: HeartAsexual,
  heartBisexual: HeartBisexual,
};

export const IMAGE_PICKER_TYPE_PRESETS = {
  basic: [IMAGE_PICKER_TYPES.ECG, IMAGE_PICKER_TYPES.HEART],
  all: [
    IMAGE_PICKER_TYPES.ECG,
    IMAGE_PICKER_TYPES.ECG_2,
    IMAGE_PICKER_TYPES.HEART,
    IMAGE_PICKER_TYPES.HEART_2,
    IMAGE_PICKER_TYPES.HEART_LINED,
    IMAGE_PICKER_TYPES.HEART_NESTED,
    IMAGE_PICKER_TYPES.HEART_PIXEL,
    IMAGE_PICKER_TYPES.HEART_PUZZLE,
    IMAGE_PICKER_TYPES.HEART_RIBBON,
    IMAGE_PICKER_TYPES.HEART_INK,
    IMAGE_PICKER_TYPES.HEART_FRAGMENTED_SQUARES,
    IMAGE_PICKER_TYPES.HEART_BRUSH,
    IMAGE_PICKER_TYPES.HEART_PULSOID,
  ],
  pride: [
    IMAGE_PICKER_TYPES.HEART_GAY_MEN,
    IMAGE_PICKER_TYPES.HEART_INTERSEX,
    IMAGE_PICKER_TYPES.HEART_LESBIAN,
    IMAGE_PICKER_TYPES.HEART_NON_BINARY,
    IMAGE_PICKER_TYPES.HEART_PAN_SEXUAL,
    IMAGE_PICKER_TYPES.HEART_PRIDE,
    IMAGE_PICKER_TYPES.HEART_PRIDE_PROGRESS,
    IMAGE_PICKER_TYPES.HEART_TRANS_GENDER,
    IMAGE_PICKER_TYPES.HEART_ASEXUAL,
    IMAGE_PICKER_TYPES.HEART_BISEXUAL,
  ],
} as const;
