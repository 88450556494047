// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HmLa1tw81ORbPailqX1C{font-family:\"Times New Roman\";font-style:normal;font-weight:400;font-size:144px;line-height:166px;text-align:center;text-shadow:0px 4px 4px 0px rgba(0,0,0,.2509803922)}", "",{"version":3,"sources":["webpack://./src/widgets/EldenRingWidget/EldenRingWidget.module.scss"],"names":[],"mappings":"AAAA,sBACE,6BAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,mDAAA","sourcesContent":[".pulse {\n  font-family: 'Times New Roman';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 144px;\n  line-height: 166px;\n  text-align: center;\n  text-shadow: 0px 4px 4px 0px #00000040;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "HmLa1tw81ORbPailqX1C"
};
export default ___CSS_LOADER_EXPORT___;
