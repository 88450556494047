// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"SnowtopCaps\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/winter/SnowtopCaps.otf\") format(\"opentype\")}.tZKCbfjyi52vYMl2eXa7{position:relative;top:135px;left:25px;font-size:140px;text-align:center;font-family:\"SnowtopCaps\"}.QpePivo2BQpBgMmtwJPe{position:relative;top:165px;left:25px;font-size:25px;text-align:center;font-family:\"SnowtopCaps\"}", "",{"version":3,"sources":["webpack://./src/widgets/ChristmasWidget/ChristmasWidget.module.scss"],"names":[],"mappings":"AAAA,WACE,yBAAA,CACA,uHAAA,CAIF,sBACE,iBAAA,CACA,SAAA,CACA,SAAA,CAEA,eAAA,CACA,iBAAA,CACA,yBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,SAAA,CAEA,cAAA,CACA,iBAAA,CACA,yBAAA","sourcesContent":["@font-face {\n  font-family: 'SnowtopCaps';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/winter/SnowtopCaps.otf')\n    format('opentype');\n}\n\n.pulse {\n  position: relative;\n  top: 135px;\n  left: 25px;\n\n  font-size: 140px;\n  text-align: center;\n  font-family: 'SnowtopCaps';\n}\n\n.bpm {\n  position: relative;\n  top: 165px;\n  left: 25px;\n\n  font-size: 25px;\n  text-align: center;\n  font-family: 'SnowtopCaps';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "tZKCbfjyi52vYMl2eXa7",
	"bpm": "QpePivo2BQpBgMmtwJPe"
};
export default ___CSS_LOADER_EXPORT___;
