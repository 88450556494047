import React from 'react';
import Sound from '../Sound';

export class SoundWidgetV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromLaunch: props.fromLaunch,
    };
  }

  findRange = (ranges, heartRate) => {
    for (let index = 0; index < ranges.length; index++) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate) {
        return index;
      }
    }
    return null;
  };

  componentWillReceiveProps(nextProps, nextContext) {
    let heartRate = nextProps.pulse;
    let index = this.findRange(this.props.configuration.ranges, heartRate);
    if (index != null) {
      if (this.state.index !== index) {
        if (
          this.props.configuration.interruptSound ||
          this.state.index === undefined ||
          this.state.play === false
        ) {
          this.setState({index, play: true});
        }
      }
    } else if (
      this.props.configuration.interruptSound ||
      this.state.play === false
    ) {
      this.setState({index: undefined, play: false});
    }
  }

  handleFinish = () => {
    let index = this.findRange(
      this.props.configuration.ranges,
      this.props.pulse
    );

    if (index != null) {
      if (index === this.state.index) {
        if (this.props.configuration.loopSound) {
          this.setState({index, play: true});
        } else {
          this.setState({index, play: false});
        }
      } else {
        this.setState({index, play: true});
      }
    } else {
      this.setState({index: undefined, play: false});
    }
  };

  render() {
    if (!this.state.fromLaunch) {
      return (
        <>
          {this.props.configuration.ranges &&
            this.props.configuration.ranges.map((range, index) => {
              let play = Sound.status.STOPPED;
              if (this.state.index === index && this.state.play) {
                play = Sound.status.PLAYING;
              }

              return (
                <Sound
                  key={index}
                  url={range.file.url}
                  playStatus={play}
                  playbackRate={range.playbackRate}
                  volume={range.volume}
                  onFinishedPlaying={() => this.handleFinish()}
                />
              );
            })}
        </>
      );
    } else {
      return (
        <button
          style={{width: '200px', height: '50px'}}
          onClick={() => this.setState({fromLaunch: false})}
        >
          Click here to start test you sound widget. This button is visible only
          in test mode.
        </button>
      );
    }
  }
}
