import React, {useEffect, useRef, useState} from 'react';

export type ResizableTextProps = React.SVGProps<SVGTextPathElement> & {
  text: string;
  fontSize: number;
  fontFamily?: string;
  maxWidth?: number;

  // path: React.ReactNode<SVGPathElement>;
  pathToFollow: React.ReactElement<SVGPathElement>;
};

export const ResizableTextPath = (props: ResizableTextProps) => {
  const {text, fontSize, fontFamily, maxWidth, pathToFollow, ...textPathProps} =
    props;

  const [textLength, setTextLength] = useState(null);
  const textRef = useRef<SVGTextPathElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.getBBox().width;

      if (textWidth > maxWidth) {
        setTextLength(maxWidth);
      } else if (textWidth <= maxWidth) {
        setTextLength(null);
      }
    }
  }, [text]);

  return (
    <g>
      {React.cloneElement(pathToFollow, {id: 'adaptive-path'})}
      <text
        fontSize={fontSize}
        fontFamily={`"${fontFamily}"`}
        style={{transformOrigin: 'center'}}
      >
        <textPath
          {...textPathProps}
          xlinkHref="#adaptive-path"
          startOffset="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          textLength={textLength}
        >
          {text}
        </textPath>
        <textPath
          {...textPathProps}
          style={{opacity: 0}}
          xlinkHref="#adaptive-path"
          ref={textRef}
          startOffset="50%"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {text}
        </textPath>
      </text>
    </g>
  );
};
