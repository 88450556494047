import React from 'react';
import './BeatsSaberWidget.css';

export class BeatsSaberWidget extends React.Component {
  render() {
    return (
      <>
        {this.props.configuration && (
          <div className={'fill-window'}>
            <img
              alt="Heart Beat"
              src="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/beat-saber-heart-gif.png"
              width="200px"
            />
            <span className={'beatSaberHeartRate'} id={'heartRate'}>
              {this.props.pulse}
            </span>
          </div>
        )}
      </>
    );
  }
}
