import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartPuzzleIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 5.171 46.421 L 9.531 42.221 C 7.951 40.711 6.871 38.931 7.371 36.761 C 7.659 35.589 8.261 34.517 9.111 33.661 C 11.301 31.501 14.111 31.871 17.311 34.581 C 18.001 33.841 18.691 33.091 19.401 32.351 L 21.481 30.241 L 24.691 33.451 C 22.558 36.516 23.019 40.686 25.771 43.211 C 28.345 45.485 32.149 45.68 34.941 43.681 L 38.071 46.831 L 33.741 51.051 C 36.621 54.651 36.741 57.391 34.311 59.741 C 32.111 61.851 29.181 61.511 26.001 58.741 L 21.821 63.071 L 5.171 46.421 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 23.271 64.641 L 26.111 61.801 C 30.251 63.701 33.311 63.451 35.651 60.991 C 37.991 58.531 38.311 55.271 36.341 51.401 L 39.491 48.251 L 43.821 52.711 C 45.431 50.921 47.321 49.961 49.591 50.551 C 50.686 50.85 51.68 51.437 52.471 52.251 C 54.261 54.161 54.671 57.501 51.661 60.181 L 55.811 64.321 L 39.381 80.751 C 34.101 75.421 28.681 70.041 23.271 64.641 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 52.781 32.081 C 49.835 30.074 45.891 30.382 43.291 32.821 C 40.73 35.314 40.39 39.31 42.491 42.201 L 39.271 45.401 L 35.041 41.091 C 33.822 42.697 31.859 43.56 29.851 43.371 C 28.628 43.248 27.492 42.678 26.661 41.771 C 24.421 39.301 24.661 36.501 27.411 33.451 L 22.981 29.201 L 26.251 25.971 C 30.191 27.811 33.481 27.581 35.731 25.261 C 38.361 22.541 38.551 19.911 36.471 15.691 L 39.591 12.611 L 43.831 17.091 C 45.321 15.441 46.881 14.541 48.831 14.671 C 50.161 14.689 51.425 15.249 52.331 16.221 C 54.061 18.151 55.031 21.221 51.641 24.581 L 53.841 26.781 C 54.561 27.501 55.301 28.201 56.011 28.891 L 52.781 32.081 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 52.971 34.831 L 57.141 30.521 L 60.391 33.761 C 59.203 35.405 58.764 37.475 59.181 39.461 C 59.492 40.978 60.298 42.35 61.471 43.361 C 64.024 45.569 67.728 45.825 70.561 43.991 L 73.561 46.931 L 57.421 63.071 L 54.491 60.131 C 56.431 56.731 56.491 53.271 53.241 50.291 C 50.911 48.121 47.541 48.071 44.111 49.891 L 40.971 46.741 L 45.341 42.431 C 43.711 40.931 42.601 39.211 43.101 37.061 C 43.391 35.891 43.984 34.818 44.821 33.951 C 46.431 32.281 50.001 31.591 52.971 34.831 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 44.211 14.021 L 41.071 11.181 C 44.969 7.784 49.906 5.816 55.071 5.601 C 61.744 5.18 68.289 7.561 73.131 12.171 L 69.641 15.551 C 71.111 17.021 72.201 18.551 71.991 20.651 C 71.848 21.94 71.249 23.135 70.301 24.021 C 68.151 26.111 65.391 25.861 61.991 23.291 L 57.701 27.621 L 54.531 24.311 C 56.531 20.941 56.481 17.541 53.421 14.561 C 50.834 12.301 47.045 12.079 44.211 14.021 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 74.451 13.591 C 82.431 22.505 82.728 35.902 75.151 45.161 L 70.951 40.871 C 69.351 42.701 67.541 43.651 65.371 43.161 C 64.247 42.933 63.208 42.396 62.371 41.611 C 60.031 39.191 60.371 36.521 63.031 33.531 L 58.791 29.311 L 62.071 26.011 C 65.701 27.851 69.221 27.611 71.451 25.241 C 73.681 22.871 74.751 19.621 72.191 15.651 L 74.451 13.591 Z"
      />
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M 38.411 11.151 L 33.911 15.601 C 35.341 17.011 36.401 18.531 36.221 20.601 C 36.09 21.92 35.478 23.144 34.501 24.041 C 32.271 26.171 29.331 25.901 26.281 23.301 L 21.991 27.671 L 18.741 24.421 C 20.741 19.991 20.301 16.621 17.451 14.421 C 14.863 12.345 11.18 12.345 8.591 14.421 L 6.931 12.361 C 15.752 4.368 29.05 3.899 38.411 11.251 L 38.411 11.151 Z"
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
