import React, {useRef} from 'react';
import {useState, useEffect} from 'react';
import {WidgetProps} from '../common';
import css from './EldenRingWidget.module.scss';

const BG_COLOR_AFTER_TRIGGER = '#130D01';
const BG_COLOR_BEFORE_TRIGGER = '#011213';
const SIGN_COLOR_BEFORE_TRIGGER = '#3F6367';
const SIGN_COLOR_AFTER_TRIGGER = '#5B3A12';
const SIGN_OPACITY_BEFORE_TRIGGER = '0.16';
const SIGN_OPACITY_AFTER_TRIGGER = '0.3';
const FLAME_GIF_LINK =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/elden-ring-fire-transparent.png';
const SHADOW_TEXT_COLOR_AFTER_TRIGGER = '#FFAB7B';
const SHADOW_TEXT_COLOR_BEFORE_TRIGGER = '#F4E6BB';
const LIGHT_COLOR_AFTER_TRIGGER_GRADIENT = '#FF7D33';
const DARK_COLOR_AFTER_TRIGGER_GRADIENT = '#4E1003';
const LIGHT_COLOR_BEFORE_TIRGGER_GRADIENT = '#C2B38A';
const DARK_COLOR_BEFORE_TRIGGER_GRADIENT = '#58461D';
const ANIMATION_DURATION = '0.5s';

export type EldenRingWidgetProps = WidgetProps<
  {},
  {
    triggerHeartRate: number;
  }
>;

export const EldenRingWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: EldenRingWidgetProps) => {
  const shouldShowEmptyState = emptyStateComponent && !pulse;
  const [showAfterTriggerState, setShowAfterTriggerState] = useState(false);
  const fadeInRef = useRef(null);
  const fadeOutRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (showAfterTriggerState && fadeInRef.current) {
      fadeInRef.current.beginElement();
    } else if (!showAfterTriggerState && fadeOutRef.current) {
      fadeOutRef.current.beginElement();
    }
  }, [showAfterTriggerState]);

  useEffect(() => {
    if (pulse >= configuration.triggerHeartRate) {
      setShowAfterTriggerState(true);
    } else {
      setShowAfterTriggerState(false);
    }
  }, [pulse]);

  return (
    <div style={{height: '100%'}}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 252 288"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H251V287H1V1Z"
          fill={
            showAfterTriggerState
              ? BG_COLOR_AFTER_TRIGGER
              : BG_COLOR_BEFORE_TRIGGER
          }
          fill-opacity="0.9"
          stroke={
            showAfterTriggerState
              ? 'url(#stroke_after_trigger_gradient)'
              : 'url(#stroke_before_trigger_gradient)'
          }
          stroke-width="2"
        />
        <path
          d="M194.263 161.29L194.266 161.288C200.209 155.384 206.13 149.502 211.997 143.675C199.791 131.534 187.6 119.404 175.453 107.319C163.394 95.3218 151.38 83.368 139.437 71.4893C136.955 73.8955 134.351 76.4262 131.507 79.1903L129.347 81.2896C150.282 102.053 171.223 122.822 192.669 144.093C187.412 149.195 182.143 154.306 176.955 159.338L176.947 159.346C173.754 162.444 170.591 165.512 167.48 168.53C170.894 172.059 174.035 175.304 177.035 178.403C182.78 172.698 188.532 166.984 194.263 161.29Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <path
          d="M176.49 103.287C173.727 100.545 170.575 97.4155 166.941 93.8077C168.182 92.6098 169.431 91.4189 170.68 90.228C173.42 87.6156 176.16 85.0026 178.815 82.3124C185.618 75.4116 186.513 65.3207 181.139 57.6754C173.855 47.3181 159.839 46.3011 150.796 55.3448C139.341 66.8058 127.843 78.2275 116.346 89.6485L116.335 89.6591C112.199 93.7675 108.064 97.8758 103.93 101.986C103.457 102.457 102.957 102.907 102.46 103.355L102.459 103.356L102.457 103.358L102.455 103.36C102.282 103.515 102.11 103.671 101.94 103.826L100.193 102.05C97.5034 99.3145 94.8137 96.5793 91.9717 93.6927C93.7888 91.8988 95.6256 90.0876 97.4751 88.264L97.4789 88.2603C101.438 84.3563 105.456 80.395 109.462 76.4225C112.881 73.0279 116.285 69.6182 119.689 66.2087C126.859 59.027 134.029 51.8453 141.333 44.806C150.875 35.617 162.402 32.8325 175.035 36.6763C187.341 40.4234 195.07 48.9646 197.918 61.495C200.212 71.6101 198.277 81.1623 191.589 89.0861C188.559 92.6716 184.982 95.7937 181.363 98.9526C179.734 100.375 178.096 101.804 176.496 103.287H176.49Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <path
          d="M124.841 56.7618C121.744 59.8308 118.53 63.0148 114.848 66.665C113.693 65.4686 112.543 64.2564 111.39 63.0406C108.692 60.1967 105.975 57.3334 103.127 54.6068C96.7009 48.4505 87.5601 47.4154 80.1234 51.7435C68.9318 58.263 66.674 72.6578 75.7234 82.0042C83.2449 89.772 90.9437 97.3731 98.6438 104.975L98.6463 104.978L98.6488 104.98C101.526 107.821 104.403 110.662 107.272 113.512C111.438 117.656 115.622 121.782 119.837 125.938C121.637 127.714 123.443 129.494 125.255 131.284C121.725 134.535 118.348 137.646 114.781 140.939C114.946 141.073 114.544 140.812 114.216 140.485C109.795 136.088 105.369 131.696 100.944 127.303L100.941 127.3C89.4362 115.881 77.9317 104.462 66.5097 92.9607C54.0096 80.3758 52.5247 62.7606 62.5296 48.8743C73.7943 33.2445 96.853 30.3692 111.44 43.057C114.728 45.916 117.704 49.1259 120.701 52.3597C122.07 53.8365 123.443 55.3183 124.853 56.7739L124.841 56.7618Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <path
          d="M157.525 187.662C155.943 186.114 154.35 184.554 152.677 182.919C151.289 184.331 149.9 185.75 148.509 187.17L148.468 187.212C145.235 190.514 141.991 193.827 138.711 197.102C129.62 206.178 120.51 215.231 111.4 224.286L111.385 224.3L111.347 224.338C108.856 226.813 106.366 229.288 103.876 231.763C99.2206 236.394 93.634 238.719 87.0675 237.683C79.2048 236.443 73.6668 231.933 71.1716 224.402C68.6704 216.848 70.5691 210.074 76.2045 204.426C78.8605 201.768 81.5677 199.157 84.1967 196.621L84.1986 196.619C85.3038 195.554 86.3952 194.501 87.4631 193.464C84.6449 190.617 82.0194 187.97 79.5099 185.44L77.5981 183.512C76.2544 184.81 74.8772 186.091 73.4947 187.377C70.3941 190.261 67.2674 193.17 64.4348 196.339C56.8641 204.814 54.3081 214.893 56.925 225.904C59.9496 238.616 67.9827 247.133 80.6714 250.613C93.1897 254.052 104.4 251.073 113.686 241.963C121.67 234.137 129.585 226.241 137.501 218.345L137.502 218.344L137.517 218.329L137.522 218.324C141.698 214.159 145.874 209.993 150.061 205.837C153.036 202.886 156.032 199.955 159.048 197.005L159.054 196.998L159.057 196.996L159.063 196.99C160.391 195.69 161.723 194.387 163.06 193.076C161.162 191.221 159.351 189.449 157.525 187.662Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <path
          d="M129.338 155.715C130.792 154.292 132.194 152.918 133.599 151.541C135.617 149.562 137.643 147.577 139.842 145.425C140.623 146.254 141.399 147.092 142.178 147.931L142.179 147.933C143.906 149.796 145.642 151.669 147.45 153.476C153.041 159.059 158.645 164.628 164.25 170.196L164.254 170.2L164.258 170.204L164.264 170.21C172.576 178.468 180.888 186.726 189.155 195.032C202.111 208.053 201.917 229.366 188.857 242.181C175.669 255.123 154.521 255.196 141.413 242.357C138.654 239.655 135.878 236.974 133.123 234.313C131.915 233.147 130.711 231.984 129.515 230.825C133.063 227.363 136.294 224.209 140.122 220.468C141.174 221.567 142.223 222.712 143.287 223.872C145.844 226.661 148.482 229.539 151.417 232.078C154.071 234.373 157.424 236.37 160.795 237.248C169.37 239.482 177.982 235.335 182.132 227.708C186.386 219.887 185.126 211.019 178.718 204.626C164.403 190.349 150.062 176.102 135.72 161.852L131.809 157.967C131.323 157.484 130.806 157.026 130.213 156.501L130.212 156.499L130.21 156.498C129.935 156.255 129.644 155.997 129.332 155.715H129.338Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <path
          d="M118.927 212.401L118.904 212.424C117.767 213.52 116.67 214.577 115.602 215.607L43 143.373C48.8643 137.552 54.8189 131.64 60.799 125.703L60.8048 125.697L61.0005 125.503C66.5773 119.966 72.1757 114.407 77.7433 108.881C79.211 110.362 80.7617 111.925 82.3791 113.555L82.4021 113.578C83.9362 115.124 85.5302 116.73 87.1701 118.385C81.2764 124.089 75.1853 129.984 69.0305 135.94L61.9448 142.798L79.5406 160.236C95.0517 175.607 110.327 190.745 125.638 205.922V205.928C123.218 208.265 121.005 210.398 118.927 212.401Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <path
          d="M161.623 117.158C158.908 114.294 156.048 111.276 153.298 108.378L92.2885 169.081C95.3678 172.162 98.5628 175.364 101.533 178.342L162.286 117.857L161.623 117.158Z"
          fill={
            showAfterTriggerState
              ? SIGN_COLOR_AFTER_TRIGGER
              : SIGN_COLOR_BEFORE_TRIGGER
          }
          fill-opacity={
            showAfterTriggerState
              ? SIGN_OPACITY_AFTER_TRIGGER
              : SIGN_OPACITY_BEFORE_TRIGGER
          }
        />
        <image
          x="0"
          y="0"
          height="300"
          href={FLAME_GIF_LINK}
          style={{opacity: showAfterTriggerState ? 1 : 0}}
        >
          <animate
            ref={fadeInRef}
            attributeName="opacity"
            from="0"
            to="1"
            dur={ANIMATION_DURATION}
            fill="freeze"
            begin="indefinite"
          />
          <animate
            ref={fadeOutRef}
            attributeName="opacity"
            from="1"
            to="0"
            dur={ANIMATION_DURATION}
            fill="freeze"
            begin="indefinite"
          />
        </image>
        <g filter="url(#b_letter_filter)">
          <path
            d="M30.0547 45.5547C32.099 45.5547 33.8112 45.1576 35.1914 44.3633C36.5716 43.569 37.2617 42.0456 37.2617 39.793C37.2617 37.5534 36.4544 36.0234 34.8398 35.2031C33.2253 34.3828 31.168 33.9727 28.668 33.9727C28.056 33.9727 27.6654 33.9727 27.4961 33.9727C27.3398 33.9727 27.0924 33.9661 26.7539 33.9531V43.9141C26.7539 44.6042 26.9492 45.0534 27.3398 45.2617C27.7305 45.457 28.6354 45.5547 30.0547 45.5547ZM18.8438 46.2773C20.4583 46.1862 21.5 45.9388 21.9688 45.5352C22.4505 45.1185 22.6914 44.1615 22.6914 42.6641V24.8711C22.6914 23.3997 22.4505 22.4557 21.9688 22.0391C21.5 21.6094 20.4583 21.3555 18.8438 21.2773V20.5352H30.0547C33.2578 20.5352 35.7969 21.0495 37.6719 22.0781C39.5599 23.1068 40.5039 24.8776 40.5039 27.3906C40.5039 29.2786 39.8464 30.7109 38.5312 31.6875C37.7891 32.2474 36.6758 32.7096 35.1914 33.0742C37.0273 33.5169 38.5964 34.2591 39.8984 35.3008C41.2005 36.3294 41.8516 37.7747 41.8516 39.6367C41.8516 42.7617 40.4062 44.9102 37.5156 46.082C35.9922 46.694 34.1172 47 31.8906 47H18.8438V46.2773ZM30.5625 32.3711C31.9036 32.3451 33.0169 32.1302 33.9023 31.7266C35.5951 30.9453 36.4414 29.5456 36.4414 27.5273C36.4414 25.1445 35.4648 23.5365 33.5117 22.7031C32.431 22.2474 30.7904 22.0195 28.5898 22.0195C27.7565 22.0195 27.2422 22.0911 27.0469 22.2344C26.8516 22.3776 26.7539 22.7682 26.7539 23.4062V32.4297L30.5625 32.3711Z"
            fill="url(#b_letter_gradient)"
          />
        </g>
        <g filter="url(#pm_letters_filter)">
          <path
            d="M194.379 246.801C194.379 244.353 193.324 242.673 191.215 241.762C190.082 241.267 188.65 241.02 186.918 241.02C186.098 241.02 185.59 241.104 185.395 241.273C185.212 241.43 185.121 241.788 185.121 242.348V252.777C185.837 252.842 186.306 252.882 186.527 252.895C186.749 252.908 186.964 252.914 187.172 252.914C189.008 252.914 190.453 252.654 191.508 252.133C193.422 251.182 194.379 249.405 194.379 246.801ZM177.719 265.277C179.242 265.134 180.193 264.835 180.57 264.379C180.948 263.91 181.137 262.849 181.137 261.195V243.871C181.137 242.478 180.922 241.56 180.492 241.117C180.076 240.674 179.151 240.395 177.719 240.277V239.535H188.266C191.651 239.535 194.242 240.193 196.039 241.508C197.836 242.823 198.734 244.555 198.734 246.703C198.734 249.424 197.738 251.397 195.746 252.621C193.754 253.845 191.26 254.457 188.266 254.457C187.836 254.457 187.283 254.451 186.605 254.438C185.941 254.411 185.447 254.398 185.121 254.398V261.664C185.121 263.174 185.349 264.125 185.805 264.516C186.273 264.906 187.309 265.16 188.91 265.277V266H177.719V265.277ZM199.789 265.277C201.443 265.108 202.504 264.737 202.973 264.164C203.441 263.578 203.676 262.23 203.676 260.121V243.871C203.676 242.426 203.448 241.488 202.992 241.059C202.536 240.616 201.501 240.355 199.887 240.277V239.535H207.797L217.055 259.711L225.902 239.535H233.871V240.277C232.387 240.382 231.43 240.655 231 241.098C230.57 241.527 230.355 242.452 230.355 243.871V261.664C230.355 263.122 230.57 264.06 231 264.477C231.43 264.88 232.387 265.147 233.871 265.277V266H222.66V265.277C224.275 265.147 225.277 264.861 225.668 264.418C226.072 263.962 226.273 262.888 226.273 261.195V243.227L216.039 266H215.492L205.434 244.164V260.121C205.434 262.309 205.753 263.734 206.391 264.398C206.807 264.828 207.738 265.121 209.184 265.277V266H199.789V265.277Z"
            fill="url(#pm_letters-gradient)"
          />
        </g>

        {shouldShowEmptyState ? (
          emptyStateComponent
        ) : (
          <>
            <text
              x="50.2%"
              y="55%"
              className={css.pulse}
              dominantBaseline="middle"
              textAnchor="middle"
              fill={
                showAfterTriggerState
                  ? SHADOW_TEXT_COLOR_AFTER_TRIGGER
                  : SHADOW_TEXT_COLOR_BEFORE_TRIGGER
              }
            >
              {pulse}
            </text>
            <text
              x="50.4%"
              y="55%"
              className={css.pulse}
              dominantBaseline="middle"
              textAnchor="middle"
              fill={
                showAfterTriggerState
                  ? SHADOW_TEXT_COLOR_AFTER_TRIGGER
                  : SHADOW_TEXT_COLOR_BEFORE_TRIGGER
              }
            >
              {pulse}
            </text>
            <text
              x="50.6%"
              y="55%"
              className={css.pulse}
              dominantBaseline="middle"
              textAnchor="middle"
              fill={
                showAfterTriggerState
                  ? SHADOW_TEXT_COLOR_AFTER_TRIGGER
                  : SHADOW_TEXT_COLOR_BEFORE_TRIGGER
              }
            >
              {pulse}
            </text>
            <text
              x="50.8%"
              y="55%"
              className={css.pulse}
              dominantBaseline="middle"
              textAnchor="middle"
              fill={
                showAfterTriggerState
                  ? SHADOW_TEXT_COLOR_AFTER_TRIGGER
                  : SHADOW_TEXT_COLOR_BEFORE_TRIGGER
              }
            >
              {pulse}
            </text>
            <text
              x="50.9%"
              y="55%"
              className={css.pulse}
              dominantBaseline="middle"
              textAnchor="middle"
              fill={
                showAfterTriggerState
                  ? SHADOW_TEXT_COLOR_AFTER_TRIGGER
                  : SHADOW_TEXT_COLOR_BEFORE_TRIGGER
              }
            >
              {pulse}
            </text>
            <text
              x="51%"
              y="55%"
              className={css.pulse}
              dominantBaseline="middle"
              textAnchor="middle"
              fill="url(#pulse_text_gradient)"
            >
              {pulse}
            </text>
          </>
        )}

        <defs>
          <filter
            id="b_letter_filter"
            x="14.8438"
            y="20.5352"
            width="31.0078"
            height="34.4648"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2104_217"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2104_217"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values={
                showAfterTriggerState
                  ? '0 0 0 0 1 0 0 0 0 0.670588 0 0 0 0 0.482353 0 0 0 1 0'
                  : '0 0 0 0 0.956863 0 0 0 0 0.901961 0 0 0 0 0.733333 0 0 0 1 0'
              }
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_2104_217"
            />
          </filter>
          <filter
            id="pm_letters_filter"
            x="173.719"
            y="239.535"
            width="64.1562"
            height="34.4648"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2104_217"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2104_217"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values={
                showAfterTriggerState
                  ? '0 0 0 0 1 0 0 0 0 0.670588 0 0 0 0 0.482353 0 0 0 1 0'
                  : '0 0 0 0 0.956863 0 0 0 0 0.901961 0 0 0 0 0.733333 0 0 0 1 0'
              }
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_2104_217"
            />
          </filter>

          <linearGradient
            id="stroke_before_trigger_gradient"
            x1="81.48"
            y1="-4.08239e-06"
            x2="179.32"
            y2="280.687"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color={SHADOW_TEXT_COLOR_BEFORE_TRIGGER} />
            <stop offset="0.2" stop-color="#3E2F09" />
            <stop offset="0.4" stop-color="#6A5721" />
            <stop offset="0.6" stop-color="#6F5B27" />
            <stop offset="0.8" stop-color="#3E2F09" />
            <stop offset="1" stop-color="#95875B" />
          </linearGradient>
          <linearGradient
            id="stroke_after_trigger_gradient"
            x1="81.48"
            y1="-4.08239e-06"
            x2="179.32"
            y2="280.687"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F5D4BC" />
            <stop offset="0.2" stop-color="#3E1D09" />
            <stop offset="0.4" stop-color="#6A3E21" />
            <stop offset="0.6" stop-color="#6F4327" />
            <stop offset="0.8" stop-color="#3E1D09" />
            <stop offset="1" stop-color="#95735B" />
          </linearGradient>
          <linearGradient
            id="pulse_text_gradient"
            gradientTransform="rotate(90)"
          >
            <stop
              stop-color={
                showAfterTriggerState
                  ? LIGHT_COLOR_AFTER_TRIGGER_GRADIENT
                  : LIGHT_COLOR_BEFORE_TIRGGER_GRADIENT
              }
            />
            <stop
              offset="1"
              stop-color={
                showAfterTriggerState
                  ? DARK_COLOR_AFTER_TRIGGER_GRADIENT
                  : DARK_COLOR_BEFORE_TRIGGER_GRADIENT
              }
            />
          </linearGradient>
          <linearGradient
            id="b_letter_gradient"
            x1="31.5"
            y1="11"
            x2="31.5"
            y2="57"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              stop-color={
                showAfterTriggerState
                  ? LIGHT_COLOR_AFTER_TRIGGER_GRADIENT
                  : LIGHT_COLOR_BEFORE_TIRGGER_GRADIENT
              }
            />
            <stop
              offset="1"
              stop-color={
                showAfterTriggerState
                  ? DARK_COLOR_AFTER_TRIGGER_GRADIENT
                  : DARK_COLOR_BEFORE_TRIGGER_GRADIENT
              }
            />
          </linearGradient>
          <linearGradient
            id="pm_letters-gradient"
            x1="206"
            y1="230"
            x2="206"
            y2="276"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              stop-color={
                showAfterTriggerState
                  ? LIGHT_COLOR_AFTER_TRIGGER_GRADIENT
                  : LIGHT_COLOR_BEFORE_TIRGGER_GRADIENT
              }
            />
            <stop
              offset="1"
              stop-color={
                showAfterTriggerState
                  ? DARK_COLOR_AFTER_TRIGGER_GRADIENT
                  : DARK_COLOR_BEFORE_TRIGGER_GRADIENT
              }
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
