import React, {useEffect, useRef, useState} from 'react';
import {WidgetProps} from '../common';
import {loadCustumFont} from '../../utils/fontUtils';
import {srirachaFont} from '../../controls/FontPicker/FontPicker.constants';
import {findRangeForPulse} from '../../utils/rangeUtils';
import {useRandomId} from '../../hooks/useRandomId';
import {FontOption} from '../../controls/FontPicker/FontPicker';

export type AnimeBarWidgetProps = WidgetProps<
  {
    image?: string;
  },
  {
    min?: number;
    max?: number;
    bpmText?: {font?: FontOption};
    noImage?: boolean;
  }
>;

const BOTTOM_HEART_IMG_URL =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/heart-back.png';

const DEFAULT_RANGE_TO_IMAGE = [
  {
    from: 0,
    to: 75,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-1.png',
  },
  {
    from: 76,
    to: 85,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-2.png',
  },
  {
    from: 86,
    to: 95,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-3.png',
  },
  {
    from: 96,
    to: 100,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-4.png',
  },
  {
    from: 101,
    to: 110,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-5.png',
  },
  {
    from: 111,
    to: 120,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-6.png',
  },
  {
    from: 121,
    to: 999,
    image:
      'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/anime-7.png',
  },
];

export const AnimeBarWidget = ({pulse, configuration}: AnimeBarWidgetProps) => {
  const {
    min,
    max,
    bpmText: {font = srirachaFont} = {},
    ranges = DEFAULT_RANGE_TO_IMAGE,
    noImage,
  } = configuration;

  const pulseTextRef = useRef<SVGTextElement>(null);
  const [shouldShrinkText, setShouldShrinkText] = useState(false);

  // Caclulate if pulse text length is too wide and should be shrinked
  useEffect(() => {
    if (pulseTextRef.current && !shouldShrinkText) {
      const textLength = pulseTextRef.current.getComputedTextLength();
      const fontSize = Number(pulseTextRef.current.getAttribute('font-size'));
      if (textLength > 140) {
        setShouldShrinkText(true);
      } else if (textLength < 140 && fontSize < 80) {
        setShouldShrinkText(false);
      }
    }
  }, [pulseTextRef.current, shouldShrinkText, pulse, font.name]);

  // Reset shrink for every update so the useEffect above can calculate again
  useEffect(() => {
    if (pulseTextRef.current && shouldShrinkText) {
      setShouldShrinkText(false);
    }
  }, [font.name, pulse]);

  useEffect(() => {
    loadCustumFont(font);
  }, []);

  const range = findRangeForPulse(ranges || DEFAULT_RANGE_TO_IMAGE, pulse);
  const [barFillOffset, setBarFillOffset] = useState(0);
  const currentBarFillPercent = Math.min(
    100,
    Math.max(0, ((pulse - min) / (max - min)) * 100)
  );
  const svgId = {
    barFill: useRandomId('animeBarFillColor'),
  };

  useEffect(() => {
    // set bar fill offset gradually if it changes
    const interval = setInterval(() => {
      setBarFillOffset((prev) => {
        const prevVal = isFinite(prev) ? prev : 0;
        const diff = currentBarFillPercent - prevVal;
        if (Math.abs(diff) < 0.4) {
          clearInterval(interval);
          return currentBarFillPercent;
        }

        return prevVal + diff * 0.1;
      });
    }, 30);

    return () => clearInterval(interval);
  }, [currentBarFillPercent]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 700"
      width="100%"
      height="100%"
    >
      <defs>
        <linearGradient
          id="anime-bar-widget-bg-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#fb84d3" />
          <stop offset="5%" stopColor="#fb84d3" />
          <stop offset="20%" stopColor="#848cff" />
          <stop offset="40%" stopColor="#85effd" />
          <stop offset="60%" stopColor="#93ff84" />
          <stop offset="80%" stopColor="#fff189" />
          <stop offset="100%" stopColor="#fff189" />
        </linearGradient>

        <clipPath id="anime-bar-widget-shape">
          <ellipse id="top-ellipse" cx="50" cy="90" rx="50" ry="50" />
          <rect id="base-rect" x="0" y="100" width="100" height="500" />
        </clipPath>
      </defs>

      <rect
        y="50"
        width="100%"
        height="550"
        fill="url(#anime-bar-widget-bg-gradient)"
        clip-path="url(#anime-bar-widget-shape)"
      />

      {/* empty space over gradient layer */}
      <rect
        y="50"
        width="100%"
        height={((100 - barFillOffset) / 100) * 490}
        fill="#3c306a"
        clip-path="url(#anime-bar-widget-shape)"
      />

      {/* Sparkles background */}
      {barFillOffset >= 100 && (
        <image
          x="0"
          y="40"
          width="100"
          height="500"
          href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/sparkle-gif.png"
        />
      )}

      {/* overlay background */}
      <image
        x="0"
        y="80"
        width="100"
        height="500"
        style={{
          transformOrigin: 'center',
          transform: 'scale(1.2)',
        }}
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/bar-cover.png"
      />

      {/* heart and wings images on the top */}
      <image
        x="-10"
        y="16"
        width="50"
        height="50"
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/wing-top-left.png"
      />
      <image
        x="60"
        y="16"
        width="50"
        height="50"
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/wing-top-right.png"
      />
      <image
        x="25"
        y="16"
        width="50"
        height="50"
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/small-heart-top.png"
      />

      {/* Bottom heart */}
      <image
        x="-50"
        y="500"
        width="200"
        height="200"
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/anime-bar-by-YoshinoArt/heart-border.png"
      />

      <image
        x="-39"
        y="511"
        width="178"
        height="178"
        href={noImage || !range.image ? BOTTOM_HEART_IMG_URL : range.image}
      />

      <text
        ref={pulseTextRef}
        x="50"
        y="630"
        width="178"
        height="178"
        textAnchor="middle"
        startOffset="50%"
        fontSize={80}
        fill="#3c306a"
        stroke="#fff"
        strokeWidth={2}
        fontFamily={font.name}
        textLength={shouldShrinkText ? 140 : null}
        lengthAdjust="spacingAndGlyphs"
      >
        {pulse}
      </text>
    </svg>
  );
};
