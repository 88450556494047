import React, {useEffect, useRef} from 'react';
import {WidgetProps} from '../common';
import {Prediction, PredictionClient} from './PulsoidClient';
import css from './PredictionWidget.module.scss';
import {getOutcomeIdByTitle} from './PulsoidClient';

export type PredictionWidgetProps = WidgetProps<
  {},
  {
    triggerBPM: number;
    token: string;
  }
>;

type Mode =
  | 'heart-rate'
  | 'prediction-threshold'
  | 'prediction'
  | 'result-reveal';

const PREDICTION_THRESHOLD_DURATION = 60000;
const PREDICTION_DURATION = 30000;
const INSERTS_ANIMATION_DURATION = 3000;
const RESULT_REVEAL_DURATION = 5000;
const COOLDOWN_DURATION = 900000; // 15 minutes

const HeartRateBackground =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/prediction-widget/heart-rate-background.svg';

const GuessBpm =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/prediction-widget/guess-bpm.svg';

const PredictionBackground =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/prediction-widget/prediction-background.svg';

const GameOver =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/prediction-widget/game-over.svg';

const AnswerBackground =
  'https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/prediction-widget/answer-background.svg';

let client = new PredictionClient();

export const PredictionWidget = ({
  pulse,
  configuration,
}: PredictionWidgetProps) => {
  const [status, setStatus] = React.useState<Mode>('heart-rate');
  const [maxBPM, setMaxBPM] = React.useState<number | null>(null);
  const [prediction, setPrediction] = React.useState<Prediction | null>(null);
  const [timeLeft, setTimeLeft] = React.useState<number>();
  const [showGuessBPM, setShowGuessBPM] = React.useState(false);
  const [showGameOver, setShowGameOver] = React.useState(false);
  const [isCooldown, setIsCooldown] = React.useState<boolean>(false);

  useEffect(() => {
    if (
      status == 'heart-rate' &&
      pulse > configuration.triggerBPM &&
      !isCooldown
    ) {
      setStatus('prediction-threshold');
      setTimeLeft(PREDICTION_THRESHOLD_DURATION + PREDICTION_DURATION);
      setIsCooldown(true);
      setTimeout(() => {
        setIsCooldown(false);
      }, COOLDOWN_DURATION);
    }
    if (status == 'prediction-threshold') {
      setMaxBPM(Math.max(pulse, maxBPM ?? 0));
    }
  }, [pulse]);

  useEffect(() => {
    if (status === 'prediction-threshold') {
      setShowGuessBPM(true);
      setTimeout(() => {
        setShowGuessBPM(false);
      }, INSERTS_ANIMATION_DURATION);
      setTimeout(() => {
        setStatus('prediction');
      }, PREDICTION_THRESHOLD_DURATION);
    }
    if (status == 'prediction') {
      //alert(`Guess the heart rate (${generatePossibleOutcomes(maxBPM == null ? pulse : maxBPM)})`);
      setMaxBPM(maxBPM == null ? pulse : maxBPM);
      const possibleOutcomes = generatePossibleOutcomes(
        maxBPM == null ? pulse : maxBPM
      );

      if (configuration.token && configuration.token.length > 0) {
        client
          .startPrediction(
            configuration.token,
            'Guess the maximum heart rate',
            possibleOutcomes,
            Math.floor(PREDICTION_DURATION / 1000)
          )
          .then((prediction) => {
            console.log('prediction setted', prediction);
            setPrediction(prediction);
          });
      }
      setTimeout(() => {
        setStatus('result-reveal');
      }, PREDICTION_DURATION + 1000);
    }
    if (status === 'result-reveal') {
      console.log('result reveal');

      console.log('configuration.token', configuration.token);
      console.log('prediction', prediction);
      if (
        configuration.token &&
        configuration.token.length > 0 &&
        prediction &&
        prediction.predictionId
      ) {
        let bpm = maxBPM!;
        let winningOutcomeId = getOutcomeIdByTitle(prediction, bpm.toString()); // should be calculated based on the actual result

        console.log('winningOutcomeId', winningOutcomeId);
        client
          .resolvePrediction(
            configuration.token,
            prediction.predictionId,
            winningOutcomeId
          )
          .finally(() => {
            console.log('resolved');
            setPrediction(null);
          });
      }

      setTimeout(() => {
        setShowGameOver(true);
        setTimeout(() => {
          setShowGameOver(false);
          setStatus('heart-rate');
        }, INSERTS_ANIMATION_DURATION);
      }, RESULT_REVEAL_DURATION);
    }
    if (status === 'heart-rate') {
      setMaxBPM(null);
    }
  }, [status]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1000);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  useEffect(() => {
    let images = [
      HeartRateBackground,
      GuessBpm,
      PredictionBackground,
      GameOver,
      AnswerBackground,
    ];

    // Preload images
    images.forEach((src: string) => {
      new Image().src = src;
    });
  }, []);

  const generatePossibleOutcomes = (num: number): string[] => {
    const result: Set<number> = new Set();
    const minRange = num - 10;
    const maxRange = num + 10;

    result.add(num);

    while (result.size < 4) {
      const randomNum =
        Math.floor(Math.random() * (maxRange - minRange + 1)) + minRange;
      const isValid = Array.from(result).every(
        (n) => Math.abs(n - randomNum) >= 3
      );
      if (isValid) {
        result.add(randomNum);
      }
    }

    return Array.from(result)
      .map((n) => n.toString())
      .sort(() => Math.random() - 0.5);
  };

  const minutes = Math.floor(timeLeft / (60 * 1000));
  const seconds = Math.floor((timeLeft % (60 * 1000)) / 1000);
  const milliseconds = Math.floor((timeLeft % 1000) / 100);

  let result = null;

  if (status === 'heart-rate') {
    result = (
      <>
        <img
          src={HeartRateBackground}
          style={{display: 'block', width: '100%', height: '100%'}}
        />
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 280 280"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <text
            x="50%"
            y="61%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="url(#heart-rate-gradient)"
            className={css.pulse}
            filter="url(#heart-rate-shadow)"
          >
            {pulse}
          </text>
          <defs>
            <filter id="heart-rate-shadow" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2586_180"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2586_180"
                result="shape"
              />
            </filter>
            <linearGradient
              id="heart-rate-gradient"
              x1="0%"
              x2="0%"
              y1="0%"
              y2="100%"
            >
              <stop stopColor="#FE9213" />
              <stop offset="0.5" stopColor="#FFE539" />
              <stop offset="1" stopColor="#FFE539" />
            </linearGradient>
          </defs>
        </svg>
      </>
    );
  }

  if (
    (status === 'prediction-threshold' || status === 'prediction') &&
    showGuessBPM
  ) {
    result = (
      <img
        src={GuessBpm}
        style={{
          display: showGuessBPM ? 'block' : 'none',
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  if (
    (status === 'prediction-threshold' || status === 'prediction') &&
    !showGuessBPM
  ) {
    result = (
      <>
        <img
          src={PredictionBackground}
          style={{display: 'block', width: '100%', height: '100%'}}
        />

        <svg
          width="100%"
          height="100%"
          viewBox="0 0 275 240"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            className={css.question_mark_animation_a}
            filter="url(#question_mark_shadow)"
          >
            <path
              d="M121.939 142.336V124.336C126.547 120.304 130.723 116.224 134.467 112.096C138.307 107.872 141.331 103.408 143.539 98.704C145.747 94 146.851 88.96 146.851 83.584C146.851 79.264 145.795 75.808 143.683 73.216C141.667 70.624 138.643 69.328 134.611 69.328C131.251 69.328 128.515 70.336 126.403 72.352C124.387 74.272 123.379 76.816 123.379 79.984C123.379 81.328 123.475 82.576 123.667 83.728C123.955 84.88 124.291 86.128 124.675 87.472L108.547 90.784C107.971 89.056 107.491 87.328 107.107 85.6C106.819 83.872 106.675 82.192 106.675 80.56C106.675 72.592 109.123 66.208 114.019 61.408C118.915 56.608 125.827 54.208 134.755 54.208C144.067 54.208 151.267 56.8 156.355 61.984C161.539 67.072 164.131 74.32 164.131 83.728C164.131 89.968 162.979 95.68 160.675 100.864C158.371 106.048 155.203 110.896 151.171 115.408C147.139 119.824 142.579 124.144 137.491 128.368V142.336H121.939ZM121.219 172V154.432H138.499V172H121.219Z"
              fill="#222222"
            />
          </g>
          <g
            className={css.question_mark_animation_b}
            filter="url(#question_mark_shadow)"
          >
            <path
              d="M58.6609 165.493L54.0022 148.107C57.4096 143.019 60.3873 137.998 62.9353 133.041C65.5512 127.967 67.3168 122.873 68.2321 117.757C69.1474 112.642 68.9093 107.488 67.5179 102.295C66.3998 98.1227 64.4853 95.0577 61.7744 93.1007C59.1563 91.1188 55.8999 90.6496 52.0052 91.6932C48.7597 92.5628 46.3779 94.2446 44.8596 96.7385C43.4092 99.1149 43.094 101.833 43.9139 104.893C44.2618 106.191 44.6775 107.372 45.1612 108.435C45.7375 109.473 46.3851 110.592 47.1038 111.791L32.3826 119.164C31.379 117.644 30.4681 116.099 29.6499 114.529C28.9245 112.935 28.3506 111.349 27.9282 109.773C25.8659 102.076 26.5782 95.2763 30.0651 89.3727C33.5519 83.469 39.6072 79.3619 48.231 77.0511C57.2257 74.641 64.8512 75.2812 71.1076 78.9717C77.4318 82.5446 81.8114 88.8747 84.2464 97.9622C85.8614 103.99 86.227 109.805 85.3433 115.409C84.4595 121.012 82.6542 126.515 79.9274 131.917C77.1757 137.226 73.8892 142.579 70.0678 147.976L73.683 161.468L58.6609 165.493ZM65.643 194.333L61.0961 177.363L77.7873 172.891L82.3342 189.86L65.643 194.333Z"
              fill="#222222"
            />
          </g>
          <g
            className={css.question_mark_animation_c}
            filter="url(#question_mark_shadow)"
          >
            <path
              d="M191.111 158.733L195.77 141.346C201.265 138.644 206.354 135.784 211.039 132.766C215.841 129.679 219.918 126.15 223.268 122.178C226.618 118.206 228.989 113.623 230.381 108.43C231.499 104.258 231.373 100.646 230.004 97.5957C228.727 94.5702 226.142 92.5357 222.247 91.4921C219.002 90.6225 216.098 90.888 213.536 92.2887C211.092 93.6215 209.46 95.8179 208.64 98.878C208.292 100.176 208.062 101.407 207.949 102.569C207.929 103.756 207.931 105.049 207.954 106.446L191.518 105.471C191.409 103.653 191.393 101.86 191.469 100.091C191.638 98.3475 191.934 96.6874 192.356 95.111C194.418 87.4145 198.435 81.8817 204.407 78.5124C210.378 75.1431 217.676 74.6139 226.3 76.9246C235.294 79.3347 241.578 83.7019 245.151 90.0261C248.842 96.2825 249.469 103.954 247.034 113.042C245.419 119.069 242.828 124.288 239.261 128.699C235.694 133.11 231.379 136.973 226.317 140.288C221.279 143.51 215.756 146.503 209.749 149.266L206.133 162.758L191.111 158.733ZM182.738 187.2L187.285 170.23L203.976 174.703L199.429 191.672L182.738 187.2Z"
              fill="#222222"
            />
          </g>
          <defs>
            <filter id="question_mark_shadow" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4638_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4638_3"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 280 382"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <text
            x="50%"
            y="13%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="url(#countdown-gradient)"
            className={css.countdown}
            filter="url(#countdown-shadow)"
          >
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </text>
          <defs>
            <filter id="countdown-shadow" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2591_276"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.29471 0 0 0 0 0.157955 0 0 0 0 0.0533779 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_2591_276"
                result="effect2_dropShadow_2591_276"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_2591_276"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="0.5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.898039 0 0 0 0 0.223529 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect3_innerShadow_2591_276"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feGaussianBlur stdDeviation="0.5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.797184 0 0 0 0 0.250862 0 0 0 0 0 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="effect3_innerShadow_2591_276"
                result="effect4_innerShadow_2591_276"
              />
            </filter>
            <linearGradient
              id="countdown-gradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop stopColor="#FE9213" />
              <stop offset="0.5" stopColor="#FFE539" />
              <stop offset="1" stopColor="#FFE539" />
            </linearGradient>
          </defs>
        </svg>
      </>
    );
  }

  if (status === 'result-reveal' && showGameOver) {
    result = (
      <img
        src={GameOver}
        style={{
          display: showGameOver ? 'block' : 'none',
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  if (status === 'result-reveal' && !showGameOver) {
    result = (
      <>
        <img
          src={AnswerBackground}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            overflow: 'visible',
          }}
        />
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 306 382"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <text
            x="51%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#003292"
            className={css.pulse}
            style={{fontSize: '182px'}}
            filter="url(#maxBPM-shadow)"
          >
            {maxBPM}
          </text>
          <defs>
            <filter id="maxBPM-shadow" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2586_180"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2586_180"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          padding: '0px 2.5%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={css.result_container}>{result}</div>
      </div>
    </>
  );
};
