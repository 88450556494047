import React from 'react';

export const StromnoWidget = ({pulse, configuration}) => {
  const {shadowSize, font, imageUrl, color} = configuration;

  return (
    <div
      style={{
        backgroundColor: 'transparent',
        fontSize: '7vh',
        fontFamily: "'" + font + "'",
        color: color,
        textShadow: shadowSize
          ? '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff'
          : undefined,
      }}
      className={'fill-window'}
      id={'heartRate'}
    >
      <link
        href={`https://fonts.googleapis.com/css?family=${font}`}
        rel={'stylesheet'}
      />
      {imageUrl && <img src={imageUrl} alt="Loading" style={{width: 80}} />}
      &nbsp;
      {pulse}
    </div>
  );
};
