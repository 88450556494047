import React from 'react';
import {WidgetProps} from '../common';
import css from './AlanWakeWidget.module.scss';
import {findRangeForPulse} from '../../utils/rangeUtils';
import classNames from 'classnames';

export type AlanWakeWidgetProps = WidgetProps<
  {
    color?: string;
  },
  {
    animatedHeart?: boolean;
    animatedActivityBar?: boolean;
  }
>;

const DEFAULT_CONFIGURATION = {
  ranges: [
    {
      from: 0,
      to: 75,
      color: '#6DAAB5',
    },
    {
      from: 76,
      to: 85,
      color: '#7CA372',
    },
    {
      from: 86,
      to: 105,
      color: '#C4C164',
    },
    {
      from: 105,
      to: 999,
      color: '#B26969',
    },
  ],
};

export const AlanWakeWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: AlanWakeWidgetProps) => {
  const {animatedHeart = false, animatedActivityBar = false} =
    configuration || {};

  const animationDuration = Math.max(Math.floor((60 / pulse) * 1000), 300);

  const cssAnimationKey = [
    animatedHeart && 'heart',
    animatedActivityBar && 'bar',
  ]
    .filter(Boolean)
    .join('-');
  const shouldShowEmptyState = emptyStateComponent && !pulse;

  const ranges = configuration?.ranges || DEFAULT_CONFIGURATION.ranges;
  const {color = '#C4C164'} = findRangeForPulse(ranges, pulse) || {};

  const activeRangeIndex = ranges?.findIndex(
    (range) => range.from <= pulse && range.to >= pulse
  );

  return (
    <svg width="100%" height="100%" viewBox="0 0 280 320">
      <foreignObject
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <div className={css.widgetContainer}>
          <div className={css.widgetHeader}>
            <div className={css.bpm}>BPM</div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              className={classNames({[css.fadePulseAnimation]: animatedHeart})}
              key={cssAnimationKey + activeRangeIndex}
              style={{animationDuration: `${animationDuration}ms`}}
            >
              <path
                d="M24 41.95L21.95 40.1C18.4167 36.8667 15.5 34.075 13.2 31.725C10.9 29.375 9.06667 27.275 7.7 25.425C6.33333 23.575 5.375 21.9 4.825 20.4C4.275 18.9 4 17.3833 4 15.85C4 12.85 5.00833 10.3417 7.025 8.325C9.04167 6.30834 11.5333 5.3 14.5 5.3C16.4 5.3 18.1583 5.75 19.775 6.65C21.3917 7.55 22.8 8.85 24 10.55C25.4 8.75 26.8833 7.425 28.45 6.575C30.0167 5.725 31.7 5.3 33.5 5.3C36.4667 5.3 38.9583 6.30834 40.975 8.325C42.9917 10.3417 44 12.85 44 15.85C44 17.3833 43.725 18.9 43.175 20.4C42.625 21.9 41.6667 23.575 40.3 25.425C38.9333 27.275 37.1 29.375 34.8 31.725C32.5 34.075 29.5833 36.8667 26.05 40.1L24 41.95Z"
                fill={color}
              />
            </svg>
          </div>

          <div className={css.pulse} style={{color}}>
            {shouldShowEmptyState ? emptyStateComponent : pulse}
          </div>

          <div className={css.activityBar}>
            {new Array(4).fill(0).map((_, index) => (
              <div
                key={index + cssAnimationKey}
                style={{
                  backgroundColor:
                    activeRangeIndex >= index ? color : '#272e2a',
                  animationDuration: `${animationDuration}ms`,
                }}
                className={classNames(css.activityBarIndicator, {
                  [css.active]: activeRangeIndex >= index,
                  [css.fadePulseAnimation]:
                    animatedActivityBar && activeRangeIndex === index,
                })}
              />
            ))}
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};
