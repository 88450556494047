import React from 'react';
import {HeartIconAnimateTransform, HeartIconProps} from './heartIcon.constants';

export const HeartNestedIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 2 : 0}
        d="M 41.524 22.025 L 42.122 21.08 C 45.878 15.002 51.244 11.323 58.343 10.69 C 69.271 9.536 79.208 17.132 81.043 28.041 C 81.949 33.829 80.956 39.757 78.216 44.926 C 75.365 50.418 71.478 55.3 66.773 59.296 C 59.302 65.925 50.728 70.725 41.637 74.684 C 41.259 74.829 40.84 74.829 40.461 74.684 C 31.04 70.486 22.156 65.447 14.655 58.174 C 9.083 52.864 4.646 46.807 2.624 39.273 C 0.663 31.959 1.231 24.924 6.06 18.69 C 9.334 14.26 14.284 11.394 19.732 10.773 C 29.111 9.661 36.241 13.422 41.163 21.475 C 41.256 21.61 41.348 21.745 41.524 22.025 Z M 61.273 25.963 C 61.046 25.631 60.871 25.361 60.664 25.101 C 58.977 22.667 55.964 21.547 53.111 22.296 C 50.368 22.779 48.211 24.93 47.705 27.688 C 46.982 31.107 48.148 34.099 50.294 36.697 C 53.103 39.978 56.672 42.512 60.685 44.074 C 60.96 44.183 61.267 44.183 61.541 44.074 C 65.232 42.627 68.576 40.411 71.354 37.569 C 73.68 35.366 75.005 32.296 75.017 29.081 C 74.844 24.293 70.201 20.979 65.658 22.399 C 63.817 23.013 62.257 24.279 61.273 25.963 Z"
      />

      {withAnimation && <HeartIconAnimateTransform />}
    </svg>
  );
};
