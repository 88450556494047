import React from 'react';

export class MaxWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      max: 0,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      max: Math.max(nextProps.pulse, this.state.max),
    });
  }

  render() {
    return (
      <>
        {this.props.configuration && (
          <div
            style={{
              backgroundColor: this.props.fromLaunch
                ? this.props.configuration.backgroundColor
                : 'transparent',
              fontSize: '60vh',
              fontFamily: this.props.configuration.font,
              color: this.props.configuration.fontColor,
            }}
          >
            <link
              href={`https://fonts.googleapis.com/css?family=${this.props.configuration.font}`}
              rel={'stylesheet'}
            />
            {this.state.max}
          </div>
        )}
      </>
    );
  }
}
