import {OneOf} from '../../types/internal';

export const LAYOUT_TYPES = {
  LEFT_RIGHT: 'left-right',
  TOP_DOWN: 'top-down',
  RIGHT_LEFT: 'right-left',
  BOTTOM_UP: 'bottom-up',
} as const;

export const layoutTypeToFlexDirectionMap: Record<
  OneOf<typeof LAYOUT_TYPES>,
  React.CSSProperties['flexDirection']
> = {
  [LAYOUT_TYPES.TOP_DOWN]: 'column',
  [LAYOUT_TYPES.LEFT_RIGHT]: 'row',
  [LAYOUT_TYPES.RIGHT_LEFT]: 'row-reverse',
  [LAYOUT_TYPES.BOTTOM_UP]: 'column-reverse',
};
