import React from 'react';
import {resolveFontUrl} from '../utils/fontUtils';
import {findColorInRange} from '../utils/rangeUtils';
import {WidgetProps} from './common';

export type DeadByDaylightWidgetProps = WidgetProps<
  {color?: string},
  {font?: string; fontUrl?: string}
>;

export const DeadByDaylightWidget = ({
  configuration,
  pulse,
}: DeadByDaylightWidgetProps) => {
  const fillColor = findColorInRange(configuration?.ranges, {
    pulse,
    defaultColor: 'green',
  });

  let fontUrl = resolveFontUrl({
    font: configuration?.font,
    fontUrl: configuration?.fontUrl,
  });

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
        rel="stylesheet"
      />
      {fontUrl ? <link href={fontUrl} rel="stylesheet" /> : <EdoFontFace />}

      <svg width="100%" height="100%" viewBox="0 0 114 114">
        <filter id="turbulence">
          <feTurbulence
            type="turbulence"
            baseFrequency="0.5 0.5"
            numOctaves="2"
            result="NOISE"
          />
          <feDisplacementMap
            in="SourceGraphic"
            in2="NOISE"
            scale="1"
            xChannelSelector="R"
          />
        </filter>
        <g filter="url(#turbulence)">
          <path
            style={{fill: fillColor, stroke: '#000000', strokeWidth: 8}}
            d="M 30 6 L 4 30 L 4 55 L 56 106 L 108 55 L 108 30 L 82 6 L 56 30 L 30 6 Z"
          />
        </g>

        <image
          href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/dbd-style/dbd-texture.png"
          x="4"
          y="4"
          width="104"
          height="104"
        />

        {/* Duplicate path stroke without fill to cover the image on borders */}
        <g filter="url(#turbulence)">
          <path
            style={{stroke: '#000000', fill: 'transparent', strokeWidth: 8}}
            d="M 30 6 L 4 30 L 4 55 L 56 106 L 108 55 L 108 30 L 82 6 L 56 30 L 30 6 Z"
          />
        </g>

        <text fill="#ffffff">
          <tspan
            fontFamily="'Edo', sans-serif"
            fontSize={36}
            x="50%"
            y="45%"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {pulse}
          </tspan>
          <tspan
            fontFamily="'Roboto', sans-serif"
            fontSize={10}
            x="50%"
            y="75%"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            BPM
          </tspan>
        </text>
      </svg>
    </>
  );
};

export const EdoFontFace = () => (
  <style>
    {`
@font-face {
  font-family: 'Edo';
  font-style: normal;
  src: url(https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/dbd-style/edo.woff2);
}
`}
  </style>
);
