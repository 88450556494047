// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G43hIhVbb_wGjYDcXNET{transform:rotate(45deg);transform-origin:180px 180px;transition:transform .5s linear}.LF3rwrtmEfwEfmmh_gql{filter:drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 2px red) drop-shadow(0px 0px 3px red)}.ga68cO0G3EZdu8lzowh5{animation:FYLcUBamt0kUojtqvd7v 1s linear infinite}@keyframes FYLcUBamt0kUojtqvd7v{0%{transform:rotate(220deg)}15%{transform:rotate(210deg)}30%{transform:rotate(220deg)}35%{transform:rotate(220deg)}40%{transform:rotate(215deg)}60%{transform:rotate(220deg)}100%{transform:rotate(220deg)}}", "",{"version":3,"sources":["webpack://./src/controls/SpeedTestArrowPicker/SpeedTestArrows/SpeedTestArrows.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CACA,4BAAA,CACA,+BAAA,CAGF,sBACE,8FAAA,CAIF,sBACE,iDAAA,CAGF,gCACE,GACE,wBAAA,CAEF,IACE,wBAAA,CAEF,IACE,wBAAA,CAEF,IACE,wBAAA,CAEF,IACE,wBAAA,CAEF,IACE,wBAAA,CAEF,KACE,wBAAA,CAAA","sourcesContent":[".arrowLine {\n  transform: rotate(45deg);\n  transform-origin: 180px 180px;\n  transition: transform 0.5s linear;\n}\n\n.arrowGlow {\n  filter: drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 2px red)\n    drop-shadow(0px 0px 3px red);\n}\n\n.arrowLineMax {\n  animation: speedMax 1s linear infinite;\n}\n\n@keyframes speedMax {\n  0% {\n    transform: rotate(220deg);\n  }\n  15% {\n    transform: rotate(210deg);\n  }\n  30% {\n    transform: rotate(220deg);\n  }\n  35% {\n    transform: rotate(220deg);\n  }\n  40% {\n    transform: rotate(215deg);\n  }\n  60% {\n    transform: rotate(220deg);\n  }\n  100% {\n    transform: rotate(220deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowLine": "G43hIhVbb_wGjYDcXNET",
	"arrowGlow": "LF3rwrtmEfwEfmmh_gql",
	"arrowLineMax": "ga68cO0G3EZdu8lzowh5",
	"speedMax": "FYLcUBamt0kUojtqvd7v"
};
export default ___CSS_LOADER_EXPORT___;
