import React, {Component} from 'react';
import './App.css';
import WidgetContainer from './WidgetContainer.js';

class App extends Component {
  render() {
    return <WidgetContainer />;
  }
}

export default App;
