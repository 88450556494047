import React from 'react';
import {WidgetProps} from '../common';
import css from './DiabloWidget.module.scss';
import {findRangeForPulse} from '../../utils/rangeUtils';
import {useRandomId} from '../../hooks/useRandomId';

const DEFAULT_HEART_COLOR = '#ff0000';

export type DiabloWidgetProps = WidgetProps<
  {
    heartColor?: string;
  },
  {
    min?: number;
    max?: number;
  }
>;

export const DiabloWidget = ({
  pulse,
  configuration,
  emptyStateComponent,
}: DiabloWidgetProps) => {
  const {min, max} = configuration;
  const currentHeartFillPercent = Math.min(
    100,
    Math.max(0, ((pulse - min) / (max - min)) * 100)
  );
  const [heartFillOffset, setHeartFillOffset] = React.useState(
    currentHeartFillPercent
  );
  const svgId = {
    heartFill: useRandomId('diabloHeartFillColor'),
  };

  const range = findRangeForPulse(configuration?.ranges || [], pulse);
  const heartColor = range?.heartColor || DEFAULT_HEART_COLOR;

  React.useEffect(() => {
    // set heart fill offset gradually if it changes
    const interval = setInterval(() => {
      setHeartFillOffset((prev) => {
        const diff = currentHeartFillPercent - prev;
        if (Math.abs(diff) < 0.4) {
          clearInterval(interval);
          return currentHeartFillPercent;
        }

        return prev + diff * 0.1;
      });
    }, 30);

    return () => clearInterval(interval);
  }, [currentHeartFillPercent]);

  const shouldShowEmptyState = emptyStateComponent && !pulse;

  return (
    <svg width="100%" height="100%" viewBox="0 0 1005 548">
      <image
        height="100%"
        width="100%"
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/diablo-style/diablo-widget-background.png"
      />
      <path
        style={{fill: `url(#${svgId.heartFill})`}}
        d="M 690.154 118.258 C 768.322 -58.525 983.617 45.431 969.736 184.532 C 935.838 359.439 759.719 519.44 692.695 521.39 C 628.97 523.244 418.161 343.31 404.588 193.262 C 390.777 40.581 582.083 -48.367 690.154 118.258 Z"
      />
      <image
        height="100%"
        width="100%"
        href="https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/diablo-style/diablo-widget-top-layer.png"
      />
      <text
        x="685"
        y="280"
        textAnchor="middle"
        fill="white"
        fontSize="180"
        className={css.bpm}
      >
        {shouldShowEmptyState ? emptyStateComponent : pulse}
      </text>
      <text
        x="685"
        y="420"
        textAnchor="middle"
        fill="white"
        fontSize="80"
        className={css.bpm}
      >
        BPM
      </text>

      <defs>
        <linearGradient
          y2="0%"
          x2="0%"
          y1="100%"
          x1="0%"
          id={svgId.heartFill}
          style={{transition: 'all 0.5s ease'}}
        >
          <stop stop-color={heartColor} offset={`${heartFillOffset}%`} />
          <stop stop-color="transparent" offset="0%" />
        </linearGradient>
      </defs>
    </svg>
  );
};
