import React from 'react';
import {HeartIconProps} from './heartIcon.constants';

export const HeartLinedIcon = ({
  color = '#000',
  size = 100,
  style,
  withAnimation = false,
  stroke,
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 83"
      width={size}
      height={size}
      style={style}
    >
      <path
        fill={color}
        stroke={stroke}
        strokeWidth={stroke ? 1 : 0}
        d="M81.965 21.376v3.66H.635v-3.66h81.33zM79.495 26.186v3.67H2.975v-3.67h76.52zM77.185 30.966v3.67H5.415v-3.67h71.77zM7.765 35.706h66.91v3.73H7.765v-3.73zM12.585 44.216v-3.7h57.38v3.7h-57.38zM65.145 45.296v3.7h-47.79v-3.7h47.79zM22.165 53.806v-3.7h38.21v3.7h-38.21zM38.765 16.576v3.65h-35.8v-3.65h35.8zM43.665 20.226v-3.65h35.82v3.65h-35.82zM7.735 15.406v-3.66h28.7v3.66h-28.7zM74.835 11.746v3.68h-28.7v-3.68h28.7zM55.585 54.966v3.64h-28.61v-3.64h28.61zM50.825 59.726v3.66h-19.05v-3.66h19.05zM14.975 10.636v-3.7h14.29v3.7h-14.29zM53.305 6.926h14.28v3.69h-14.28v-3.69zM34.205 68.176v-3.66h14.29v3.62l-14.29.04zM45.995 72.916h-9.41v-3.7h9.45l-.04 3.7zM43.665 77.706h-4.66v-3.7h4.66v3.7z"
      />

      {withAnimation && (
        <animateTransform
          attributeName="transform"
          type="scale"
          values="0.8; 1.1; 1; 1.1; 1.1; 0.8;"
          dur="500ms"
          repeatCount="indefinite"
          additive="sum"
        />
      )}
    </svg>
  );
};
