import * as React from 'react';
import css from './EcgIcon.module.scss';

export type EcgIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  withAnimation?: boolean;
};

export const EcgIcon2 = ({
  color = '#000',
  size = 100,
  withAnimation,
  style,
}: EcgIconProps) => {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 83 83">
      <path
        className={withAnimation ? css.animate2 : ''}
        d="M 1.673 44.453 L 13.76 44.325 L 15.944 42.77 L 19.288 45.982 L 21.477 42.276 L 22.554 62.91 L 25.255 23.999 L 27.738 46.768 L 30.399 42.8 L 32.769 45.129 L 46.211 44.818 L 47.961 43.18 L 50.498 46.954 L 53.441 42.78 L 55.152 64.19 L 57.73 25.163 L 60.288 46.181 L 63.01 42.58 L 64.903 44.02 L 81.395 44.25"
        style={{
          strokeWidth: '2px',
          stroke: color,
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
    </svg>
  );
};
