import React from 'react';
import ReactPlayer from 'react-player';

export class VideoWidget extends React.Component {
  findVideo = (ranges, heartRate) => {
    for (let index in ranges) {
      let range = ranges[index];
      if (range.from <= heartRate && range.to >= heartRate && range.file.url) {
        return parseInt(index);
      }
    }

    return -1;
  };

  render() {
    let videoIndex;
    if (this.props.configuration) {
      videoIndex = this.findVideo(
        this.props.configuration.ranges,
        this.props.pulse
      );
    } else {
      videoIndex = -1;
    }
    return (
      <>
        {this.props.configuration &&
          this.props.configuration.ranges.map((range, index) => {
            let isActive = false;
            if (index === videoIndex) {
              isActive = true;
            }
            return (
              <ReactPlayer
                key={index}
                style={isActive ? {} : {display: 'none'}}
                width={'100%'}
                height={'100%'}
                loop={isActive}
                muted={true}
                playing={isActive}
                controls={false}
                url={range.file.url}
              />
            );
          })}
      </>
    );
  }
}
