// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Press Start 2P\";src:url(\"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/PressStart2P-Regular.ttf\")}.mslRcIosj99uAp7wknQH{stroke-width:3;stroke-dasharray:80 300;animation:wZxN9_5elV65M5uO5u7Q 3s linear forwards;transform:scale(1.2) translate(40px, 25px)}@keyframes wZxN9_5elV65M5uO5u7Q{0%{stroke-dashoffset:100;opacity:0}20%{stroke-dashoffset:0;opacity:1}50%{opacity:1}70%{stroke-dashoffset:-200;opacity:.1}100%{stroke-dashoffset:-250;opacity:0}}", "",{"version":3,"sources":["webpack://./src/widgets/ResidentEvilWidget/ResidentEvilWidget.module.scss"],"names":[],"mappings":"AAAA,WACE,4BAAA,CACA,4HAAA,CAGF,sBACE,cAAA,CACA,uBAAA,CACA,iDAAA,CACA,0CAAA,CAGF,gCACE,GACE,qBAAA,CACA,SAAA,CAEF,IACE,mBAAA,CACA,SAAA,CAEF,IACE,SAAA,CAEF,IACE,sBAAA,CACA,UAAA,CAGF,KACE,sBAAA,CACA,SAAA,CAAA","sourcesContent":["@font-face {\n  font-family: 'Press Start 2P';\n  src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/advanced-widget/fonts/PressStart2P-Regular.ttf');\n}\n\n.ekg {\n  stroke-width: 3;\n  stroke-dasharray: 80 300;\n  animation: resident-evil-widget-ekg 3s linear forwards;\n  transform: scale(1.2) translate(40px, 25px);\n}\n\n@keyframes resident-evil-widget-ekg {\n  0% {\n    stroke-dashoffset: 100;\n    opacity: 0;\n  }\n  20% {\n    stroke-dashoffset: 0;\n    opacity: 1;\n  }\n  50% {\n    opacity: 1;\n  }\n  70% {\n    stroke-dashoffset: -200;\n    opacity: 0.1;\n  }\n\n  100% {\n    stroke-dashoffset: -250;\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ekg": "mslRcIosj99uAp7wknQH",
	"resident-evil-widget-ekg": "wZxN9_5elV65M5uO5u7Q"
};
export default ___CSS_LOADER_EXPORT___;
