import ClientBrowser from 'jayson/lib/client/browser';
import * as Sentry from '@sentry/browser';

class PulsoidClient {
  constructor() {
    let url;
    const hostname = window && window.location && window.location.hostname;

    if (hostname === 'pulsoid.net') {
      url = 'https://pulsoid.net/v1/api/public/rpc';
    } else {
      url = '/v1/api/public/rpc';
    }

    this.client = ClientBrowser(
      (function () {
        /**
         * @return {boolean}
         */
        function IsJsonString(str) {
          try {
            JSON.parse(str);
          } catch (e) {
            return false;
          }
          return true;
        }

        return function (request, callback) {
          var options = {
            method: 'POST',
            body: request, // request is a string
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          };

          fetch(url, options)
            .then(function (res) {
              return res.text();
            })
            .then(function (text) {
              if (IsJsonString(text)) {
                callback(null, text);
              } else {
                let error = new Error('not json received' + text);
                Sentry.captureException(error);
                callback(error);
              }
            })
            .catch(function (err) {
              callback(err);
            });
        };
      })(),
      {version: 2}
    );
  }

  getWidget(widgetId) {
    return this.toPromise('getWidget', {widgetId: widgetId});
  }

  toPromise(method, params) {
    let client = this.client;
    return new Promise(function (res, rej) {
      client.request(method, params, function (err, response) {
        if (err != null) {
          rej(err);
        } else {
          res(response);
        }
      });
    }).then((z) => {
      if (z.error && z.error.code) {
        if (z.error.code === -33404) {
          return Promise.reject('EntityNotFound');
        } else if (z.error.code === -33422) {
          let err = new Error('not authentificated user');
          Sentry.captureException(err);
          window.location.href = 'https://pulsoid.net/login';
          return Promise.reject(err);
        } else {
          let error = new Error(
            `error occured while interacting ${z.error.code} ${
              z.error.message
            }, method ${method}, params: ${JSON.stringify(params)}`
          );
          Sentry.captureException(error);
          return Promise.reject(error);
        }
      } else {
        return z.result;
      }
    });
  }
}

export default PulsoidClient;
